import { useLanguageChange } from 'pages/giftshop/hooks'
import { Link as RouterLink } from 'react-router-dom'
import { useGetActivities } from 'services/activities/get/useGetActivities'
import { useHideActivity } from 'services/activities/mutation/useHideActivity'
import styled, { css } from 'styled-components/macro'
import { Text, Title } from 'ui/atoms'

import close from './close-icon.svg'

const ActivityLink = ({
  pageLink,
  background,
  children,
}: {
  pageLink: string
  background: string
  children: React.ReactNode
}) => {
  const handleScroll = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault()

    const targetElement = document.querySelector(pageLink)

    if (targetElement) {
      const elementPosition =
        targetElement.getBoundingClientRect().top + window.pageYOffset

      window.scrollTo({
        top: elementPosition - 56,
        behavior: 'smooth',
      })
    }
  }

  // Если ссылка начинается с "#", обрабатываем скролл
  if (pageLink.startsWith('#')) {
    return (
      <ActivityAnchor
        href={pageLink}
        background={background}
        onClick={handleScroll}
      >
        {children}
      </ActivityAnchor>
    )
  }
  // Если ссылка начинается с "/", используем Link из react-router-dom
  else if (pageLink.startsWith('/')) {
    return (
      <ActivityContent to={pageLink} background={background}>
        {children}
      </ActivityContent>
    )
  }
  // Для остальных случаев (абсолютные или относительные вроде "../../../link") — обычный <a>
  else {
    return (
      <ActivityAnchor
        href={pageLink}
        background={background}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </ActivityAnchor>
    )
  }
}

export const Activities = () => {
  const { data, refetch } = useGetActivities()

  useLanguageChange(refetch)

  const { mutateAsync } = useHideActivity()

  const hideActivity = async (name: string) => {
    await mutateAsync({ name })
    refetch()
  }

  return (
    data && (
      <ActivitiesContainer>
        <Title level={4}>Activities</Title>
        <ActivitiesList>
          {data.activities.map((activity) => (
            <ActivityWrapper>
              {activity.canBeClosed ? (
                <CloseButton onClick={() => hideActivity(activity.name)} />
              ) : null}
              <ActivityLink
                pageLink={activity.pageLink}
                background={activity.imageLink}
              >
                <Text level={3} weight="medium" withTranslate={false}>
                  {activity.content}
                </Text>
              </ActivityLink>
            </ActivityWrapper>
          ))}
        </ActivitiesList>
      </ActivitiesContainer>
    )
  )
}

const ActivitiesContainer = styled.div``

const ActivitiesList = styled.div`
  margin-top: 12px;
  margin-bottom: -20px;
  margin-inline-end: -20px;
  padding-inline-end: 20px;
  padding-bottom: 20px;
  display: flex;
  gap: 16px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1175px) {
    width: calc(100vw - 300px);
  }

  @media (max-width: 1050px) {
    margin-inline-start: -20px;
    padding-inline-start: 20px;
    width: 100vw;
  }

  @media (max-width: 599px) {
    margin-inline-start: -8px;
    padding-inline-start: 8px;
  }
`

const ActivityWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
`

const commonStyles = css<{ background: string }>`
  cursor: pointer;
  padding-top: 12px;
  padding-inline-start: 12px;
  padding-inline-end: 26px;
  width: 128px;
  height: 128px;
  display: block;
  transition: var(--transition);
  border-radius: 8px;
  background-color: var(--color-gray-100);
  box-shadow: var(--box-shadow-4);
  background-image: ${({ background }) => `url(${background})`};
  background-size: contain;
  background-position: bottom right;

  &:hover {
    box-shadow: var(--box-shadow-6);
  }
`

const ActivityContent = styled(RouterLink)<{ background: string }>`
  ${commonStyles}
`

const ActivityAnchor = styled.a<{ background: string }>`
  ${commonStyles}
`

const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 0;
  inset-inline-end: 0;
  width: 24px;
  height: 24px;
  background-image: url(${close});
  background-repeat: no-repeat;
  background-size: 16px auto;
  background-position: center center;
`
