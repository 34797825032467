import { useMask } from '@react-input/mask'
import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { A3codes } from 'libs/A3codes'
import { useIsMobile } from 'libs/hooks/useIsMobile'
import { yup } from 'libs/validators'
import {
  BenefitsImage,
  BenefitsImages,
  Container,
  Footer,
  Mondatory,
  MondatoryHeader,
  Person,
  Uploads,
} from 'pages/dashboard/verification/pages/request/styled'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  Banner,
  Field,
  FieldSelect,
  List,
  ModalHint,
  Title,
  Tooltip,
} from 'ui/atoms'
import { Dialog } from 'ui/molecules'

import { $verification } from '../../model'
import { PersonProps } from '../../types'
import { BenefitsDialog } from '../styled'
import {
  blurry,
  blurryWeb,
  clear,
  clearWeb,
  cropped,
  croppedWeb,
  reflective,
  reflectiveWeb,
} from './images'
import {
  $country,
  $errors,
  setCountry,
  submitEditForm,
  submitForm,
} from './model'
import { RequestButton } from './RequestButton'
import { UploadFilesWrapp } from './UploadFilesWrapp'
import { LegacyCountrySelect } from 'ui/atoms/form/LegacyCountrySelect'

export const RequestPage = () => {
  const [searchParams] = useSearchParams()
  const isMobile = useIsMobile()

  const [t] = useTranslation()

  const dateOfBirthRef = useMask({
    mask: '____-__-__',
    replacement: { _: /\d/ },
    showMask: true,
    separate: true,
  })

  const user = useStore($userProfile)
  const verification = useStore($verification)
  const errors = useStore($errors)
  const currentCountry = useStore($country)
  const isRejected = verification?.status === 'PAVRS_REJECTED'
  const isBanned = verification?.status === 'PAVRS_BANNED'
  const isAccepted = verification?.status === 'PAVRS_ACCEPTED'

  const isDisabled = useMemo(() => {
    const isEditVerificationMode = !!searchParams.get('edit')
    if (isEditVerificationMode) return false

    return isBanned || isAccepted
  }, [isBanned, isAccepted, searchParams])

  const initialValues = {
    firstName: verification?.firstName || user.firstName,
    lastName: verification?.lastName || user.lastName,
    middleName: verification?.middleName || '',
    dateOfBirth: verification?.dateOfBirth || '',
    address: verification?.address || '',
    country: verification?.country || user.country,
    documentNumber: verification?.documentNumber || '',
  }

  useEffect(() => {
    setCountry(verification?.country || user.country);
    return () => {
      setCountry('')
    }
  }, [])

  return (
    <>
      {isRejected && (
        <Banner
          design="sunray"
          title={verification.reason}
          icon="exclamationTriangle"
          textClasses={isMobile ? '!text-[14px]' : undefined}
          titleClaases={isMobile ? '!text-[14px]' : undefined}
        >
          {verification.comment}
        </Banner>
      )}
      {isBanned && (
        <Banner
          design="opal"
          title={verification.reason}
          icon="exclamationCircle"
          textClasses={isMobile ? '!text-[14px]' : undefined}
          titleClaases={isMobile ? '!text-[14px]' : undefined}
        >
          {verification.comment}
        </Banner>
      )}

      <Container
        initial={initialValues}
        onSubmit={(values) => {
          const newObj = { ...values, country: currentCountry }
          if (!!searchParams.get('edit')) {
            submitEditForm(newObj as PersonProps)
            return
          }

          submitForm(newObj as PersonProps)
        }}
        validation={yup.verification}
      >
        <Person>
          <Field
            name="firstName"
            label="First name"
            placeholder="First name"
            error={errors?.fields?.firstName}
            disabled={isDisabled}
          />
          <Field
            name="middleName"
            label="Middle name"
            placeholder="Middle name"
            optional
            error={errors?.fields?.middleName}
            disabled={isDisabled}
          />
          <Field
            name="lastName"
            label="Last name"
            placeholder="Last name"
            error={errors?.fields?.lastName}
            disabled={isDisabled}
          />
          <Field
            name="dateOfBirth"
            label="Date of birth"
            placeholder="yyyy-mm-dd"
            error={errors?.fields?.dateOfBirth}
            disabled={isDisabled}
            ref={dateOfBirthRef}
          />
          <LegacyCountrySelect
            label="Country"
            name="country"
            defaultValue={verification?.country || user.country}
            value={currentCountry}
            error={errors?.fields?.country}
            isHaveSearch
            placeholder="Choose country"
            onSelect={setCountry}
            disabled={isDisabled}
          />
          <Field
            name="address"
            label="Address"
            placeholder="Address"
            optional
            maxLength={100}
            error={errors?.fields?.address}
            disabled={isDisabled}
          />
          <Field
            name="documentNumber"
            label="ID number"
            placeholder="ID number"
            error={errors?.fields?.documentNumber}
            disabled={isDisabled}
          />
        </Person>
        <Uploads>
          <UploadFilesWrapp />
        </Uploads>
        <Mondatory>
          <MondatoryHeader className={'lg:items-start items-center'}>
            <Title
              level={isMobile ? 4 : 3}
              className={isMobile ? '!text-[14px] !leading-[21px]' : undefined}
            >
              Mandatory requirements for all documents
            </Title>
            <ModalHint title="Upload only high-quality images">
              <BenefitsImages>
                {[
                  [clear, clearWeb],
                  [blurry, blurryWeb],
                  [reflective, reflectiveWeb],
                  [cropped, croppedWeb],
                ].map((src, key) => (
                  <BenefitsImage src={src[0]} srcSet={src[1]} key={key} />
                ))}
              </BenefitsImages>
            </ModalHint>
          </MondatoryHeader>
          <List
            itemClasses={isMobile ? '!text-[12px] !leading-[18px]' : undefined}
            items={[
              'The validity period does not exceed the current date.',
              'The photo has not been modified in a graphic editor.',
              'The document is clearly visible, the photo is not blurry.',
            ]}
          />
        </Mondatory>
        <Footer>
          <RequestButton />
        </Footer>
      </Container>
    </>
  )
}
