import React from 'react';
import { Spinner2 } from 'ui/atoms';

import { PageWrapper } from 'ui/atoms/pageWrapper';

import { PromoActive } from './PromoActive';
import { useGetYour2024 } from '../api/useGetYour2024';
import { FinishedPromo } from './FinishedPromo';

export const Your2024Page = () => {
  const { data, isLoading } = useGetYour2024();

  if (isLoading || !data) {
    return <Spinner2 size='large' />
  }

  if (data?.status === 'STATUS_ACTIVE') {
    return (
      <PageWrapper className='flex'>
        <PromoActive data={data} />
      </PageWrapper>
    )
  }
  
  return (
    <PageWrapper className='flex justify-center items-start'>
      <FinishedPromo data={data} />
    </PageWrapper>
  )
}
