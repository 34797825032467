import { useStoreMap } from 'effector-react';
import { $userProfile } from 'features/user';

import { useGetCountry } from 'services/common/get/useGetCountry';

export const useCountryCurrent = () => {
  const country = useStoreMap($userProfile, (state) => state.country);

  const { data, isLoading } = useGetCountry({ enabled: true, noRefetch: true });

  return ({
    isLoading,
    country: data?.countries?.[country],
  })
}
