import { useQuery } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';

export const GET_COUNTRIES_KEY = 'GET_COUNTRIES_KEY';

export interface CountriesResp {
  countries: Record<string, CountryItem>;
}

export interface CountryItem {
  A2: string;
  A3: string;
  forbiddenProducts: string[];
  isForbidden: boolean;
  isMuslim: boolean;
  name: string;
}

const getCountry = async () => {
  const res = await axios.get<CountriesResp>(`/dictionary/v1/countries`);

  return res.data;
};

type Options = {
  enabled?: boolean;
  noRefetch?: boolean;
}

export const useGetCountry = ({ enabled, noRefetch = false }: Options) => {
  return useQuery({
    queryKey: [GET_COUNTRIES_KEY],
    queryFn: getCountry,
    retry: retryFn,
    // Кэш 5 минут
    // staleTime: 50000,
    staleTime: Infinity,
    refetchOnWindowFocus: !noRefetch,
    refetchOnReconnect: !noRefetch,
    enabled,
  })
};
