import { useIsMobile } from 'libs/hooks/useIsMobile'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Link, Text, Title } from 'ui/atoms'

import banner from './img/cashBooster.jpg'
import * as S from './styled'

interface Props {
  onClose: () => void
}

export const CashBoosterBanner: React.FC<Props> = ({ onClose }) => {
  const [t] = useTranslation()

  const isMobile = useIsMobile()

  const onCloseBanner = () => {
    localStorage.setItem('IB_BANNER_CASH_BOOSTER', 'true')
    onClose()
  }

  const bannerLink = `${window.location.origin}/promo/cash-booster`

  return (
    <S.BannerBlock
      url={banner}
      className={`md:items-center md:justify-between ${
        isMobile ? 'flex-col' : ''
      } !min-w-[343px] prevent-rtl`}
    >
      <S.Icon
        name="xMarkIcon"
        className="text-gray.300"
        onClick={onCloseBanner}
      />
      <Title
        level={isMobile ? 3 : 1}
        className={`z-10 ${!isMobile && '!text-[44px]'} md:ml-[12px]`}
      >
        Cash Booster Bonus
      </Title>

      <div className="xl:mr-[230px] lg:mr-[100px] mr-[10px] mt-[10px] md:mt-0 text-left">
        <Text
          className={'text-left whitespace-nowrap'}
          level={isMobile ? 4 : 1}
          withTranslate={false}
        >
          <Trans
            t={t}
            i18nKey={'Boost your partner commission. Get up to $3000 extra!'}
          >
            Boost your partner commission.
            <br /> Get up to $3000 extra!
          </Trans>
          {isMobile && (
            <Link
              to={`${window.origin}/promo/cash-booster/`}
              className="!leading-[10px]"
            >
              <Text
                level={isMobile ? 4 : 1}
                weight="medium"
                className="!inline ms-2 cursor-pointer !text-gray.100"
              >
                Join now
              </Text>
            </Link>
          )}
        </Text>

        {!isMobile && (
          <Button
            name="joinNow"
            className="mt-[10px] min-w-[123px] xl:min-w-[123px] xl:!h-[36px] !h-[36px] relative z-20"
            design="secondary"
          >
            <Link to={bannerLink}>
              <Text level={2}>Join now</Text>
            </Link>
          </Button>
        )}
      </div>
    </S.BannerBlock>
  )
}
