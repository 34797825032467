
import React, { ChangeEvent } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormAdditionalFields } from 'services/giftshop/types'
import { OrderForm } from 'services/giftshop/mutations/useGiftOrder'
import { Button, Select } from 'ui/atoms'
import { FormInput } from 'ui/molecules'

import { getSchemaGiftOrder } from './utils'
import { CountrySelect } from './CountrySelect'


interface Props {
  onSubmit: (values: OrderForm) => void;
  disabled: boolean;
  formAdditionalFields?: FormAdditionalFields;
}

const SIZE_OPTIONS = [
  {
    label: 'S',
    value: 'PACS_S',
  },
  {
    label: 'M',
    value: 'PACS_M',
  },
  {
    label: 'L',
    value: 'PACS_L',
  },
  {
    label: 'XL',
    value: 'PACS_XL',
  },
];

export const GiftOrderForm: React.FC<Props> = ({ onSubmit, disabled, formAdditionalFields = [] }) => {
  const isHaveSizeField = formAdditionalFields?.includes('SIZE');

  // const defaultCountry = useStoreMap($userProfile, (state) => state.country);
  const [t] = useTranslation();
  
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    setValue
  } = useForm<OrderForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
    },
    mode: 'onTouched',
    // без any почему то форма берет типы из резолвера
    // даже явное определение типа формы через дженерик не помогает
    resolver: yupResolver(getSchemaGiftOrder(isHaveSizeField) as any),
  });

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('contactNumber', `+${event.target.value.replace(/[^\d]/g, '')}`)
  }

  return (
    <>
      <fieldset
        className="my-[4rem] lg:w-[60rem] flex flex-col gap-[2rem] relative"
        disabled={disabled}
      >
        <FormInput
          state={'hint'}
          errorMessage={errors.firstName?.message}
          isTouchedForm={dirtyFields?.firstName}
          placeholder="First name"
          autoComplete="off"
          maxLength={32}
          {...register('firstName')}
        />
        <FormInput
          // className='mt-[1.6rem]'
          errorMessage={errors.lastName?.message}
          isTouchedForm={dirtyFields?.lastName}
          placeholder="Last name"
          autoComplete="off"
          maxLength={32}
          {...register('lastName')}
        />
        <div className="w-full flex gap-[2rem] flex-wrap">
          <FormInput
            {...register('contactNumber')}
            type="text"
            errorMessage={errors.contactNumber?.message}
            isTouchedForm={dirtyFields?.contactNumber}
            maxLength={15}
            // inputClasses='!ps-[2.5rem]'
            placeholder="Contact number"
            className="!w-[calc(50%-10px)]"
            onChange={handlePhoneChange}
          />
          { isHaveSizeField &&
            <Controller
              name='size'
              control={control}
              rules={{
                required: { value: true, message: 'Required' }
              }}
              render={({ field: { onChange, ...restFields } }) => (
                <Select
                  {...restFields }
                  placeholder={t("Select size")!}
                  className='!w-[calc(50%-10px)]'
                  errorMessage={errors.size?.message}
                  autoComplete="off"
                  options={SIZE_OPTIONS}
                  listClassName='w-full'
                  onSelect={onChange}
                  readOnly
                  isHaveSearch={false}
                />
              )}
            />
          }
        </div>

        <div className="w-full flex gap-[2rem] flex-wrap">
          <CountrySelect control={ control } error={errors?.country} />

          <FormInput
            {...register('postalCode')}
            type="text"
            errorMessage={errors.postalCode?.message}
            isTouchedForm={dirtyFields?.postalCode}
            // inputClasses='!ps-[2.5rem]'
            placeholder={t("Postal Code")!}
            className={"!w-[calc(50%-10px)]"}
            // onChange={handlePhoneChange}
          />
        </div>

        <FormInput
          {...register('address')}
          type="text"
          errorMessage={errors.address?.message}
          isTouchedForm={dirtyFields?.address}
          replacePattern={/[<>@]/gm}
          maxLength={140}
          placeholder="Address"
          autoComplete="off"
        />
      </fieldset>

      <Button
        name="submit"
        className="min-w-[24rem]"
        onClick={handleSubmit(onSubmit)}
        loading={disabled}
        disabled={disabled}
      >
        Order now
      </Button>
    </>
  )
}
