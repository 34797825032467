import React, { useState, useEffect } from 'react';
import { differenceInSeconds } from 'date-fns';

interface Props {
  targetTimestamp: number;
}

interface TimerLeft {
  hours: string;
  minutes: string;
  seconds: string;
}

export const CountdownTimer: React.FC<Props> = ({ targetTimestamp }) => {
  const [timeLeft, setTimeLeft] = useState<TimerLeft | null>(null);

  const timeFormatter = (time: string) => {
    if (!time || typeof time !== 'string') return '';

    return  time.length === 1 ? `0${time}` : time;
  }

  const calculateTimeLeft = () => {
    if (!targetTimestamp || typeof targetTimestamp !== 'number') {
      setTimeLeft(null);
      return;
    }

    const targetDate = new Date(targetTimestamp * 1000);
  
    const now = new Date();
    const diffInSeconds = differenceInSeconds(targetDate, now);

    if (diffInSeconds <= 0) {
      setTimeLeft(null);
      return; // Если время уже прошло, возвращаем null
    }

    const hours = Math.floor(diffInSeconds / 3600)?.toString();
    const minutes = Math.floor((diffInSeconds % 3600) / 60)?.toString();
    const seconds = (diffInSeconds % 60).toString();

    setTimeLeft({ 
      hours: timeFormatter(hours),
      minutes: timeFormatter(minutes),
      seconds: timeFormatter(seconds)
    })
  }

  useEffect(() => {
    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(timer);
  }, [targetTimestamp]);

  // Если время уже прошло, выводим сообщение
  if (!timeLeft) {
    return null;
  }

  return (
    <>
      {timeLeft.hours} : {timeLeft.minutes} : {timeLeft.seconds}
    </>
  );
};