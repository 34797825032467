import styled from 'styled-components/macro'

import icon from './icon.webp'

export const Wrapper = styled.div`
  padding: 168px 24px 32px 24px;
  display: grid;
  justify-items: center;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  text-align: center;
  background-color: var(--color-gray-300);
  background-image: url(${icon});
  background-repeat: no-repeat;
  background-size: 120px auto;
  background-position: top 32px center;
  border-radius: 8px;

  @media (max-width: 599px) {
    padding: 184px 32px 48px 32px;
    background-position: top 48px center;
  }
`
