import React, { useMemo } from 'react'
import { useStoreMap, useUnit } from 'effector-react';

import { $amountField, $calcAmount, $destenation } from '../model';

export const useGetReceiveAmount = () => {
  const destenationCurrency = useStoreMap($destenation, (state) => state?.currency);
  const amountField = useUnit($amountField);
  const calcAmount = useUnit($calcAmount);

  const receiveAmount = useMemo(() => {
    if (amountField.errorType) {
      return '0.00'
    }

    return calcAmount.currency === destenationCurrency ? calcAmount.convertedAmount : amountField.amount;
  }, [amountField, destenationCurrency, calcAmount]);

  const receiveCurrency = useMemo(() =>  destenationCurrency || 'USD', [destenationCurrency]);

  return ({
    receiveAmount,
    receiveCurrency,
  })
}
