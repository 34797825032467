import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { axios } from 'services/axios'

export interface OrderForm {
  firstName: string
  lastName: string
  contactNumber: string
  country: string
  address: string
  size: string;
  postalCode: string;
}

export interface OrderFormPostBody {
  orderForm: OrderForm | null
  giftID: string | number
}

const postGiftOrder = async (
  id: string | number,
  orderForm: OrderForm | null,
) => {
  const res = await axios.post<{}, AxiosResponse<{}>, OrderFormPostBody>(
    '/gift-shop/v1/order-gift',
    {
      giftID: id,
      orderForm,
    },
  )

  return res.data
}

export const useGiftOrder = (id: string | number) => {
  return useMutation({
    mutationFn: (orderForm: OrderForm | null) => postGiftOrder(id, orderForm),
  })
}
