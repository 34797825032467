import styled, { css } from 'styled-components/macro'

export const CheckCircleWrapper = styled.div<{isChecked?: boolean}>`
  height: 32px;
  width: 32px;
  border: 2px solid var(--color-eucalyptus-800);
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isChecked }) => {
    if (isChecked) return css`background-color: var(--color-eucalyptus-800);`;

    return '';
  }}
`;