import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/axios';

export const GET_DEPOSIT_BONUS = 'GET_DEPOSIT_BONUS';

export interface BonusDepositResp {
  bonusAmount: string;
  bgColor: "COLOR_YELLOW";
  bonusConditions?: BonusConditions;
  currency: string;
  isActiveByDefault: boolean;
  bonusPercent: string;
  isLimitReached: boolean;
  isIncreasedPercentage: boolean;
  promoCode?: {
    code?: string;
  };
  limitExceeding?: {
    sumLimit: string;
  };
  receiveAmount: string;
}

export interface BonusConditions {
  amountDepositForTooltip: string;
  bonusPercentAfter: string;
  bonusPercentBefore: string;
  sumDeposit: string;
  sumLimit?: string;
}

const getDepositBonus = async ({ accountID, receiveAmount, state }: Options) => {
  const resp = await axios.get<BonusDepositResp>(`deposit-bonus/v1/widget`, {
    params: {
      accountID, receiveAmount: receiveAmount || 0,
      state
    },
  });

  return resp.data;
}

interface Options {
  accountID: string;
  receiveAmount: string;
  state?: 'STATE_COMMON' | 'STATE_INCREASE_REG_BONUS' | 'STATE_INCREASE_BY_CRM' | 'STATE_INCREASE_BY_PROMO_CODE' | 'STATE_UNAVAILABLE';
  enabled?: boolean;
}

export const useGetDepositBonus = ({ accountID, receiveAmount, state, enabled = true }: Options) => {
  return useQuery({
    // @ts-ignore
    queryKey: [GET_DEPOSIT_BONUS, accountID, receiveAmount],
    queryFn: () => getDepositBonus({ accountID, receiveAmount, state }),
    staleTime: 200,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled,
  })
}
