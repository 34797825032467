import { useIsMobile } from 'libs/hooks/useIsMobile';
import React, { useEffect, useState } from 'react';
import { BonusConditions, BonusDepositResp } from 'services/money/get';

import { Icon2, Text } from 'ui/atoms';
import { Progress } from 'ui/molecules';
import { setDepositBonusEvent, toggleBonusDepositEvent } from '../store/depositBonusStore';


interface Props {
  bonus: BonusConditions;
}

export const BonusInfo: React.FC<Props> = ({ bonus }) => {
  const [isShow, setIsShow] = useState(true);
  const isMobile = useIsMobile();

  const onToggleShow = () => setIsShow(prev => !prev);

  useEffect(() => {
    setDepositBonusEvent.watch((checked) => {
      if (!checked) setIsShow(false);
    })
  }, []);
  

  const progress = 50;

  return (
    <>
      <div className='w-full h-[40px] flex justify-between items-center cursor-pointer' onClick={onToggleShow} >
        <Text color='main' weight='medium' level={2}>
          Why do I need the bonus?
        </Text>
        <Icon2 name={isShow ? 'chevronUp' : 'chevronDown'} />
      </div>
      {isShow &&
        <div className='mt-[8px] w-full'>
          <Text level={isMobile ? 3 : 2}>
            The deposit bonus allows you to trade and earn more. The bigger your deposit, the higher the bonus %:
          </Text>

          <div className='mt-[16px] flex'>
            <Text color='main' level={3} weight='medium' className='flex-1' withTranslate={false}>Deposit under ${bonus.sumDeposit}</Text>
            <Text color='main' level={3} weight='medium' className='flex-1' withTranslate={false}>Deposit from ${bonus.sumDeposit}</Text>
          </div>

          <Progress
            progress={progress}
            wrapClasses='mt-[6px] !h-[12px] !bg-eucalyptus.200 flex justify-end'
            progressClasses='!bg-eucalyptus.500'
          />
          <div className='mt-[6px] flex'>
            <Text color='main' level={3} weight='medium' className='flex-1' withTranslate={false}>
              +{bonus.bonusPercentBefore}% bonus
            </Text>
            <Text color='main' level={3} weight='medium' className='flex-1' withTranslate={false}>
              +{bonus.bonusPercentAfter}% bonus
            </Text>
          </div>
        </div>
      }
    </>
  )
}
