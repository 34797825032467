import styled from 'styled-components/macro'

import { Input } from '../input'

export const PickerWrapper = styled.div`
  input + div {
    inset-inline-end: 12px;
    display: flex !important;
  }

  .react-datepicker {
    border: none;
    width: 100%;
  }

  .react-datepicker,
  .react-datepicker > * {
    font-family: var(--font--primary);

    .react-datepicker__header {
      padding: 0;
      border-bottom: none;
      background-color: #ffffff;
    }

    .react-datepicker__navigation {
      top: 24px;
      inset-inline-start: 16px;
      width: 20px;
      height: 20px;
      transition: opacity 0.1 ease;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.79062 0.230169C6.07772 0.528748 6.06841 1.00353 5.76983 1.29063L1.83208 5L5.76983 8.70938C6.06841 8.99647 6.07772 9.47125 5.79062 9.76983C5.50353 10.0684 5.02875 10.0777 4.73017 9.79063L0.230167 5.54063C0.0831082 5.39922 -1.18115e-06 5.20401 -1.17223e-06 5C-1.16331e-06 4.79599 0.0831082 4.60078 0.230167 4.45938L4.73017 0.209376C5.02875 -0.077719 5.50353 -0.0684095 5.79062 0.230169Z' fill='%23666666'/%3E%3C/svg%3E%0A");
      background-position: center center;

      transform: ${({ theme }) =>
        theme.dir === 'rtl' ? 'rotate(180deg)' : 'none'};

      &:hover {
        opacity: 0.7;
      }

      &:acitve {
        opacity: 0.3;
      }
    }

    .react-datepicker__navigation--next {
      inset-inline-start: auto;
      inset-inline-end: 16px;
      transform: ${({ theme }) =>
        theme.dir === 'rtl' ? 'none' : 'rotate(180deg)'};
    }

    .react-datepicker__navigation-icon {
      display: none;
    }

    .react-datepicker__current-month {
      padding: 24px 0;
      font-size: var(--body-font-size-medium);
      color: var(--color-gray-700);
    }

    .react-datepicker__month-container {
      float: none;

      @media (max-width: 460px) {
        width: 100%;
      }
    }

    .react-datepicker__month {
      margin: 0;
      padding: 12px 16px 16px;

      @media (max-width: 460px) {
        padding: 12px 0 16px;
      }
    }

    .react-datepicker__week {
      display: grid;
      grid-template-columns: repeat(7, 49px);
      grid-template-rows: 32px;

      @media (max-width: 460px) {
        grid-template-columns: repeat(7, 1fr);
      }
    }

    .react-datepicker__day {
      margin: 0;
      width: 100%;
      font-size: var(--body-font-size-medium);
      color: var(--color-gray-700);
      line-height: 32px;
      border-radius: 4px;
      transition-property: color, background-color;
      transition-duration: 0.1s;
      transition-timing-function: ease;

      &:hover {
        background-color: var(--color-gray-300);
      }

      &:active {
        background-color: var(--color-sunray-100);
      }

      &--selected {
        background-color: #ffffff;
      }

      &--in-selecting-range {
        border-radius: 0;
        background-color: var(--color-gray-300);
      }

      &--in-range {
        border-radius: 0;
        background-color: var(--color-sunray-100);
      }

      &--keyboard-selected,
      &--range-start,
      &--range-end {
        pointer-events: none;
        font-weight: 500;
        background-color: var(--color-sunray-500);
      }

      &--range-start,
      &--selecting-range-start {
        border-radius: 4px 0 0 4px;
      }

      &--range-end,
      &--selecting-range-end {
        border-radius: 0 4px 4px 0;
      }

      &--outside-month {
        color: var(--color-gray-600);
      }

      &--today {
        position: relative;
        font-weight: 500;
        color: var(--color-sunray-600);

        &::after {
          content: '';
          position: absolute;
          bottom: 4px;
          inset-inline-start: 4px;
          width: calc(100% - 8px);
          height: 2px;
          transition: background-color 0.1s ease;
          border-radius: 2px;
          background-color: var(--color-sunray-600);
        }
      }

      &--today.react-datepicker__day--selected {
        color: var(--color-gray-700);

        &::after {
          background-color: var(--color-gray-700);
        }
      }
    }

    .react-datepicker__day-names {
      margin: 0;
      padding: 0 16px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: 500;

      @media (max-width: 460px) {
        grid-template-columns: repeat(7, 1fr);
      }
    }

    .react-datepicker__day-name {
      margin: 0;
      width: 41px;
      text-align: center;
      font-size: var(--body-font-size-small);
      color: var(--color-gray-600);
      text-transform: uppercase;

      @media (max-width: 460px) {
        width: auto;
      }
    }
  }
`

export const Row = styled.div`
  max-width: 375px;
  padding: 0 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 460px) {
    /* max-width: 295px; */
    gap: 12px;
  }
`

export const InputWrapper = styled(Input)`
  input {
    padding-top: 14px;
    padding-bottom: 14px;
    height: 48px;
  }
`
