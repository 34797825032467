import { useIsMobile } from 'libs/hooks/useIsMobile';
import { path } from 'libs/path';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { BannerHeadKit } from 'services/banners/get/useGetHeadBanner';
import { useHideHeadBanner } from 'services/banners/mutations/useHideHeadBanner';
import { Button, Icon2, Text } from 'ui/atoms';

interface Props {
  banner: BannerHeadKit;
  onRefetchHeaderBanner?: () => void
}

export const Regular: React.FC<Props> = ({ banner, onRefetchHeaderBanner }) => {
  const { mutateAsync } = useHideHeadBanner();

  const isMobile = useIsMobile();

  const navigate = useNavigate();
  const onGoToDeposit = () => {
    navigate(path.money.depositTab());
  }

  const onHideBanner = async () => {
    try {
      await mutateAsync(banner.name);
    } catch (error) {
      
    }
  }

  return (
    <div className={`md:h-[56px] w-full fixed flex justify-center items-center top-0 z-40 bg-azure.100 h-[37px]`}>
      <Text level={isMobile ? 2 : 1} color='main' weight='medium'>
        {isMobile 
          ? 'Get extra $ for trading'
          : 'A deposit bonus is available to you! Get extra $ for trading'
        }
      </Text>
      <Text level={isMobile ? 2 : 1} weight='medium' className='!h-[42px] min-w-[130px] md:ml-[16px] ml-[8px] !text-bronze.500 flex items-center cursor-pointer' onClick={onGoToDeposit}>
        Deposit now
      </Text>
      <div className='h-full cursor-pointer absolute right-0 top-0 flex items-center md:mr-[18px] mr-[12px]' onClick={onHideBanner}>
        <Icon2 name='xMarkIcon' size={isMobile ? 'small' : 'medium' }  />
      </div>
    </div>
  )
}
