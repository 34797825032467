import React, { PropsWithChildren } from 'react'
import { Icon2 } from 'ui/atoms'
import { Icons } from 'ui/atoms/icon2'

import * as S from './styled'

export type AlertType = 'success' | 'warning' | 'pending' | 'info' | 'error'

export type AlertSize = 'large' | 'medium'

interface Props extends PropsWithChildren {
  type: AlertType
  title?: string
  onClose?: () => void
  className?: string
  size?: AlertSize
}

const icons: Record<AlertType, Icons> = {
  pending: 'clockSolid',
  success: 'checkCircle',
  warning: 'exclamationTriangleSolid',
  info: 'informationCircleSolid',
  error: 'exclamationCircleSolind',
}

export const Alerts: React.FC<Props> = ({
  type,
  children,
  title,
  onClose,
  className,
  size = 'large',
}) => {
  return (
    <S.Alert type={type} size={size} className={className}>
      <Icon2 name={icons[type]} size={size === 'large' ? 'medium' : 'small'} />

      <div className="w-full">
        {title && <S.Title>{title}</S.Title>}
        {children}
      </div>

      {onClose && <S.CloseButton type="button" onClick={onClose} />}
    </S.Alert>
  )
}
