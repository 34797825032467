import { createEvent, restore } from 'effector'
import { AlertType } from 'ui/molecules/alerts/Alerts'

export const setGlobalError = createEvent<boolean>()
export const $isGlobalError = restore(setGlobalError, false)

export const setIsLogoutModalVisible = createEvent<boolean>()
export const $isLogoutModalVisible = restore(setIsLogoutModalVisible, false)


export type GlobalAlertStore = {
  type: AlertType;
  title?: string;
  text?: string;
}

// Глобальный алерт
export const setGlobalAlert = createEvent<GlobalAlertStore | null>();
export const $globalAlert = restore(setGlobalAlert, null);

// Алерт на конкретную страницу можно поставить со своими стилями и местом расположения и тд 
export const setPageAlert = createEvent<GlobalAlertStore | null>();
export const $pageAlert = restore(setPageAlert, null);
