import React from 'react';
import { useUnit } from 'effector-react'

import { $globalAlert, setGlobalAlert } from 'ui/templates/generic/model';
import { Alert } from './Alert';

export const GlobalAlert = () => {
  const alert = useUnit($globalAlert);

  const onCloseAlert = () => {
    setGlobalAlert(null);
  }

  if (!alert) return null;

  return (
    <Alert alert={alert} onCloseAlert={onCloseAlert} />
  )
}
