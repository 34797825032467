import styled, {css} from 'styled-components/macro'
import { Button, Title } from 'ui/atoms'

import desktopBackgroundRtl from './images/background-desktop-rtl.webp'
import desktopBackground from './images/background-desktop.webp'
import mobileBackground from './images/background-mobile-full.webp'

export const Container = styled.div`
  position: relative;
  padding: 0 40px 40px;
  border-radius: 8px;
  background-color: var(--color-gray-100);

  @media (max-width: 768px) {
    padding: 0 20px 24px;
  }

  @media (max-width: 470px) {
    padding: 0 12px 24px;
  }
`

export const TitleWrapper = styled.div`
  position: relative;
  margin: 0 -40px 64px;
  width: calc(100% + 80px);
  padding-bottom: 28.3%;
  display: block;
  background-color: rgb(242, 251, 255);
  border-radius: 8px;
  background-image: url(${desktopBackground});
  background-size: auto 100%;
  background-position: right center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    margin: 0 -20px 32px;
    width: calc(100% + 40px);
  }

  @media (max-width: 470px) {
    margin: 0 -12px 32px;
    padding: 11% 20px 60.7%;
    width: calc(100% + 24px);
    background-image: url(${mobileBackground});
    background-position: center bottom;
    background-size: 100% auto;
  }

  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      background-image: url(${desktopBackgroundRtl});
      background-position: left center;
    `}
`

export const PageTitle = styled.div`
  position: absolute;
  top: 50%;
  inset-inline-start: 4%;
  max-width: 36%;
  transform: translateY(-50%);
  font-size: 83px;
  line-height: 1;
  font-weight: 500;

  @media (max-width: 1440px) {
    font-size: calc((100vw - 300px) / 13.85);
  }

  @media (max-width: 1050px) {
    font-size: calc((100vw - 20px) / 13.85);
  }

  @media (max-width: 470px) {
    position: static;
    max-width: 100%;
    transform: none;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }
`

export const AboutWrapper = styled.div`
  margin: 0 auto;
  max-width: 80%;
  display: grid;
  gap: 12px;
  text-align: center;
  justify-items: center;

  @media (max-width: 570px) {
    max-width: 100%;
  }
`

export const Link = styled.a`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 500;
  color: var(--color-bronze-500);

  @media (max-width: 570px) {
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const JoinButton = styled(Button)`
  margin: 40px auto 0;
  min-width: 240px;

  @media (max-width: 768px) {
    margin-top: 32px;
  }

  @media (max-width: 570px) {
    width: 100%;
  }
`

export const ProgressTitle = styled.div`
  margin-top: 64px;
  margin-bottom: 16px;
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;

  @media (max-width: 768px) {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  @media (max-width: 570px) {
    font-size: 20px;
  }
`

export const ContentWrapper = styled.div`
  margin-top: 64px;

  @media (max-width: 570px) {
    margin-top: 32px;
  }
`

export const TabsWrapper = styled.div`
  margin-bottom: 40px;
  padding-top: 12px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  text-align: center;
  gap: 20px;
  justify-content: center;

  @media (max-width: 768px) {
    margin-bottom: 20px;
    padding-top: 0;
    gap: 12px;
  }
`

export const Tab = styled(Title)<{ isActive: boolean }>`
  pointer-events: ${({ isActive }) => (isActive ? 'none' : 'initial')};
  cursor: pointer;
  padding: 2px 12px;
  color: ${({ isActive }) =>
    isActive ? 'var(--color-bronze-500)' : 'var(--color-gray-600)'};
  transition: color var(--transition);
`
