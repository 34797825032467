import { useMutation } from '@tanstack/react-query'
import { axios } from 'services/axios'

const hidePopup = async (name: string) => {
  await axios.patch('/contentkit/v1/popup/hide', { name })
}

export const useHidePopup = () => {
  return useMutation({
    mutationFn: hidePopup,
  })
}
