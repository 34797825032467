import { useQuery } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';

export const GET_YOUR_2024_KEY = 'GET_YOUR_2024_KEY';

export type PromoYour2024Status = 'STATUS_INVALID' | 'STATUS_ACTIVE' | 'STATUS_FINAL' | 'STATUS_FINISHED';
export type PromoYour2024Colors = 'COLOR_INVALID' | 'COLOR_GOLD' | 'COLOR_SILVER' | 'COLOR_BRONZE' | 'COLOR_GREEN';

export interface PromoYour2024Type {
  refLink: string;
  status: PromoYour2024Status;
  card: {
    color: PromoYour2024Colors;
    rank: string;
    categoryID: number;
  };
}

const getYour2024 = async () => {
  const resp = await axios.get<PromoYour2024Type>(`/promo/your2024/v1/info`,
  )

  return resp.data;
}

export const useGetYour2024 = () => {
  return useQuery({
    queryKey: [GET_YOUR_2024_KEY],
    queryFn: () => getYour2024(),
    retry: retryFn,
    staleTime: 300,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
}
