import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { GET_HEAD_BANNERS_KIT_KEY } from 'services/banners/get/useGetHeadBanner';
import { GET_BANNERS_KIT_KEY } from 'services/banners/get/useGetKitBanners';
import { retryFn } from 'services/helpers/retryFn';

interface Options {
  code: string;

  // Только для дева
  state?: 'STATE_NONE' | 'STATE_DONE' | 'STATE_NOT_FOUND' | 'STATE_EXPIRED' | 'STATE_ALREADY_USED';
}


export interface ActivatePromocodeErrorResp {
  code: number;
  msg: string;
  details: {
    validationErrors?: {
      items?: {
        field: 'code',
        msg: string;
      }[];
    }
  };
}

const activeDepositPromocode = async ({
  code, state
}: Options) => {
  const response = await axios.put<{bonusPercent : string}>(
    '/deposit-bonus/v1/promo-code',
    {
      code,
      state,
    },
  )

  return response
}

interface Options {
  onSuccess?: () => void;
}

export const useActiveDepositPromocode = (opt: Options | void) => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: activeDepositPromocode,
    retry: retryFn,
    onSuccess() {
      queryClient.invalidateQueries([GET_HEAD_BANNERS_KIT_KEY]);
      queryClient.invalidateQueries([GET_BANNERS_KIT_KEY, 'SECTION_FINANCE'])
    },
  })
}
