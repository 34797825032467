import { path } from 'libs/path';
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { BonusBanner, MetaTraders, Tabs } from 'ui/molecules'

import { AccountsList } from './list'
import { TransactionHistory } from './transactions'
import { MoneyHistoryList } from 'pages/money/transactionsNew';

export const AccountsPage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const val = React.useMemo(() => {
    if (location.pathname === path.accounts.entry()) return 'My Accounts'
    if (location.pathname === path.accounts.moneyHistoryAccounts())
      return 'Transaction History'
  }, [location?.pathname])

  return (
    <AccountsContainer>
      <AccountsContent>
        <Tabs
          defaultValue={val}
          value={val}
          items={[
            {
              label: 'My Accounts',
              children: <AccountsList withDetails={true} />,
              onClick: () => navigate(path.accounts.entry()),
            },
            {
              label: 'Transaction History',
              children: <MoneyHistoryList />,
              onClick: () => navigate(path.accounts.moneyHistoryAccounts()),
            },
          ]}
        />
      </AccountsContent>
      <AccountsAside>
        <MetaTraders aside />
        <BonusBanner />
      </AccountsAside>
    </AccountsContainer>
  )
}

const Wrapper = styled.div`
  padding: 24px 20px;
  background-color: var(--color-gray-100);
  box-shadow: 0px 4px 6px -1px rgba(38, 33, 25, 0.1),
    0px 2px 4px -1px rgba(38, 33, 25, 0.06);
  border-start-start-radius: 0;
  border-start-end-radius: 8px;
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
  height: auto;
`

const AccountsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (min-width: 1050px) and (max-width: 1185px), (max-width: 1000px) {
    flex-direction: column;
  }
`

const AccountsContent = styled.div`
  width: 70%;

  @media (min-width: 1050px) and (max-width: 1185px), (max-width: 1000px) {
    width: 100%;
  }
`

const AccountsAside = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 20px;
  padding-top: 42px;

  @media (min-width: 1050px) and (max-width: 1185px), (max-width: 1000px) {
    padding-top: 0;
    width: 100%;
  }
`
