import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';

import { Icon2, Text, Title } from 'ui/atoms';
import { useIsMobile } from 'libs/hooks/useIsMobile';

import bonusPng from './regBonus.png';

import { useHideBannerKit } from 'services/banners/mutations/useHideBannerKit';
import { CountdownTimer } from 'ui/atoms/timer';

interface Props {
  item: {
    extraDepositPercents: number;
    finishTs: string;
  };
  canBeClosed: boolean;
  name: string;
}

export const RegBonus: React.FC<Props> = ({ item, canBeClosed, name }) => {
  const [isHide, setisHide] = useState(false);
  const isMobile = useIsMobile();

  const [t] = useTranslation();

  const { mutateAsync } = useHideBannerKit();

  const onClose = async () => {
    try {
      await mutateAsync(name);

      setisHide(true);
    } catch (error) {
      console.log(error);
    }
  };

  if (isHide) return null;

  return (
    <BonusStyled 
      className={`
        md:w-[600px] w-full md:py-[16px] px-[16px] py-[12px] pb-[48px]
        md:pb-[16px]
        md:h-auto ${isMobile ? 'bg-right-bottom' : 'bg-right'}
      `}
      isMobile={ isMobile }
      src={isMobile ? '' : bonusPng}
    >
      {isMobile && 
        <LazyLoadImage
          // width={isMobile ? 156 : '100%'}
          src={bonusPng}
          className={`absolute right-0 bottom-0 w-[240px]`}
        />
      }
      <Title level={3} withTranslate={false}>
       {t('Trade more with a bonus', {'item.extraDepositPercents': `+${item.extraDepositPercents}%`})}
      </Title>
      <Text level={2} className='mt-[4px] md:max-w-[380px]'>
        Make a deposit now to get the biggest bonus for your trading. Seize the chance! The offer is limited and will expire soon:
      </Text>
      <Title level={3} withTranslate={false} className='mt-[6px]'>
        <CountdownTimer targetTimestamp={+item.finishTs} />
      </Title>

      {canBeClosed && <Icon2 name='xMarkIcon' className='absolute right-[16px] top-[16px] cursor-pointer' onClick={onClose} />}
    </BonusStyled>
  )
}

const BonusStyled = styled.div<{src: string, isMobile: boolean}>`
  border-radius: 8px;
  position: relative;
  background-color: #A1BAFF;
  background-image: ${(p) => `url(${p.src})`};

  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
`;