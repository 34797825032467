import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import mobileBackground from '../../images/banner-background-mobile.webp'
import desktopBackgroundRtl from '../../images/banner-background-rtl.webp'
import desktopBackground from '../../images/banner-background.webp'
import blackCoin from '../../images/black-coin.svg'
import colorCoin from '../../images/color-coin.png'
import colorCoinWeb from '../../images/color-coin.webp'
import warning from '../../images/exclaimation-circle.svg'
import gift from '../../images/gift-choice.svg'

export const Container = styled.div<{ isActive: boolean; isInner: boolean }>`
  background-color: #f9feff;
  background-image: url(${desktopBackground});
  background-size: auto 100%;
  background-position: right center;
  background-repeat: no-repeat;
  overflow: hidden;

  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      background-image: url(${desktopBackgroundRtl});
      background-position: left center;
    `}

  ${({ isInner }) =>
    !isInner &&
    css`
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 2px 4px -1px rgba(38, 33, 25, 0.06),
        0px 4px 6px -1px rgba(38, 33, 25, 0.1);

      @media (max-width: 540px) {
        padding: 12px;
      }
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      display: grid;
      grid-template-columns: 170px 1fr 170px;
      grid-template-rows: 160px;
      gap: 20px;
      font-size: var(--body-font-size-medium);
      line-height: var(--body-line-height-medium);
      background-color: var(--color-gray-100);
      background-image: none;

      @media (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 130px minmax(102px, auto);
        gap: 12px;
      }
    `}

    ${({ isActive }) =>
    !isActive &&
    css`
      @media (max-width: 540px) {
        background-image: none;
      }
    `}
`

export const Subtitle = styled.div`
  margin-top: 12px;
  font-size: 20px;
  line-height: 25px;
  color: #4a4a4a;

  @media (max-width: 1219px) {
    margin-top: 8px;
    font-size: var(--body-font-size-regular);
    line-height: var(--body-line-height-regular);
  }
`

export const Buttons = styled.div`
  margin-top: 35px;
  display: grid;
  grid-template-columns: minmax(160px, max-content) max-content;
  gap: 16px;

  @media (max-width: 740px) {
    margin-top: 33px;
  }

  @media (max-width: 640px) {
    grid-template-columns: auto;
    justify-items: start;
  }

  @media (max-width: 540px) {
    margin: 0 -12px -12px;
    padding: 46% 12px 12px;
    background-image: url(${mobileBackground});
    background-repeat: no-repeat;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
    background-position: center bottom;
    background-size: cover;
  }
`

export const TotalCrowns = styled.div`
  padding: 16px;
  display: grid;
  align-content: center;
  place-items: center;
  text-align: center;
  background-color: var(--color-gray-200);
  border-radius: 8px;

  &::before {
    content: '';
    margin-bottom: 8px;
    width: 54px;
    height: 54px;
    display: block;
    background-image: url(${colorCoin});
    background-image: image-set(url(${colorCoinWeb}), url(${colorCoin}));
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;

    @media (max-width: 767px) {
      width: 32px;
      height: 32px;
    }
  }
`

export const TotalText = styled.div`
  margin-top: 4px;
  font-weight: 500;
  color: var(--color-gray-600);

  @media (max-width: 767px) {
    margin-top: 6px;
    font-size: var(--body-font-size-small);
    line-height: var(--body-line-height-small);
  }
`

export const ProgressWrapper = styled.div`
  border: 1px solid var(--color-gray-300);
  padding: 16px;
  display: grid;
  align-content: space-between;
  font-weight: 500;
  border-radius: 8px;

  @media (max-width: 767px) {
    grid-column: span 2;
    grid-row-start: 2;
    padding: 12px;
    font-size: 10px;
    line-height: 14px;
  }
`

export const ProgressLine = styled.div`
  position: relative;
  margin-top: 8px;
  margin-bottom: 4px;
  padding: 0 8px;
  height: 32px;
  display: flex;
  align-items: center;
  color: var(--color-gray-500);
  background-color: var(--color-gray-300);
  border-radius: 8px;

  @media (max-width: 767px) {
    height: 24px;
  }
`

export const ProgressValue = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  color: var(--color-neutral);
  background-color: var(--color-sunray-400);
  border-radius: 8px;

  @media (max-width: 767px) {
    gap: 4px;
  }

  &::before {
    content: '';
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    background-image: url(${blackCoin});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    @media (max-width: 767px) {
      width: 12px;
      height: 12px;
    }
  }
`

export const Range = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--color-gray-700);
`

export const GiftWrapper = styled(({ giftImage, ...props }) => (
  <Link {...props} />
))`
  position: relative;
  padding: 12px;
  display: grid;
  align-content: end;
  text-align: center;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 500;
  background-color: var(--color-gray-200);
  border-radius: 8px;
  background-image: url(${gift});
  background-repeat: no-repeat;
  background-size: 64px auto;
  background-position: top 31px center;

  @media (max-width: 767px) {
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
    background-position: center top 17px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    inset-inline-end: 8px;
    width: 24px;
    height: 24px;
    background-image: url(${warning});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  ${({ giftImage }) =>
    !!giftImage &&
    css`
      background-image: url(${giftImage});
      background-position: center center;
      background-size: cover;

      @media (max-width: 767px) {
        background-size: contain;
        background-position: center center;
      }

      &::before {
        content: none;
      }
    `}
`
