import { format } from "date-fns";
import i18next from 'i18next'
import { localeMap } from 'libs/dateUtils'
import { TransactionItem, TransactionResponse } from 'services/money/get/moneyHistory';

const locale = localeMap[i18next.language]

const formatDate = (date: string) =>
  format(new Date(+date * 1000), 'LLL d, iiii', {
    locale,
  })

export type SortHistoryForDateType = ReturnType<typeof sortHistoryForDate>;

export const sortHistoryForDate = (data: TransactionResponse) => {
  if (!data) return undefined;
  const items = Object.entries(
    data.items.reduce((acc, value) => {
      if (acc[formatDate(value.createdTs)]) {
        acc[formatDate(value.createdTs)].push(value)
      } else {
        acc[formatDate(value.createdTs)] = [value]
      }
      return acc
    }, {} as { [key: string]: TransactionItem[] }) || {},
  );

  return {
    ...data,
    items,
  };
}