import React, { useMemo } from 'react';
import { useUnit } from 'effector-react';

import { useGetTransactions, TransactionItem } from 'services/money/get/moneyHistory';
import { Pagination } from 'ui/atoms/pagination/Pagination';

import { 
  $moneyHistoryFilter, $moneyHistoryPagination, 
  MoneyHistoryFilterStore, 
  setPageEvent, setPerPageEvent 
} from '../model/moneyHistoryModel';
import { NotFound } from './notFound';
import { Transaction } from './transaction';

import { SortHistoryForDateType, sortHistoryForDate } from '../utils/sortHistoryForDate';
import * as S from './styled';

type Props = {
  defaultFilter?: Partial<MoneyHistoryFilterStore>;
  notFoundClickFn?: () => void;
}

export const TransactionList: React.FC<Props> = ({ defaultFilter = {}, notFoundClickFn }) => {
  const { page, perPage } = useUnit($moneyHistoryPagination);
  const filter = useUnit($moneyHistoryFilter);

  const filterMemo = useMemo(() => ({ ...filter, ...defaultFilter }), [filter, defaultFilter]);
  
  const { data } = useGetTransactions<SortHistoryForDateType>(
    { filter: filterMemo, page, perPage, onSelect: sortHistoryForDate }
  );

  const getTotalItems = useMemo(() => {
    if (!data) return 0;
    try {
      return (perPage * +data.totalPages);
    } catch (error) {
      return 0;
    }
  }, [data?.totalPages, perPage]);

  if (!data) return null;

  return (
    <>
    {data?.items.length === 0 ? (
      <NotFound hasFilters={ !filter.isDefaultFilter} notFoundClickFn={notFoundClickFn} />
    ) : (
      data.items.map(([date, items], index) => (
        <div key={index}>
          <S.Date>{date}</S.Date>
          <S.InnerWrapper>
            {items.map((item: TransactionItem) => (
              <Transaction key={item.id} data={item} />
            ))}
          </S.InnerWrapper>
        </div>
      ))
    )}
    {(+data.totalPages > 1 || perPage !== 20) && (
      <Pagination
        pageSize={perPage}
        current={page}
        total={getTotalItems}
        onChange={setPageEvent}
        onChangePerPage={setPerPageEvent}
        showSizeChanger
      />
    )}
  </>
  )
}
