import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useUnit } from 'effector-react';

import { ProcessingProvider } from 'services/money/get/types';
import { Button, Text } from 'ui/atoms';

import { $amountField } from './model';
import { AdditionFields, useSbmWithdrawal } from '../hooks/useSbmWithdrawal';
import { setOtpFormEvent } from '../model';

type Props = {
  // onSuccessCreateDep: (url: CreateWithdrawalResponse) => void;
  isDisabled: boolean | undefined;
  gatewayProvider: ProcessingProvider;
}

export const SubmitButton = ({ isDisabled, gatewayProvider }: Props) => {
  const { errorType } = useUnit($amountField);

  const { handleSubmit, setError } = useFormContext<AdditionFields>();

  const { onCreateWithdrawal, isLoading } = useSbmWithdrawal({ gatewayProvider, setError });

  const onCreate = async (fields: AdditionFields) => {
    try {
      const resp = await onCreateWithdrawal(fields);
      
      if (!resp) return;

      if (resp.needOTP) {
        setOtpFormEvent(true);
      }

      if (resp.redirectUrl) {
        // TODO
      }
    } catch (error: any) {
    }
  }

  return (
    <div className='flex w-full justify-center mt-[6rem]'>
      <Button
        name='sbm'
        design='primary'
        disabled={ errorType === 'LIMIT_ERROR' || isDisabled }
        loading={ isLoading }
        className='min-w-[23rem]'
        onClick={handleSubmit(onCreate)}
      >
        <Text>Withdrawal</Text>
      </Button>
    </div>
  )
}
