import { useWindowSize } from '@uidotdev/usehooks'
import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { path } from 'libs/path'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Tabs } from 'ui/molecules'
import { Modal } from 'ui/molecules/modal_draft'
import { TabContent } from 'ui/molecules/tabs'

import { CompareStrategies } from './compareStrategies'
import { CreateStrategy } from './createStrategy'
import { EditStrategy } from './editStrategy'
import {
  $isInvestorBoardInfoVisible,
  $isTraderAreaInfoVisible,
  $strategiesComparisonList,
  setInvestorBoardInfoVisibility,
  setStrategiesComparisonList,
  setTraderAreaInfoVisibility,
} from './model'
import { Alerts } from './molecules/alerts'
import { ComparisonModal } from './molecules/comparisonModal'
import { PublicTraderArea } from './molecules/publicTraderArea'
import { StartCopying } from './startCopying'
import { StrategiesList } from './strategiesList'
import { StrategyPreviewForInvestors } from './strategyPreviews/previewForInvestors'
import { StrategyPreviewForTraders } from './strategyPreviews/previewForTraders'
import * as Styled from './styled'
import { TradersList } from './tradersList'
import { TraderTables } from './traderTables'

const HowItWorks = ({
  title,
  isOpened,
  onToggle,
  children,
}: {
  title: string
  isOpened: boolean
  onToggle: () => void
  children: ReactNode
}) => {
  const [t] = useTranslation()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { width } = useWindowSize()
  const isMobileMode = width && width < 600

  const videoLink = isMobileMode ? '1A1byCyTD30' : 'WQnXXyCNIpM'

  return (
    <>
      <Styled.ChevronButton isOpened={isOpened} onClick={onToggle} />
      {isOpened ? (
        <Styled.InfoWrapper>
          <Styled.InfoTitle level={isMobileMode ? 3 : 2}>
            {t(title)}
          </Styled.InfoTitle>
          <Styled.InfoText>{children}</Styled.InfoText>
          <Styled.InfoButton
            name="how-it-works"
            size={isMobileMode ? 'small' : 'medium'}
            design="secondary"
            onClick={() => setIsModalVisible(true)}
          >
            How it works
          </Styled.InfoButton>
        </Styled.InfoWrapper>
      ) : (
        <Styled.SmallTitle onClick={onToggle}>{t(title)}</Styled.SmallTitle>
      )}

      <Modal
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        width={1000}
        footer={false}
      >
        <Styled.VideoWrapper>
          <iframe
            className="absolute w-full h-full"
            id="ytplayer"
            src={`https://www.youtube.com/embed/${videoLink}?autoplay=1&mute=1`}
            allowFullScreen
            allow="autoplay; encrypted-media"
            title="How it works"
          />
        </Styled.VideoWrapper>
        <Styled.VideoHint>
          <Trans
            t={t}
            i18nKey="If the video is not playing, watch it on YouTube"
          >
            If the video is not playing, watch it on{' '}
            <a
              href={`https://www.youtube.com/watch?v=${videoLink}`}
              target="_blank"
              rel="noreferrer"
              className="text-sunray.700 font-medium"
            >
              YouTube
            </a>
          </Trans>
        </Styled.VideoHint>
      </Modal>
    </>
  )
}

export const Copytrade = () => {
  const navigate = useNavigate()

  const { pathname } = useLocation()
  const { strategyID } = useParams()

  const croppedPathname = pathname.replace(/\/$/g, '')

  const [t, i18n] = useTranslation()

  const { userID } = useStore($userProfile)
  const strategiesComparisonList = useStore($strategiesComparisonList)

  const isTraderAreaInfoVisible = useStore($isTraderAreaInfoVisible)
  const isInvestorBoardInfoVisible = useStore($isInvestorBoardInfoVisible)

  const [activeTab, setActiveTab] = useState<'Investor Board' | 'Trader Area'>(
    pathname.includes('trader-area') ? 'Trader Area' : 'Investor Board',
  )

  const isFullScreen = [
    path.copytrade.createStrategy(),
    path.copytrade.editStrategy(strategyID || ''),
    path.copytrade.startCopying.selectAccount(),
    path.copytrade.startCopying.accountSettings(),
    path.copytrade.startCopying.replenishAccount(),
  ].every((path) => croppedPathname !== path)

  const isTraderAreaDisabled = [
    path.copytrade.startCopying.selectAccount(),
    path.copytrade.startCopying.accountSettings(),
    path.copytrade.startCopying.replenishAccount(),
  ].some((path) => croppedPathname === path)

  const isInvestorBoardDisabled = [
    path.copytrade.createStrategy(),
    path.copytrade.editStrategy(strategyID || ''),
  ].some((path) => croppedPathname === path)

  const traderTabContent = useMemo(() => {
    switch (croppedPathname) {
      case path.copytrade.createStrategy(): {
        return <CreateStrategy />
      }
      case path.copytrade.editStrategy(strategyID || ''): {
        return <EditStrategy />
      }
      case path.copytrade.strategyForTrader(strategyID || ''): {
        return <StrategyPreviewForTraders />
      }
      default: {
        return userID ? (
          <HowItWorks
            title="Earn more from trading"
            isOpened={isTraderAreaInfoVisible}
            onToggle={() =>
              setTraderAreaInfoVisibility(!isTraderAreaInfoVisible)
            }
          >
            <>
              {t(
                'In Copytrade, investors pay for copying your trades – and you get extra profits for your positions.',
              )}
              <br />{' '}
              {t(
                'Create a strategy, set a commission from $1, and keep trading.',
              )}{' '}
              <Styled.Terms
                href={path.copytrade.terms(i18n.language)}
                target="_blank"
              >
                {t('Terms and conditions')}
              </Styled.Terms>
            </>
          </HowItWorks>
        ) : (
          <PublicTraderArea />
        )
      }
    }
  }, [pathname, isTraderAreaInfoVisible, userID])

  const investorTabContent = useMemo(() => {
    switch (croppedPathname) {
      case path.copytrade.strategyForInvestor(strategyID || ''): {
        return <StrategyPreviewForInvestors />
      }
      case path.copytrade.compareStrategies(): {
        return <CompareStrategies />
      }
      case path.copytrade.investorEntry(): {
        return (
          <TabContent>
            <HowItWorks
              title={
                userID
                  ? 'Profit from the best traders'
                  : 'Headway Copytrade: Profit from the best traders'
              }
              isOpened={isInvestorBoardInfoVisible}
              onToggle={() =>
                setInvestorBoardInfoVisibility(!isInvestorBoardInfoVisible)
              }
            >
              <>
                {t(
                  'In Copytrade, you invest in other users, copy their orders, and gain profit.',
                )}
                <br />{' '}
                <Trans
                  i18nKey="Check the Terms and conditions and select a strategy that works to your advantage."
                  t={t}
                >
                  Check the{' '}
                  <Styled.Terms
                    href={path.copytrade.terms(i18n.language)}
                    target="_blank"
                  >
                    Terms and conditions
                  </Styled.Terms>{' '}
                  and select a strategy that works to your advantage.
                </Trans>
              </>
            </HowItWorks>
          </TabContent>
        )
      }
      default: {
        return <StartCopying />
      }
    }
  }, [pathname, isInvestorBoardInfoVisible])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' })

    if (pathname === path.copytrade.traderEntry()) {
      setActiveTab('Trader Area')
    } else if (pathname === path.copytrade.investorEntry()) {
      setActiveTab('Investor Board')
    }
  }, [pathname])

  useEffect(() => {
    if (userID) {
      const userStrategiesComparisonList = localStorage.getItem(
        `strategiesComparisonList-${userID}`,
      )

      if (userStrategiesComparisonList) {
        setStrategiesComparisonList(JSON.parse(userStrategiesComparisonList))
      }
    }
  }, [userID])

  useEffect(() => {
    // change OG

    const meta = document.querySelector(
      'meta[property="og:title"]',
    ) as HTMLMetaElement | null
    const initialMetaContent = meta?.content

    meta?.setAttribute(
      'content',
      'Copytrade from Headway – unlock all the benefits of social trading. Copy the best and earn more!',
    )

    return () => meta?.setAttribute('content', initialMetaContent || '')
  }, [])

  return (
    <>
      <Helmet>
        <title>
          Copytrade from Headway – unlock all the benefits of social trading!
        </title>
      </Helmet>

      <Styled.ContentContainer isFullScreen={isFullScreen}>
        <Alerts currentPath={pathname} />

        <Tabs
          value={activeTab}
          fullHeight={false}
          withOutWrapp
          items={[
            {
              label: 'Investor Board',
              children: investorTabContent,
              onClick: () => navigate(path.copytrade.investorEntry()),
              disabled: isInvestorBoardDisabled,
            },
            {
              label: 'Trader Area',
              children: <TabContent>{traderTabContent}</TabContent>,
              onClick: () => navigate(path.copytrade.traderEntry()),
              disabled: isTraderAreaDisabled,
            },
          ]}
        />

        {activeTab === 'Trader Area' ? (
          userID ? (
            <>
              {croppedPathname === path.copytrade.traderEntry() && (
                <StrategiesList />
              )}
              {isFullScreen && <TraderTables />}
            </>
          ) : (
            ''
          )
        ) : (
          <>
            {croppedPathname === path.copytrade.investorEntry() && (
              <TradersList />
            )}
            {userID &&
              croppedPathname !== path.copytrade.compareStrategies() &&
              strategiesComparisonList.length > 0 && <ComparisonModal />}
          </>
        )}
      </Styled.ContentContainer>
    </>
  )
}
