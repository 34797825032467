import styled, { css } from 'styled-components/macro'
import { Title as UiTitle } from 'ui/atoms'

export const Wrapper = styled.div<{
  backgroundColor: string
  backgroundImage: string
  rtlBackgroundImage?: string
  color?: string
}>`
  position: relative;
  padding: 24px 20px;
  height: 300px;
  display: grid;
  justify-content: start;
  border-radius: 8px;
  background-size: auto 300px;
  background-repeat: no-repeat;

  @media (max-width: 767px) {
    height: 260px;
    background-size: auto 260px;
  }

  ${(props) =>
    css`
      color: ${props.color || 'var(--color-gray-700)'};
      background-color: ${props.backgroundColor};
      background-image: ${props.theme.dir === 'rtl' && props.rtlBackgroundImage
        ? `url(${props.rtlBackgroundImage})`
        : `url(${props.backgroundImage})`};
      background-position: ${props.theme.dir === 'rtl' &&
      props.rtlBackgroundImage
        ? 'bottom left'
        : 'bottom right'};
    `}
`

export const Title = styled(UiTitle)``

export const Grid = styled.div`
  margin-top: auto;
  display: grid;
  gap: 16px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
`

export const Value = styled.div`
  margin-top: 4px;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;

  @media (max-width: 599px) {
    font-size: 24px;
    line-height: 1.35;
  }
`

export const Tag = styled.div`
  position: absolute;
  top: 12px;
  inset-inline-end: 20px;
  padding: 12px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  background-color: color-mix(
    in srgb,
    var(--color-eucalyptus-500) 70%,
    transparent
  );
  border-radius: 9999px;
  backdrop-filter: blur(20px);

  @media (max-width: 1023px) {
    top: 20px;
    padding: 8px;
    font-size: var(--body-font-size-small);
    line-height: var(--body-line-height-small);
  }
`
