import { useStore } from 'effector-react'
import { setAuthSocialName, submitOAuthToken } from 'features/oath/model'
import { RouterHistory } from 'features/router'
import { $userProfile } from 'features/user'
import { $isUserEmailConfirmed } from 'features/user/model'
import { env } from 'libs/constants'
import { path } from 'libs/path'
import queryString from 'query-string'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import styled from 'styled-components/macro'
import { AuthHeader } from 'ui/organisms'

type GenericTemplateProps = {
  children?: React.ReactNode
}

export const AuthTemplate: React.FC<GenericTemplateProps> = () => {
  const { pathname, search } = useLocation()
  const [_, setSearchParams] = useSearchParams()

  const user = useStore($userProfile)
  const isUserEmailConfirmed = useStore($isUserEmailConfirmed)

  const [t] = useTranslation()

  const init = async (search: string) => {
    if (search) {
      const { code, state } = queryString.parse(search)

      if (!code) {
        return
      }

      // if (state && state === getCookie('fbState')) {
      if (state) {
        setAuthSocialName('facebook')

        submitOAuthToken({
          socialType: 2,
          authorizationCode: code as string,
          partnerKey: localStorage.getItem('partnerId') as string,
          regPromoCode: localStorage.getItem('regPromoCode') as string,
        })
      } else {
        setAuthSocialName('google')

        // вручную получаем токен для авторизации через гугл
        try {
          const response = await fetch('https://oauth2.googleapis.com/token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
              code: code as string,
              client_id: env.google.clientId,
              client_secret: env.google.clientSecret,
              redirect_uri: `${window.location.origin}/user/login`,
              grant_type: 'authorization_code',
            }).toString(),
          })
          const data = await response.json()

          submitOAuthToken({
            socialType: 1,
            accessToken: data.access_token,
            partnerKey: localStorage.getItem('partnerId') as string,
            regPromoCode: localStorage.getItem('regPromoCode') as string,
          })
        } catch {
        } finally {
          setSearchParams('')
        }
      }
    }
  }

  useEffect(() => {
    init(search)
  }, [search])

  useEffect(() => {
    if (pathname !== path.register.oOath.entry()) {
      localStorage.removeItem('registrationParams')
    }
  }, [pathname])

  if (
    user.userID &&
    pathname !== path.register.email.confirm() &&
    pathname !== path.register.email.confirmSuccess() &&
    (isUserEmailConfirmed ||
      (pathname !== path.register.email.confirmRepeat() &&
        pathname !== path.register.email.password()))
  ) {
    const returnUrl = localStorage.getItem('returnUrl')
    localStorage.removeItem('returnUrl')

    return <Navigate to={returnUrl ? returnUrl : path.dashboard.entry()} />
  }

  return (
    <>
      <RouterHistory />
      <AuthLayout>
        <AuthHeader />
        <AuthMain>
          <Outlet />
        </AuthMain>
        <AuthTerms>
          <Trans
            i18nKey="I have read and agree to Headway's Terms of service and Privacy Policy."
            t={t}
          >
            I have read and agree to Headway's{' '}
            <a
              className="text-azure.500"
              href={path.terms()}
              target="_blank"
              rel="noreferrer"
            >
              Terms of service
            </a>{' '}
            and{' '}
            <a
              className="text-azure.500"
              href={path.privacy()}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </Trans>
        </AuthTerms>
      </AuthLayout>
    </>
  )
}

const AuthLayout = styled.div``

const AuthMain = styled.div`
  min-height: calc(100vh - 116px - 53px);
  display: flex;
  justify-content: center;
  padding: 0 32px;

  @media (max-width: 540px) {
    padding: 0 16px;
    min-height: calc(100vh - 108px - 76px);
  }
`

const AuthTerms = styled.div`
  padding: 16px 32px;
  text-align: center;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);

  @media (max-width: 540px) {
    margin-top: 24px;
    padding: 16px;
    text-align: left;
    font-size: var(--body-font-size-small);
    line-height: var(--body-line-height-small);
  }
`
