import { AccountTariffName } from 'pages/accounts/create/types'
import { AccountPlatform } from 'pages/accounts/types'
import { Wallet } from 'pages/dashboard/widgets/wallets/types'
import { Account } from 'services/accounts/types'
import { Money, Tag, Text } from 'ui/atoms'

import * as Styled from './styled'

export type Platform =
  | AccountPlatform
  | 'ACCOUNT_PLATFORM_MT4'
  | 'ACCOUNT_PLATFORM_MT5'
  | 'PLATFORM_MT4'
  | 'PLATFORM_MT5'

export const mapAccountPlatform: Record<Platform, string> = {
  PAAP_INVALID: 'Invalid',
  PAAP_MT5: 'MT5',
  PAAP_MT4: 'MT4',
  ACCOUNT_PLATFORM_MT4: 'MT4',
  ACCOUNT_PLATFORM_MT5: 'MT5',
  PLATFORM_MT4: 'MT4',
  PLATFORM_MT5: 'MT5',
}

export const mapAccountTariff: Record<AccountTariffName, string> = {
  cent: 'Cent',
  standard: 'Standard',
  pro: 'Pro',
  demoCent: 'Demo Cent',
  demoStandard: 'Demo Standard',
  demoPro: 'Pro',
}

type Props = Account & {
  // не мапит платформу, а сразу выводить текст, который в поле platform
 platformNoMap?: boolean;
};

export const AccountLine = (props: Props) => {
  return (
    <Styled.Wrapper>
      <div className="flex items-center flex-wrap gap-[4px]">
        <Text withTranslate={ false } level={2} className=''>
          {props.name || props.accountName|| props.accountID}
        </Text>
        {props.isFixRate && <Tag size='small' design="eucalyptus">Fixed rate</Tag>}
        {/* todo: change to Tag component */}
        <Styled.Plarform>
          { props.platformNoMap ? props.platform : (mapAccountPlatform[props.platform] || 'Unknown platform')}
        </Styled.Plarform>
        <Styled.Tariff>
          {mapAccountTariff[
            (props.tariffName || props.tariff) as AccountTariffName
          ] ||
            props.tariffName ||
            props.tariff}
        </Styled.Tariff>
      </div>
      {typeof props.balance !== 'undefined' && (
        <div className="font-medium">
          <Money value={+props.balance} currency={props.currency} />
        </div>
      )}
    </Styled.Wrapper>
  )
}

export const WalletLine = (props: Wallet) => {
  return (
    <Styled.Wrapper>
      <span>{props.name || `Wallet ${props.currency}`}</span>
      {props.balance ? (
        <div className="font-medium">
          <Money value={props.balance} currency={props.currency} />
        </div>
      ) : null}
    </Styled.Wrapper>
  )
}
