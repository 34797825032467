import { CountryName } from 'pages/contest/molecules/countryName'
import React from 'react'
import { CategoryBestType } from 'services/ramadan/types'
import { Money, Text } from 'ui/atoms'

interface Props {
  titleLabel: string
  category: CategoryBestType | null
  footerLabel: string
  footerValue?: string
}
export const DayCardCategory: React.FC<Props> = ({
  titleLabel,
  category,
  footerLabel,
  footerValue,
}) => {
  return (
    <>
      <div className="flex justify-between items-center">
        <Text level={2}>{titleLabel}</Text>
        {category && (
          <Text
            withTranslate={false}
            className="!text-eucalyptus.600"
            weight="medium"
          >
            <Money value={category.prize} currency={'USD'} />
          </Text>
        )}
      </div>

      <div className="flex md:flex-col md:mt-[24px] mt-[16px]">
        <div className="md:w-full flex flex-col md:items-center items-start flex-1">
          {category && (
            <>
              <Text
                className="text-center mb-[8px]"
                level={2}
                weight="medium"
                withTranslate={false}
              >
                {category.isYou ? 'You' : category.name}
              </Text>

              <CountryName code={category.country} />
            </>
          )}
        </div>

        {footerValue && (
          <div className="md:w-full flex md:flex-row flex-col justify-center md:mt-[12px]">
            <Text
              className="text-right md:mb-[0px] mb-[4px]"
              level={2}
              color="subtext"
              withTranslate={false}
            >
              {footerLabel}:
            </Text>

            <Text
              withTranslate={false}
              weight="medium"
              className="text-right md:ml-[4px]"
              level={2}
            >
              {footerValue}
            </Text>
          </div>
        )}
      </div>
    </>
  )
}
