import { useWindowSize } from '@uidotdev/usehooks'
import { format } from 'date-fns'
import { localeMap } from 'libs/dateUtils'
import * as Styled from 'pages/contests/contestDetails/styled'
import { Timer } from 'pages/contests/molecules/timer'
import { useTranslation } from 'react-i18next'
import type { Contest } from 'services/ramadan/types'
import { Button, Tag } from 'ui/atoms'
import { toCurrency } from 'ui/atoms/money'

import { contestStatuses, participantStatuses } from '../../utils'
import { useActionButton } from '../../utils/useActionButton'
import mobileBackground from './background-mobile.webp'
import background from './background.webp'
import * as S from './styled'

export const ContestHeader = ({ data }: { data: Contest }) => {
  const [t, i18n] = useTranslation()
  const locale = localeMap[i18n.language]

  const { width } = useWindowSize()

  const { buttonLabel, onClickButton, isLoading } = useActionButton({
    isJoined: data.participant.isJoined,
    isMadeDeposit: data.participant.isMadeDeposit,
  })

  const targetTimestamp =
    data.promo.status === 'REGISTRATION'
      ? data.promo.promoStartsTs
      : data.promoDay && data.promoDay.currentDay.dayType !== 'WEEKEND'
      ? data.promoDay.currentDay.endsTs
      : data.promoDay && data.promoDay.nearestActiveDay
      ? data.promoDay.nearestActiveDay.startsTs
      : ''

  return (
    <S.Wrapper>
      <Styled.ContestInfo>
        <Styled.InfoTopWrapper>
          <div className="flex gap-[4px]">
            <Tag design={contestStatuses[data.promo.status].tagDesign}>
              {t(contestStatuses[data.promo.status].name)}
            </Tag>
            {data.participant.status !== 'UNREGISTERED' && (
              <Tag
                design={participantStatuses[data.participant.status].tagDesign}
              >
                {t(participantStatuses[data.participant.status].name)}
              </Tag>
            )}
          </div>
          <div>
            {format(new Date(+data.promo.promoEndsTs * 1000), 'LLLL yyyy', {
              locale,
            })}
          </div>
        </Styled.InfoTopWrapper>
        <Styled.ContestTitle>
          {t('Headway Ramadan Challenge')}
        </Styled.ContestTitle>
        <Styled.ContestDescription>
          {t(
            'Trade in the daily challenge and earn a good fortune for yourself and your community. Do a good deed with Headway!',
          )}
        </Styled.ContestDescription>
        <Styled.ContestTerms>
          <div className="flex">
            <Styled.TermTitle>{t('Prize pool')}</Styled.TermTitle>
            <span className="text-eucalyptus.700">
              {toCurrency(data.promo.prizes.prizePool, 'USD', 0)}
            </span>
          </div>
          <div className="flex">
            <Styled.TermTitle>{t('Winners')}</Styled.TermTitle>
            <span className="text-eucalyptus.700">
              {data.promo.prizes.winners}
            </span>
          </div>
        </Styled.ContestTerms>
        {data.promo.status !== 'ENDED' && (
          <Styled.InfoBottomWrapper>
            <div>
              <div className="mb-[12px] text-body.medium leading-body.medium">
                {data.promo.status === 'REGISTRATION'
                  ? t('Challenge starts:')
                  : data.promoDay &&
                    data.promoDay.currentDay.dayType !== 'WEEKEND'
                  ? `Trading day ${data.promoDay.currentDay.dayID} of ${data.promo.daysCount} ends:`
                  : data.promoDay && data.promoDay.nearestActiveDay
                  ? t('Trading starts:')
                  : null}
                {(data.promo.status === 'REGISTRATION' ||
                  (data.promoDay &&
                    data.promoDay.currentDay.dayType === 'WEEKEND')) && (
                  <span className="ms-[8px] font-medium">
                    {format(new Date(+targetTimestamp * 1000), 'LLL d, yyyy', {
                      locale,
                    })}
                  </span>
                )}
              </div>
              <Timer targetTimestamp={targetTimestamp} />
            </div>
            {data.participant.status === 'UNREGISTERED' && (
              <div className="mobile:w-full">
                {data.participant.isJoined && (
                  <div className="mb-[12px] text-center text-body.medium leading-body.medium text-opal.600">
                    {t('Complete joining the contest')}
                  </div>
                )}
                <Button
                  name="join-contest"
                  size={width && width < 600 ? 'medium' : 'large'}
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={onClickButton}
                  className="min-w-[240px] mobile:w-full"
                >
                  {buttonLabel}
                </Button>
              </div>
            )}
          </Styled.InfoBottomWrapper>
        )}
      </Styled.ContestInfo>
      {width &&
        (width < 600 || width > 860) &&
        (width > 1170 || width < 1051) && (
          <Styled.ContestImage>
            <img
              src={width < 600 ? mobileBackground : background}
              alt="Headway Ramadan Challenge"
            />
          </Styled.ContestImage>
        )}
    </S.Wrapper>
  )
}
