import { useMutation } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { retryFn } from 'services/helpers/retryFn'

interface DataObj {
  name: string
}
const hideActivity = async (data: DataObj) => {
  const res = await axios.patch('/contentkit/v1/activity/hide', data)

  return res.data
}

export const useHideActivity = () => {
  return useMutation({
    mutationFn: hideActivity,
    retry: retryFn,
  })
}
