import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useGetPopup } from 'services/contentKit/get/useGetHeadBanner';

import { RegBonusPopup } from './RegBonusPopup';
import { useHidePopup } from 'services/contentKit/mutation/useHidePopup';

export const PopUpContainer = () => {
  const [isShowPopup, setIsShowPopup] = useState(true);

  const { data, refetch } = useGetPopup({});

  const { mutateAsync, isLoading } = useHidePopup();

  const onClosePopUp = useCallback(
    async (name: string) => {
      try {
        setIsShowPopup(false);
        await mutateAsync(name);
        refetch();
      } catch (error) {
        
      }
    },
    [],
  );

  useEffect(() => {
    setIsShowPopup(!!data?.name);
  }, [data]);
  
  
  const getPopup = useCallback(() => {
    if (!data || !isShowPopup) return;
    
    if (data.name === 'depositBonus.regBonus') {
      return (
        <RegBonusPopup
          name={data.name}
          params={data.params as any}
          onClose={() => onClosePopUp(data.name)}
        />
      )
    }
  }, [data, isLoading, isShowPopup]);
  
  return (
    <>
    {getPopup()}
    </>
  )
}
