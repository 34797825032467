import { createEvent, restore } from "effector";
import { BonusDepositResp } from "services/money/get";

export const bonusStoreResetEvent = createEvent();


// DEPOSIT_BONUS
export const setDepositBonusEvent = createEvent<boolean>();
export const toggleBonusDepositEvent = createEvent();
export const $bonusDeposit = restore<boolean>(setDepositBonusEvent, false).on(toggleBonusDepositEvent, (state) => !state).reset(bonusStoreResetEvent);

export const setBonusPromocodeEvent = createEvent<string | null>();
export const $bonusPromocode = restore<string | null>(setBonusPromocodeEvent, null).reset(bonusStoreResetEvent);

export const setBonusDepositData = createEvent<undefined | BonusDepositResp>();
export const $bonusDepositData = restore(setBonusDepositData, null).reset(bonusStoreResetEvent);

// DEPOSIT AMOUNT NEED
export interface AmountForBigestPercent {
 amountNeeded: string | number;
 bigestPercent: string;
 currency: string;
}

export const setAmountForBigestPercent = createEvent<AmountForBigestPercent | null>();
export const $amountForBigestPercent = restore<AmountForBigestPercent | null>(setAmountForBigestPercent, null).reset(bonusStoreResetEvent);