import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Icon2, Input, Spinner2, TipyTooltip, Title, Text } from 'ui/atoms';

import { getStrFromAmount } from 'libs/parsers/getStrFromAmount';
import { AccountTariffName } from 'pages/accounts/create/types';
import { AccountSelect } from 'pages/money/molecules/newAccountSelect'
import { SourceItem } from 'services/payments/get/types'
import { Widget } from 'ui/molecules';
import { useIsMobile } from 'libs/hooks/useIsMobile';

import { useTransferWidget } from './hooks'
import * as Styled from './styled'

const errorMaxMessage = 'Limit exceeded. Please enter the maximum amount.';

const getCurrencySymbol = (currency: string) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(0)
    .replace(/\d/g, '')
}

type Props = {
  isWidget?: boolean;
}

export const TransferWidget: React.FC<Props> = ({ isWidget = true }) => {
  const [t] = useTranslation();
  
  const {
    source,
    destination,
    sources,
    destinations,
    amount,
    calculatedAmount,
    exchangeRate,
    setAmount,
    submit,
    pendingApprove,
    setSource,
    setDestination,
    errors,
    isTransferCompleted,
    resetTransfer,
    isLoading,
  } = useTransferWidget();

  const sourceCurrency = getCurrencySymbol(source?.currency || '');
  const destinationCurrency = getCurrencySymbol(destination?.currency || '');
  const isMobile = useIsMobile();

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const output = getStrFromAmount(e.target.value).split('.');
    const [decimalPart, floatPart] = output;
    
    if (floatPart) {
      output[1] = floatPart?.slice(0, 2) || '';
    }
    const final = output.join('.');

    setAmount(final);
  }

  const errorAmountMessage = React.useMemo(() => {
    if (!errors?.amount) return undefined;
    if (typeof errors.amount === 'string') return errors.amount;

    return errors.amount ? errorMaxMessage : undefined;
  }, [errors?.amount]);

  const sourcesList = React.useMemo(() => {
    const list: SourceItem[] = [];
    sources.accounts.forEach((item) => {
      list.push({
        id: item.accountID,
        ...item,
        balance: String(item.balance),
        type: 'OT_ACCOUNT',
        accountPlatform: item.platform,
        accountType: item.tariffName as AccountTariffName,
      });
    });

    sources.wallets.forEach(item => {
      // @ts-ignore
      list.push(item);
    })

    return list;
  }, [sources]);

  const destinationsList = React.useMemo(() => {
    const list: SourceItem[] = [];
    destinations.accounts.forEach((item) => {
      list.push({
        id: item.accountID,
        ...item,
        balance: String(item.balance),
        type: 'OT_ACCOUNT',
        accountPlatform: item.platform,
        accountType: item.tariffName as AccountTariffName,
      });
    });

    destinations.wallets.forEach(item => {
      // @ts-ignore
      list.push(item);
    })

    return list;
  }, [destinations]);
  
  const isDifferentCurrencies =
    sourceCurrency &&
    destinationCurrency &&
    sourceCurrency !== destinationCurrency;

  const style = useMemo(() => {
    if (isWidget) {
      if (isMobile) return '1fr';
      return isDifferentCurrencies ? '1fr 1fr' : '1fr';
    }

    return isMobile ? '1fr' : 'calc(50% - 10px) calc(50% - 10px)';
  }, [isMobile, isWidget, isDifferentCurrencies]);

  return (
    <Widget>
      {isTransferCompleted ? (
        <Styled.CompletedWrapper>
          <Title level={2}>Transfer completed</Title>
          <Styled.CompletedText>
            {pendingApprove 
              ? t('We are processing your transfer request. It may take up to 1 hour.')
              : t('Your transfer is completed')
            }
          </Styled.CompletedText>
          <Styled.CompletedButton
            name="reset-transfer"
            size="medium"
            onClick={() => resetTransfer()}
          >
            Okay
          </Styled.CompletedButton>
        </Styled.CompletedWrapper>
      ) : (
        <>
          <div className={`${(isWidget && !isMobile) ? '' : 'md:flex gap-[2rem]'}`}>
            <Styled.TransferSelect>
              <AccountSelect
                label="From"
                onSelect={setSource}
                inputMode='none'
                classes={'!m-0 !max-w-none'}
                data={sourcesList}
              />
              {
                // @ts-ignore
                source?.hasDepositBonus && (
                  <div className='flex items-center md:mt-[12px] mt-[8px]'>
                    <Text level={2} color='subtext' className='mr-[6px]'>
                      The bonus on this account will be deducted by 30% of the transfer amount
                    </Text>
                    <TipyTooltip
                      interactive
                      content={
                        <>
                          <Text level={3} className='max-w-[256px]' weight='medium'>
                            A 30% deposit bonus deduction applies on withdrawals and transfers. If the credit-to-balance ratio is violated, the bonus is lost completely.
                          </Text>
                          <Text
                            level={3}
                            className='!text-bronze.500 cursor-pointer mt-[4px]'
                            weight='medium'
                            withTranslate={false}
                          >
                            <a href='/deposit-bonus-terms/' target='_blank' rel="noreferrer">
                              {t('See all details')}
                            </a>
                          </Text>
                        </>
                      }
                    >
                      <div>
                        <Icon2 name='questionMarkCircle' size='small' className='text-gray.500 ml-[4px]'/>
                      </div>
                    </TipyTooltip>
                  </div>)
                  }
            </Styled.TransferSelect>
            <Styled.TransferSelect
              style={{ pointerEvents: !source ? 'none' : 'all' }}
            >
              <AccountSelect
                label="To"
                classes='!m-0 !max-w-none'
                inputMode='none'
                onSelect={setDestination}
                data={destinationsList}
              />
            </Styled.TransferSelect>
          </div>

          <Styled.TransferDetails
            style={{
              pointerEvents: !source || !destination ? 'none' : 'all',
              gridTemplateColumns: style,
            }}
          >
            <Input
              name="transfer-amount"
              label="Amount"
              placeholder="Amount"
              inputMode='numeric'
              type='number'
              prefix={source && sourceCurrency ? sourceCurrency : undefined}
              value={amount}
              onChange={onInputChange}
              errorMessage={errorAmountMessage}
              state={errors.amount ? 'error' : undefined}
            />

            {isDifferentCurrencies && (
              <Input
                name="transfer-exchange-amount"
                label="Exchange amount"
                placeholder="Amount"
                value={ errorAmountMessage 
                  ? '' 
                  : (calculatedAmount
                    ? `${destinationCurrency}${calculatedAmount}`
                    : ''
                  )
                }
                readOnly
              />
            )}
          </Styled.TransferDetails>
          {isDifferentCurrencies && calculatedAmount ? (
            <Styled.ExchangeRate>
              {t('Exchange rate')}: {sourceCurrency}1 = {destinationCurrency}
              {exchangeRate}
            </Styled.ExchangeRate>
          ) : null}
          <Styled.TransferFooter>
            <Button
              name="deposit-submit"
              prefix={ isLoading ? <Spinner2 size='small' /> : <Icon2 name="arrowsRightLeft" size="small" /> }
              size="medium"
              onClick={() => submit()}
              disabled={!calculatedAmount || isLoading || !!errors.amount}
            >
              Transfer
            </Button>
          </Styled.TransferFooter>
        </>
      )}
    </Widget>
  )
}
