import styled from 'styled-components/macro'

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

export const Item = styled.div<{ background: string }>`
  padding: 32px 24px;
  padding-inline-end: 72px;
  min-height: 200px;
  background-color: var(--color-gray-200);
  border-radius: 8px;
  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-size: auto 200px;
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'bottom left' : 'bottom right'};

  @media (max-width: 599px) {
    min-height: 160px;
    padding-top: 24px;
    padding-bottom: 40px;
    padding-inline-start: 20px;
    padding-inline-end: 80px;
  }
`
