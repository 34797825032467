import React from 'react';
import { useStoreMap } from 'effector-react';

import { Money, Spinner2, Text } from 'ui/atoms';
import { $amountField } from '../model';
import { useIsMobile } from 'libs/hooks/useIsMobile';

interface Props {
  receiveAmount?: string;
  receiveCurrency: string;
  bonusDep?: string;
  isBonusLoading: boolean;
}

export const BonusSum: React.FC<Props> = ({ receiveAmount = '', bonusDep, receiveCurrency, isBonusLoading }) => {
  const isMobile = useIsMobile();

  return (
    <div className='mt-[4px] md:flex justify-between items-center w-full md:h-[48px]'>
      <div>
        <Text level={isMobile ? 2 : 3}>
          Your deposit
        </Text>
        <Text withTranslate={false} weight='medium'>
          <Money currency={receiveCurrency} value={+receiveAmount}/>
        </Text>
      </div>
      <div className='mt-[10px] md:mt-0'>
        <Text level={isMobile ? 2 : 3} className='md:text-right'>
          Bonus
        </Text>
        <Text withTranslate={false} weight='medium' className='!text-eucalyptus.600'>
          {isBonusLoading 
            ? <Spinner2 size='medium' />
            : <Money currency={receiveCurrency} value={+(bonusDep || 0)} />
          }
        </Text>
      </div>
  </div>
  )
}
