import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';

import { useGetPartnerClients } from 'services/ib/get/useGetPartnerClients';
import { DateRange } from 'ui/molecules/datePickers';
import { DataPreloader, Icon2 } from 'ui/atoms';
import { Pagination } from 'ui/atoms/pagination/Pagination';
import { fromDateToIso } from 'libs/dateUtils';
import { TableModals } from './TableModals';

import { 
  $tableFilterStore, changeLoginEvent, changePeriodEvent, filterResetEvent,
  $tableSortStore,
  sortResetEvent, 
 } from './model';

import { ClientsTable } from './ClientTable';

import * as S from './styled';
import { DateRangeIcon } from 'ui/molecules/datePickers/DateRangeIcon';
import { useIsMobile } from 'libs/hooks/useIsMobile';

const INITIAL_PER_PAGE = 20;

export const ClientsReport = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(INITIAL_PER_PAGE);
  const isMobile = useIsMobile();

  // const sort = useStore($tableSortStore);
  const filter = useStore($tableFilterStore);
  const sort = useStore($tableSortStore);

  // Data fetch
  const { data, isLoading, isRefetching } = useGetPartnerClients({ page, perPage, filter, sort });

  const onChangeInput = (val: string) => {
    changeLoginEvent(val);
  }

  const onChangePage = (a: number) => {
    setPage(a);
  };

  useEffect(() => {
    return () => {
      filterResetEvent();
      sortResetEvent();
    }
  }, []);

  return (
    <>
      <div className='flex gap-[10px] lg:flex-row items-center'>
        <S.SearchInput
          placeholder="Search name"
          debounceCallbackFn={ onChangeInput }
          inputClasses='!h-[40px]'
          className='lg:!w-[271px] !h-[40px]'
          maxLength={20}
          suffix={<Icon2 name={'magnifyingGlassIcon'}/>}
          withDebaunce
        />

        {isMobile 
          ? <DateRangeIcon
            setTimeline={changePeriodEvent}
            // className='lg:ms-auto !h-[40px] lg:!w-[280px]'
            customMapperDates={fromDateToIso}
          />
          : <DateRange
            setTimeline={changePeriodEvent}
            className='lg:ms-auto !h-[40px] lg:!w-[280px]'
            inputClasses='!h-[40px] lg:!w-[280px]'
            customMapperDates={fromDateToIso}
            optionsListClasses='lg:!left-auto' 
          />
        }
      </div>

      <div id='table-sort-select' />
      <DataPreloader isLoading={isLoading || isRefetching} hasData={!!data}>
        <div className="mt-[2.4rem] w-full">
          <ClientsTable items={data?.items} totals={data?.totals} />

          {data && Number(data?.totalRecords) > INITIAL_PER_PAGE && (
            <Pagination
              pageSize={perPage}
              current={page}
              total={Number(data.totalRecords)}
              onChange={onChangePage}
              onChangePerPage={setPerPage}
              classNames="mt-[2.4rem]"
              showSizeChanger
            />
          )}
        </div>
      </DataPreloader>
      <TableModals />
    </>
  )
}
