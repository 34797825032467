import { useIsMobile } from 'libs/hooks/useIsMobile'
import { path } from 'libs/path'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from 'react-router-dom'
import { Button, Text, Title } from 'ui/atoms'

import prizesPng from './prizes.png'
import * as S from './styled'

export const ContestFooterModal = () => {
  const isMobile = useIsMobile()

  const nav = useNavigate()

  const onDeposit = () => {
    nav(path.money.depositTab())
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full flex justify-center">
        <LazyLoadImage width={128} src={prizesPng} />
      </div>

      <Title level={2} className="mt-[16px]">
        Prizes and conditions
      </Title>

      <div className="mt-[20px] gap-[16px] flex flex-wrap">
        <S.Conditions className="!md:w-[calc(50%-8px)]">
          {isMobile ? (
            <Text level={1} weight="medium">
              Only $20 to join
            </Text>
          ) : (
            <Title level={4}>Only $20 to join</Title>
          )}
          <Text className="mt-[8px]">
            Trade on your real account from March 3 to March 28
          </Text>
        </S.Conditions>

        <S.Conditions className="!md:w-[calc(50%-8px)]">
          {isMobile ? (
            <Text level={1} weight="medium">
              New leaderboard every day
            </Text>
          ) : (
            <Title level={4}>New leaderboard every day</Title>
          )}
          <Text className="mt-[8px]">
            Trade at least 0.1 lot to join the leaderboard
          </Text>
        </S.Conditions>

        <S.Conditions className="!w-full">
          {isMobile ? (
            <Text level={1} weight="medium">
              Daily results and prizes
            </Text>
          ) : (
            <Title level={4}>Daily results and prizes</Title>
          )}
          <Text className="mt-[8px]">
            Win withdrawable cash prizes. You can receive multiple prizes during
            the promo
          </Text>
        </S.Conditions>
      </div>

      <Title level={3} className="text-center mt-[24px]">
        Daily prizes
      </Title>

      <div className="flex md:flex-row flex-col gap-[16px] w-full mt-[20px]">
        <S.DailyPrizesCard className="md:w-1/2 bg-eucalyptus.100">
          <Title level={3}>All week</Title>
          <div className="flex md:flex-col flex-row gap-[8px] md:gap-0">
            <div className="mt-[24px] md:mt-[32px]">
              <Text level={isMobile ? 3 : 2}>Trader of the day *</Text>
              <Title level={3} className="mt-[4px]">
                $300
              </Title>
            </div>

            <div className='md:mt-[16px] mt-[24px]'>
              <Text level={isMobile ? 3 : 2}>Lucky winner *</Text>
              <Title level={3} className="mt-[4px]">
                $200
              </Title>
            </div>
          </div>
        </S.DailyPrizesCard>

        <S.DailyPrizesCard className="md:w-1/2 bg-[#009000]">
          <Title level={3} className="!text-white">
            Super Friday
          </Title>
          <div className="flex md:flex-col flex-row gap-[8px] md:gap-0">
            <div className="mt-[24px] md:mt-[32px]">
              <Text level={isMobile ? 3 : 2} className="!text-white">
                Trader of the day *
              </Text>
              <Title level={3} className="mt-[4px] !text-white">
                $1000
              </Title>
            </div>

            <div className='md:mt-[16px] mt-[24px]'>
              <Text className=' !text-white' level={isMobile ? 3 : 2}>Lucky winner *</Text>
              <Title level={3} className='mt-[4px] !text-white'>$500</Title>
            </div>
          </div>
        </S.DailyPrizesCard>
      </div>

      <Text level={3} color="subtext" className="md:pr-[32px] w-full mt-[16px]">
        *Trader of the day is defined by the maximum number of lots traded
        during the 24 hours.
      </Text>

      <Text level={3} color="subtext" className="md:pr-[32px] w-full mt-[8px]">
        *Lucky winner is defined randomly. The choice is based on the number of
        lucky tickets you received for every 0.1 lot traded during the 24 hours.
      </Text>

      <div className="flex gap-[16px] md:flex-row flex-col md:mt-[28px] mt-[24px] w-full">
        <Button
          design="tertiary-brand"
          className="md:w-1/2 w-full !h-[40px]"
          to={path.ramadan.terms()}
        >
          Terms and conditions
        </Button>
        <Button
          design="primary"
          className="md:w-1/2 w-full !h-[40px]"
          onClick={onDeposit}
        >
          Deposit
        </Button>
      </div>
    </div>
  )
}
