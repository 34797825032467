import { fromUnixTime, intervalToDuration } from 'date-fns'
import React from 'react'
import { PartnerStatuses, PartnerWidgetInfo } from 'services/ib/get/types'
import { Block, Icon2, Text, Title } from 'ui/atoms'
import { Progress } from 'ui/molecules'

import * as S from './styled'
import { ProgressIb } from '../molecules/progress'

interface Props {
  upgrading: PartnerWidgetInfo['upgrading']
  confirmStatus?: PartnerWidgetInfo['confirmStatus']
  currentStatus: PartnerStatuses
}

const timeNow = new Date()

const statusObj: Record<string, { label: string; next?: string }> = {
  PARTNER_STATUS_BRONZE: {
    label: 'Bronze',
    next: 'Silver',
  },
  PARTNER_STATUS_SILVER: {
    label: 'Silver',
    next: 'Gold',
  },
  PARTNER_STATUS_GOLD: {
    label: 'Gold',
    next: 'Royal',
  },
  PARTNER_STATUS_ROYAL: {
    label: 'Royal',
  },
}

export const ProgressBlock: React.FC<Props> = ({
  upgrading = {},
  confirmStatus,
  currentStatus,
}) => {
  // @ts-ignore
  const { clientsState, lotsState } = upgrading;

  const status = statusObj[currentStatus]

  const timeFn = (dateUnix: number) => {
    const dateDiff = intervalToDuration({
      start: timeNow,
      end: fromUnixTime(dateUnix),
    })

    return dateDiff.days
  }

  return (
    <>
      <S.StatusBlock className="mt-[2.4rem]">
        <div className="flex items-center">
          <Text>Upgrade</Text>
        </div>

        {currentStatus === 'PARTNER_STATUS_ROYAL' && (
          <Text className="!text-gray-600">
            Congratulations! You have reached the highest IB status, Royal.
          </Text>
        )}

        {currentStatus !== 'PARTNER_STATUS_ROYAL' && (clientsState && lotsState) && (
          <>
            <div className="w-full">
              <Text className="!text-gray.600">Active clients</Text>
              <ProgressIb
                progress={Math.floor(
                  (clientsState?.current / clientsState?.max) * 100,
                )}
                curentProgressLabel={`${clientsState?.current} / ${clientsState?.max}`}
                startLabel={status.label}
                endLabel={status.next || ''}
              />
            </div>

            <div className="w-full">
              <Text className="!text-gray.600">Lots</Text>
              <ProgressIb
                progress={Math.floor((lotsState?.current / lotsState?.max) * 100)}
                curentProgressLabel={`${lotsState?.current} / ${lotsState?.max}`}
                startLabel={status.label}
                endLabel={status.next || ''}
              />
            </div>
          </>
        )}
      </S.StatusBlock>

      {confirmStatus && (
        <S.StatusBlock className="mt-[2.4rem]">
          <div className="flex items-center w-full">
            <Text>Update</Text>
            <Icon2
              name="informationCircleOutline"
              className="ms-[4px] text-bronze.500"
              size="small"
            />
            <div className="ms-auto flex items-center">
              <Icon2
                name="clock"
                size="custom"
                className="!text-sunray.500 h-[2rem] w-[2rem]"
              />
              <Text level={2} className="mx-[4px] !text-gray.600 !inline">
                Days left
              </Text>
              <Text level={2} withTranslate={false} className="!font-medium">
                {timeFn(confirmStatus.statusReviewTs)}
              </Text>
            </div>
          </div>

          <div className="w-full">
            <Text className="!text-gray.600">Active clients</Text>
            <ProgressIb
              progress={Math.floor(
                (confirmStatus.clientsState.current /
                  confirmStatus.clientsState.max) *
                  100,
              )}
              curentProgressLabel={`${confirmStatus.clientsState.current} / ${confirmStatus.clientsState.max}`}
              startLabel={status.label}
              endLabel={`${status.label || ''} updated`}
            />
          </div>

          <div className="w-full">
            <Text className="!text-gray.600">Lots</Text>
            <ProgressIb
              progress={Math.floor(
                (confirmStatus.lotsState.current /
                  confirmStatus.lotsState.max) *
                  100,
              )}
              curentProgressLabel={`${confirmStatus.lotsState.current} / ${confirmStatus.lotsState.max}`}
              startLabel={status.label}
              endLabel={`${status.label || ''} updated`}
            />
          </div>
        </S.StatusBlock>
      )}
    </>
  )
}
