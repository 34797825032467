import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from 'services/axios';

const hdeBannerKit = async (name: string) => {
  await axios.patch('/contentkit/v1/banner/hide', { name })
}

export const useHideBannerKit = () => {
  return useMutation({
    mutationFn: hdeBannerKit,
  })
}
