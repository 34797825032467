import React, { useEffect, useMemo } from 'react'
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'ui/molecules';

import { path } from 'libs/path';
import { useGetCardsList } from 'services/payments/get/useGetCardsList';
import { TransactionStatus } from '../molecules/transactionStatus/TransactionStatus';

const errorStatuses = ['declined', 'cancelled', 'rejected'];
const pendingStatuses = ['authorized', 'initialized'];

export const ResultDepositPage = () => {
  let [searchParams] = useSearchParams();

  const { data } = useGetCardsList();

  const withdrawalPageMatch = useMatch(path.money.resultWithdrawal());
  
  const onClickVerify = () => navigate(path.money.cardsTab());
  const onClickTransfer = () => navigate(path.money.internalTransfer());

  const [t] = useTranslation();

  const navigate = useNavigate();

  const resultProps = () => {
    const obj = {
      amount: '',
      currency: searchParams.get('currency') as string,
      id: searchParams.get('id') as string,
      status: searchParams.get('status') as string,
      title: '',
      subTitle: '',
    }

    const amountQuery = searchParams.get('amount');
    const isResultAmount = searchParams.get('isResultAmount') as string;
    
    if (amountQuery) {
      let resultAmount: number | string;
     if ((obj.currency !== 'JPY' && obj.currency !== 'VND') && (isResultAmount !== 'true')) {
        // praxis умножает на 100
        resultAmount = (+amountQuery / 100).toFixed(2).toString();
      } else {
        resultAmount = amountQuery;
      }
     
      obj.amount = resultAmount;
    }

    if (obj.status === 'requested') obj.status = 'pending';
    if (errorStatuses.includes(obj.status)) obj.status = 'error';
    if (pendingStatuses.includes(obj.status)) obj.status = 'pending';

    if (obj.status === 'pending') {
      obj.title = t('Payment is in process')
      obj.subTitle = t('We are processing your transaction now – it may take some time. See the current status of your transaction in the Transaction History (My Accounts section).')
    }

    if (obj.status === 'success' || obj.status === 'approved') {
      obj.title = 'Success'
      obj.subTitle = 'Your payment is processed successfully. Start trading now!'
    }

    if (obj.status === 'error') {
      obj.title = 'Payment failed'
      obj.subTitle = 'The transaction has failed. Please, try again or contact our Customer Care for more details.'
    }
    
    return obj;
  }

  const items = [
    {
      label: 'Deposit',
      children: 
        <>
         <TransactionStatus
           {...resultProps() as any}
         />
        </>,
      onClick: () => navigate(path.money.entry())
    },
    {
      label: 'Withdrawal',
      children: 
        <>
          <TransactionStatus
            {...resultProps() as any}
          />
        </>,
      onClick: () => navigate(path.money.withdrawalTab())
    },
    {
      label: 'Transaction History',
      children: <></>,
      onClick: () => navigate(path.money.moneyHistory()),
    },
    {
      label: 'Internal transfer',
      children: <></>,
      onClick: onClickTransfer,
    }
  ];

  useEffect(() => {
    if (!searchParams.get('currency') && !searchParams.get('id') && !searchParams.get('status')) {
      navigate(path.money.entry());
    }
  }, []);

  const getArrayItems = useMemo(() => {
    const defaultItems = [...items];

    if (!!data?.bankCardInfo.length) {
      defaultItems.push({
        label: 'My cards',
        children: <></>,
        onClick: onClickVerify,
      });

      return defaultItems;
    }

    return defaultItems;
  }, [data, items]);

  return (
    <Tabs
      items={getArrayItems}
      defaultValue={withdrawalPageMatch ? 'Withdrawal' : 'Deposit'}
    />
  )
}