import { PartnerStatuses } from 'services/ib/get/types'
import styled, { css } from 'styled-components/macro'
import { Icon2, Text as TextComponent, Title as TitleComponent } from 'ui/atoms'

import smallBannerBackgroundRtl from '../../img/dashboardBannerBg-rtl.jpg'
import smallBannerBackground from '../../img/dashboardBannerBg.jpg'
import smallBannerBackgroundWeb from '../../img/dashboardBannerBg.webp'
import largeBannerBackground from '../../img/mainBannerBg.jpg'
import largeBannerBackgroundWeb from '../../img/mainBannerBg.webp'

export const PartnerBlock = styled.div`
  overflow: hidden;
  padding: 2rem;
  width: 100%;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 2px 4px -1px rgba(38, 33, 25, 0.06),
    0px 4px 6px -1px rgba(38, 33, 25, 0.1);
`

export const StepBlock = styled.div`
  border-radius: 8px;
  background: var(--color-gray-300);
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
`

export const StepTitle = styled(TitleComponent)`
  font-weight: 500;
  width: 64px;
  height: 64px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: linear-gradient(138deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
`

type BannerWidgetType = {
  isWidget?: boolean
}

export const PromoBanner = styled.div<BannerWidgetType>`
  position: relative;
  width: 100%;
  height: ${({ isWidget }) => (isWidget ? '296px' : '400px')};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 4px -1px rgba(38, 33, 25, 0.06),
    0px 4px 6px -1px rgba(38, 33, 25, 0.1);
  background-image: ${({ isWidget }) =>
    isWidget
      ? `url(${smallBannerBackground})`
      : `url(${largeBannerBackground})`};
  background-image: ${({ isWidget }) =>
    isWidget
      ? `image-set(url(${smallBannerBackgroundWeb}), url(${smallBannerBackground}))`
      : `image-set(url(${largeBannerBackgroundWeb}), url(${largeBannerBackground}))`};
  background-size: cover;
  background-position: center center;

  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      background-image: url(${smallBannerBackgroundRtl});
    `}

  @media (max-width: 540px) {
    height: 400px;
  }
`

export const JoinIBBannerWrapper = styled.div<BannerWidgetType>`
  position: relative;
  z-index: 1;

  ${(props) => {
    if (props.isWidget)
      return css`
        padding: 20px;
        padding-bottom: 16px;

        @media (max-width: 540px) {
          padding: 12px;
          padding-top: 16px;
        }
      `

    return css`
      padding-inline-start: 4rem;
      padding-top: 4rem;
    `
  }};
`

// Progress
interface ProgressWrapperType {
  status: PartnerStatuses
}
export const ProgressWrapper = styled.div<ProgressWrapperType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2rem;
  padding: 2rem;
  border-radius: 8px;
  background: ${(props) => {
    if (props.status === 'PARTNER_STATUS_BRONZE')
      return 'linear-gradient(119deg, #FDCD94 53.37%, #E2AF7B 105.06%), #262119'
    if (props.status === 'PARTNER_STATUS_SILVER')
      return 'linear-gradient(281deg, #B8B8B8 -18.18%, rgba(199, 196, 196, 0.34) 57.5%)'
    if (props.status === 'PARTNER_STATUS_GOLD')
      return 'linear-gradient(108deg, #F7F7E0 4.66%, #DBB454 101.24%)'
    if (props.status === 'PARTNER_STATUS_ROYAL')
      return 'radial-gradient(50% 50% at 50% 50%, #0D0B08 0%, #262119 100%)'
  }};
`
export const Icon = styled(Icon2)<{ status: PartnerStatuses }>`
  color: ${(props) => {
    if (props.status === 'PARTNER_STATUS_BRONZE') return '#8C6046'
    if (props.status === 'PARTNER_STATUS_SILVER') return 'var(--color.gray-600)'
    if (props.status === 'PARTNER_STATUS_GOLD') return 'var(--color-bronze-500)'
    if (props.status === 'PARTNER_STATUS_ROYAL')
      return 'var(--color-sunray-500)'
  }};
`
export const Text = styled(TextComponent)<{ status: PartnerStatuses }>`
  ${(props) => {
    if (props.status === 'PARTNER_STATUS_ROYAL')
      return css`
        color: white;
      `
  }};
`

export const Title = styled(TitleComponent)<{
  status: PartnerStatuses
  isMainColor?: boolean
}>`
  ${(props) => {
    if (props.isMainColor)
      return css`
        color: var(--color-sunray-500);
      `
    if (props.status === 'PARTNER_STATUS_ROYAL')
      return css`
        color: white;
      `
  }};
`
// end Progress
