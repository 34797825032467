import { useStore } from 'effector-react'
import { $isChatLoaded } from 'features/user/model'
import { useCallback, useEffect } from 'react'
import { ZendeskAPI } from 'react-zendesk'

import * as Styled from './styled'

export const MobileChat = () => {
  const isChatLoaded = useStore($isChatLoaded)

  useEffect(() => {
    if (!isChatLoaded) {
      return
    }

    try {
      // Скрываем лончер при загрузке
      ZendeskAPI('messenger', 'hide')

      // Обработчик закрытия: при закрытии чата снова скрываем лончер
      ZendeskAPI('messenger:on', 'close', () => {
        ZendeskAPI('messenger', 'hide')
      })
    } catch (error) {
      console.error('Error initializing Zendesk API:', error)
    }
  }, [isChatLoaded])

  const openChat = useCallback(() => {
    if (!isChatLoaded) {
      return
    }

    try {
      ZendeskAPI('messenger', 'show')
      ZendeskAPI('messenger', 'open')
    } catch (error) {
      console.error('Error opening Zendesk chat:', error)
    }
  }, [isChatLoaded])

  return <Styled.Button onClick={openChat} />
}
