import React, { useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { createEvent } from 'effector';

import { Modal } from 'ui/molecules/modal_draft';
import { Title, Text, Button } from 'ui/atoms';
import { useIsMobile } from 'libs/hooks/useIsMobile';
import { path } from 'libs/path';

import iconUrl from './ramadanRegistered.png';


export const showJoinedRamadanModalEvent = createEvent<boolean>();

export const JoinedModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isHaveDepRef = useRef<null | boolean>(null);

  const nav = useNavigate();

  const isMobile = useIsMobile();

  const onCloseModal = () => {
    setIsOpen(false);
    isHaveDepRef.current = null;
  }

  useEffect(() => {
    const watcher = showJoinedRamadanModalEvent.watch((isHaveDep) => {
      isHaveDepRef.current = isHaveDep;
      setIsOpen(true)
    });
  
    return () => {
      watcher?.();
    }
  }, []);

  const text = isHaveDepRef.current ? 'Trade, join the leaderboard, and win today’s challenge!' : 'Make any deposit and trade to join the leaderboard.';
  const buttonLabel = isHaveDepRef?.current ? 'Go' : 'Deposit to join';

  const onClick = () => {
    if (isHaveDepRef.current) {
      onCloseModal();
      return;
    } 

    nav(path.money.depositTab());
  }
  
  return (
    <Modal
      title=""
      open={isOpen}
      footer={false}
      isScrollContent
      onClose={onCloseModal}
    >
      <div className='flex justify-center'>
        <LazyLoadImage width={128} src={iconUrl} />
      </div>
      <Title level={isMobile ? 3 : 2} className='mt-[16px] text-center'>
        Welcome to the Ramadan Challenge
      </Title>
      <Text className='mt-[16px] text-center' level={1} color='subtext'>
        {`${text}`}
      </Text>
      <Button className='w-full md:mt-[28px] mt-[32px]' onClick={onClick}>
        {buttonLabel}
      </Button>
    </Modal>
  )
}
