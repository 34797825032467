import { path } from 'libs/path';
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'ui/molecules/modal_draft';

import iconSvg from './assets/regBonusIcon.png';
import { Text, Title } from 'ui/atoms';
import { CountdownTimer } from 'ui/atoms/timer';
import { useTranslation } from 'react-i18next';

interface Props {
  params: {
    activeDurationHours: string;
    extraDepositPercents: string;
    finishTs: number;
  };
  name: string;
  onClose: () => void;
}

export const RegBonusPopup: React.FC<Props> = ({ onClose, params, name}) => {
  const nav = useNavigate();
  const onOk = () => {
    nav(path.money.depositTab());
    onClose?.();
  }

  const [t] = useTranslation();

  return (
    <Modal
      cancelLabel='Later'
      okLabel='Deposit now'
      onCancel={onClose}
      onOk={onOk}
      open={true}
      onClose={onClose}
      footerClasses='!mt-[28px]'
      okButtonProps={{
        disabled: false,
      }}
    >
      <div className='w-full flex flex-col items-center'>
        <LazyLoadImage width={'128px'} src={iconSvg} />

        <Title level={2} className='mt-[16px] text-center' withTranslate={false}>
          {t('to your first deposit', {value: '75'})}
        </Title>
        <Text className={'mt-[16px]'} level={1} color='subtext'>
          Get the biggest bonus for your first deposit. You have only 72 hours to use it, seize the chance!
        </Text>

        <div className='text-[48px]/[80px] font-medium mt-[16px]'>
          <CountdownTimer targetTimestamp={+params?.finishTs} />
        </div>
      </div>
    </Modal>
  )
}
