import { useIsMobile } from 'libs/hooks/useIsMobile';
import { path } from 'libs/path';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BannerHeadKit } from 'services/banners/get/useGetHeadBanner';
import { useHideHeadBanner } from 'services/banners/mutations/useHideHeadBanner';
import { Button, Icon2, Text } from 'ui/atoms';

interface Props {
  banner: BannerHeadKit;
  onRefetchHeaderBanner?: () => void
}

export const Individual: React.FC<Props> = ({ banner, onRefetchHeaderBanner }) => {
  const { mutateAsync } = useHideHeadBanner();

  const [t] = useTranslation();

  const isMobile = useIsMobile();

  const navigate = useNavigate();
  const onGoToDeposit = () => {
    navigate(path.money.depositTab());
  }

  const onHideBanner = async () => {
    try {
      await mutateAsync(banner.name);
    } catch (error) {
      
    }
  }

  return (
    <div className={`md:h-[56px] w-full fixed flex justify-center items-center top-0 z-40 bg-eucalyptus.300 h-[37px]`}>
      <Text level={isMobile ? 2 : 1} color='main' weight='medium'>
        {isMobile 
          ? `Your next deposit will be with a +${banner.params.extraDepositPercents}% bonus!` 
          // : t(`Did you know that you have +${banner.params.extraDepositPercents}% on your next deposit?`)}
          : t(`Did you know that you have on your next deposit?`, { value: banner.params.extraDepositPercents})}
      </Text>
      {!isMobile && 
        <Button type='button' design='secondary' className='!h-[42px] min-w-[130px] ml-[16px]' onClick={onGoToDeposit}>
          Deposit now
        </Button>
      }
      <div className='h-full cursor-pointer absolute right-0 top-0 flex items-center md:mr-[18px] mr-[12px]' onClick={onHideBanner}>
        <Icon2 name='xMarkIcon' size={isMobile ? 'small' : 'medium' } />
      </div>
    </div>
  )
}
