import { path } from 'libs/path'
import { useTranslation } from 'react-i18next'
import { useGetContestInfo } from 'services/ramadan'
import { Button } from 'ui/atoms'
import { Breadcrumbs } from 'ui/molecules'

import { CharityProgress } from './molecules/charityProgress'
import { ContestFooter } from './molecules/contestFooter'
import { ContestHeader } from './molecules/contestHeader'
import { HallOfFame } from './molecules/hallOfFame'
import { Organization } from './molecules/hallOfFame/OrganizationBlock'
import { ColorMessage, SimpleMessage } from './molecules/messageBlock'
import * as Styled from './styled'
import { useActionButton } from './utils/useActionButton'

const HelpBlock = ({ isJoined }: { isJoined: boolean }) => {
  const [t] = useTranslation()

  const { buttonLabel, onClickButton, isLoading } = useActionButton({
    isJoined,
    isMadeDeposit: false,
  })

  return (
    <ColorMessage
      title={t('Your chance to win and help today')}
      text={t(
        'Join the Ramadan Challenge now, deposit $20 in a single transaction, and seize the day! Your win is a win for our charitable organizations.',
      )}
    >
      <Button
        size="medium"
        design="secondary"
        className="min-w-[200px] mobile:w-full"
        onClick={onClickButton}
        loading={isLoading}
        disabled={isLoading}
      >
        {buttonLabel}
      </Button>
    </ColorMessage>
  )
}

export const RamadanResults = () => {
  const [t] = useTranslation()

  const { data } = useGetContestInfo({ isHallOfFame: true })

  return (
    <>
      <Breadcrumbs
        className="mb-[2rem]"
        customPaths={[
          { name: 'Ramadan Challenge', pathName: path.ramadan.entry() },
          {
            name: 'Hall of Fame',
            pathName: path.ramadan.results(),
          },
        ]}
      />
      {data ? (
        <Styled.ContentContainer>
          <ContestHeader data={data} />

          <Styled.ContestBody className="flex flex-col lg:gap-[56px] gap-[40px]">
            {data.charity && (
              <CharityProgress
                startDate={data.promo.promoStartsTs}
                endDate={data.promo.promoEndsTs}
                progressBarPercentage={data.charity.progressBarPercentage}
                collectedAmount={data.charity.collectedAmount}
                isEnded={false}
                hideButton
              />
            )}

            <Organization />

            {data.participant.status === 'UNREGISTERED' && (
              <HelpBlock isJoined={data.participant.isJoined} />
            )}

            {data.winners.length > 0 ? (
              <HallOfFame
                winners={data.winners}
                daysCount={data.promo.daysCount}
              />
            ) : (
              <SimpleMessage title={t('Cheer the leaders')}>
                {t(
                  'The first winners of the Ramadan Challenge will be revealed here every day. Strive for the top or test your luck — you could be next!',
                )}
              </SimpleMessage>
            )}

            {data.promo.status === 'ACTIVE' && <ContestFooter />}
          </Styled.ContestBody>
        </Styled.ContentContainer>
      ) : (
        'loading...'
      )}
    </>
  )
}
