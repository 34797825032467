import { toPath } from 'features/router'
import { path } from 'libs'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Button, Link, List, Text, Title } from 'ui/atoms'

import verified from './images/verified.svg'

export const VerifiedPage = () => {
  const [t] = useTranslation()

  return (
    <Container>
      <VerifiedLogo />
      <Content>
        <Title level={3}>Your profile is verified</Title>
        <Text level={1}>Now you have full access to Headway:</Text>
        <List
          items={[
            <Trans
              i18nKey="Deposit with a credit or debit card"
              components={[<Link to={path.deposit.entry()} design="sunray" />]}
            />,
            <>{t('Work on a secured account with confidence')}</>,
            <>{t('Withdraw profits with all available payment methods')}</>,
          ]}
        />
        <Text level={1}>
          We are happy to have you with us. Reach your trading goals with
          Headway!
        </Text>
      </Content>
      <Footer>
        {/* <Back to={path.dashboard.verification.entry()} /> */}
        <Button name="to-profile" onClick={() => toPath(path.profile.entry())}>
          Profile
        </Button>
      </Footer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const VerifiedLogo = styled.div`
  width: 200px;
  height: 200px;
  background-image: url(${verified});
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 32px;
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  button {
    margin: 0;
    width: 220px;
  }
`
