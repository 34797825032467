import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Title, Text } from 'ui/atoms';
import charity1 from '../charityInfo/charity1.png';
import charity2 from '../charityInfo/charity2.png';
import charity3 from '../charityInfo/charity3.png';

import { PartnerImgWrapp as PartnerImgWrappStyled } from '../charityInfo/styled';
import * as S from './styled';



export const Organization = () => {
  return (
    <S.OrganizationBlock>
      <Title level={2} className='text-center'>
        These organizations wait for our help
      </Title>

      <div className='md:mt-[20px] mt-[24px] flex lg:flex-row flex-col lg:gap-[20px] gap-[16px]'>
        <S.OrganizationCard>
          <PartnerImgWrappStyled className='!w-full'>
            <LazyLoadImage src={charity1} width={'40px'} height={'40px'}/>
          </PartnerImgWrappStyled>
          <Title level={4} className='text-center mt-[8px]'>Nour Fe Elbuyot, Egypt</Title>
          <Text level={2} className='mt-[16px]'>
            Nour Fe Elbuyot is an Egyptian charity supporting the needy by providing food, clothing, financial aid, and social services, especially during Ramadan. It also focuses on child welfare and improving the quality of life for underprivileged families.
          </Text>
        </S.OrganizationCard>

        <S.OrganizationCard>
          <PartnerImgWrappStyled className='!w-full'>
            <LazyLoadImage src={charity2} width={'40px'} height={'40px'}/>
          </PartnerImgWrappStyled>
          <Title level={4} className='text-center mt-[8px]'>Yayasan Gema Insan Amanah, Indonesia</Title>
          <Text level={2} className='mt-[16px]'>
            The Gema Insan Amanah Foundation provides education, healthcare, and aid for orphans, the poor, and disaster victims. It supports economic empowerment, mosque renovations, and community well-being through social, humanitarian, and religious programs.
          </Text>
        </S.OrganizationCard>

        <S.OrganizationCard>
          <PartnerImgWrappStyled className='!w-full'>
            <LazyLoadImage src={charity3} width={'40px'} height={'40px'}/>
          </PartnerImgWrappStyled>
          <Title level={4} className='text-center mt-[8px]'>Dignity for Children Foundation, Malaysia</Title>
          <Text level={2} className='mt-[16px]'>
            Dignity for Children Foundation is a non-governmental organization that provides holistic care and education for urban poor children in Kuala Lumpur, Malaysia.
          </Text>
        </S.OrganizationCard>
      </div>

    </S.OrganizationBlock>
  )
}
