import Cookies from 'js-cookie';
import { useEffect } from 'react'

import { setCopyingStrategy } from 'pages/copytrade/model';
import { isEmpty } from 'libs/isEmpty';
// Запись всех кук по разным сторам
// Инициальный хук, вызывается один раз в самом начале работы приложения
export const useCookieToStore = () => {

  const getCookiesObj = (cookieName: string) => {
    try {
      const cookieData = Cookies.get(cookieName);
      if (!cookieData) return;
  
      const parsedData = JSON.parse(cookieData);
      if (typeof parsedData !== 'object' || isEmpty(parsedData)) return;

      return parsedData;
    } catch (error) {
      
    }
  }

  useEffect(() => {
    try {
      const data = getCookiesObj('copyingStrategy');
      if (!data) return;
  
      setCopyingStrategy(data);
    } catch (error) {
      
    }
  }, []);
  
  return null;
}
