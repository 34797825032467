import React from 'react';

import styled, { keyframes } from 'styled-components/macro';

interface Props {
  width: 'full' | string;
  height: 'full' | string;
  className?: string;
}

export const Skeleton: React.FC<Props> = ({ width, height, className = '' }) => {
  return (
    <SkeletonS className={className} width={width} height={height} />
  )
}

const skeletonSlide = keyframes`
  from {
    left: -100px;
  }
  to {
    left: calc(100% + 100px);
  }
`

const SkeletonS = styled.div<{ height: string, width: 'full' | string}>`
  width: ${(p) => p.width === 'full' ? '100%' : p.width};
  height: ${(p) => p.height === 'full' ? '100%' : p.height};
  background-color: #eee;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 100%;
    box-shadow: 0 0 80px 20px #fff;
    animation: ${skeletonSlide} 0.6s infinite ease-in-out;
  }
`;