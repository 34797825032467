import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'


export const GET_RAMADAN_CONTEST_INFO = 'ramadan-contest-info';

interface TicketsResp {
  dayID: number;
  ticketIDs: number[];
}

const getTickets = async (dayID: number) => {
  const res = await axios.get<TicketsResp>('/ramadan2025/v1/tickets', {
    params: {
      dayID,
    },
  })

  return res.data
}

interface Options {
  dayID: number;
  enabled?: boolean;
}

export const useGetTickets = ({ dayID, enabled = true }: Options) => {
  return useQuery({
    queryKey: [GET_RAMADAN_CONTEST_INFO, dayID],
    staleTime: 3000,
    cacheTime: 5000,
    enabled,
    queryFn: () => getTickets(dayID),
  })
}
