import React, { useEffect, useRef } from 'react';

import { GlobalAlertStore } from 'ui/templates/generic/model';
import { Alerts } from 'ui/molecules';
import { Text } from 'ui/atoms';

interface Props {
  alert: GlobalAlertStore;
  onCloseAlert: () => void;
}

const TIMER_10_SEC = 10000;

export const Alert: React.FC<Props> = ({ alert, onCloseAlert }) => {
  const timerIdRef = useRef<NodeJS.Timeout | undefined | null>(null);


  useEffect(() => {
    if (!alert || !!timerIdRef?.current) return;
    timerIdRef.current = setTimeout(() => {
      onCloseAlert();
    }, TIMER_10_SEC)
  
    return () => {
      if (timerIdRef) {
        clearTimeout(timerIdRef as any);
      }
      onCloseAlert();
    }
  }, []);
  

  return (
    <div className="mb-[20px] w-full">
      <Alerts
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      >
        <Text>
          {alert.text}
        </Text>
    </Alerts>
  </div>
  )
}
