import { useWindowSize } from "usehooks-ts";



export const useIsMobile = (size: number = 600): boolean => {
  const { width } = useWindowSize();

  const isMobileMode = width < size;

  return isMobileMode;
}