import { useIsMobile } from 'libs/hooks/useIsMobile'
import { useActionButton } from 'pages/ramadan/utils/useActionButton'
import React, { useMemo } from 'react'
import { ParticipantStatus, PromoStatus } from 'services/ramadan/types'
import { Button, Icon2, Text, Title } from 'ui/atoms'

import * as S from './styled'

interface Props {
  isJoined?: boolean
  isMadeDeposit?: boolean
  status?: ParticipantStatus
  promoStatus: PromoStatus
}

export const JoinBlock: React.FC<Props> = ({
  isJoined = false,
  isMadeDeposit = false,
  status,
  promoStatus,
}) => {
  const isMobile = useIsMobile()

  const { onClickButton, isLoading, buttonLabel } = useActionButton({
    isJoined,
    isMadeDeposit,
  })

  return (
    <div className="xl:w-[880px] w-full">
      <div className="w-full flex flex-col items-center">
        <Title level={2}>How to join</Title>
        <Text level={1} className="mt-[16px]">
          It takes only two steps to enter the contest!
        </Text>
      </div>

      <div className="w-full mt-[24px] flex-col md:flex-row flex justify-center gap-[24px]">
        <div className="md:w-[320px] w-full md:py-[16px] flex items-center md:flex-col md:gap-[16px] gap-[12px]">
          <S.CheckCircleWrapper className="" isChecked={isMadeDeposit}>
            {isMadeDeposit && <Icon2 name="check" className="!text-white" />}
          </S.CheckCircleWrapper>

          <div>
            <Title level={4} className="md:text-center">
              Make any deposit
            </Title>
            <Text level={isMobile ? 3 : 2} className="mt-[8px] md:text-center">
              All deposits from February 28 count in
            </Text>
          </div>
        </div>

        <div className="md:w-[320px] w-full md:py-[16px] flex items-center md:flex-col md:gap-[16px] gap-[12px]">
          <S.CheckCircleWrapper className="" isChecked={isJoined}>
            {isJoined && <Icon2 name="check" className="!text-white" />}
          </S.CheckCircleWrapper>
          <div>
            <Title level={4} className="md:text-center">
              Click the “Join now” button
            </Title>
            <Text level={isMobile ? 3 : 2} className="mt-[8px] md:text-center">
              Any time between February 28 and March 28
            </Text>
          </div>
        </div>
      </div>

      {!isMobile && status === 'UNREGISTERED' && (
        <div className="w-full flex justify-center mt-[24px]">
          <Button
            className="w-[240px]"
            onClick={onClickButton}
            loading={isLoading}
          >
            {buttonLabel}
          </Button>
        </div>
      )}
    </div>
  )
}
