import React, { useMemo, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import { useDebounce } from 'usehooks-ts';

import { OrderForm } from 'services/giftshop';
import { A3codes } from 'libs/A3codes';
import { Select } from 'ui/atoms'

type Props = {
  control: Control<OrderForm, any>;
  defaultCountry?: string;
  error?: {
    message?: string;
  }
}

export const CountrySelect: React.FC<Props> = ({ control, error, defaultCountry = '' }) => {
  const [input, setInput] = useState(defaultCountry);

  const inputDebounce = useDebounce(input, 200);

  const countryList = useMemo(() => {
    return A3codes.map((country) => ({
      value: country.code,
      label: country.country,
    }))
  }, []);

  const filteredOpt = useMemo(() => {
    if (!input) return countryList;

    return countryList.filter(item => item.label.toLowerCase().includes(inputDebounce.toLowerCase()));
  }, [inputDebounce]);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  }

  const onSelect = (changeFn: any) => (val: string) => {
    changeFn(val);
    setInput('');
  };

  return (
    <>
     <Controller
        name="country"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <Select
            {...rest}
            name="country"
            errorMessage={error?.message}
            className="!w-[calc(50%-10px)]"
            value={input}
            onChangeInput={onChangeInput}
            placeholder="Country"
            onSelect={onSelect(rest.onChange)}
            isHaveSearch
            maxHeight={410}
            autoComplete="off"
            options={filteredOpt}
          />
        )}
      />
    </>
  )
}
