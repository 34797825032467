import type { ParticipantStatus, PromoStatus } from 'services/ramadan/types'
import type { Design } from 'ui/atoms'

export const contestStatuses: Record<
  PromoStatus,
  { name: string; tagDesign: Design }
> = {
  NEW: { name: 'Unknown', tagDesign: 'opal' }, // todo: уточнить
  REGISTRATION: { name: 'Registration', tagDesign: 'eucalyptus' },
  ACTIVE: { name: 'Active', tagDesign: 'azure' },
  ENDED: { name: 'Ended', tagDesign: 'gray' },
}

export const participantStatuses: Record<
  ParticipantStatus,
  { name: string; tagDesign: Design }
> = {
  ACTIVE: { name: 'Joined', tagDesign: 'sunray' },
  DISQUALIFIED: { name: 'Disqualified', tagDesign: 'opal' },
  UNREGISTERED: { name: '', tagDesign: 'opal' },
}