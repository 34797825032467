import styled from 'styled-components/macro'


export const BgWrapper = styled.div<{url: string}>`
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 8px;
  padding: 16px 24px;
  display: flex;
  gap: 20px;

  // MOB
  @media (max-width: 599px) {
   margin-top: 4px;
   padding: 30px 20px 21px 20px;
   flex-direction: column;
  }
`;

export const PartnerImgWrapp = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  padding: 24px;
  gap: 24px;

 // Desc
  @media (min-width: 768px) {
    width: 540px;
    gap: 32px;
  }

`;

export const VideoWrapper = styled.div`
  margin-top: 8px;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  @media (max-width: 599px) {
    margin-top: 16px;
    padding-bottom: 130%;
  }
`

export const VideoHint = styled.div`
  margin-top: 28px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  text-align: center;

  @media (max-width: 599px) {
    margin-top: 16px;
  }
`
