import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

export const GET_ZENDESK_JWT = ['zendesk-jwt']

const getJWT = async () => {
  const res = await axios.get<{ jwtToken: string }>('/zendesk/v1/jwt-token')

  return res.data.jwtToken
}

export const useGetJWT = () => {
  return useQuery({
    queryKey: GET_ZENDESK_JWT,
    queryFn: getJWT,
    enabled: false,
  })
}
