import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

export const GET_HEAD_BANNERS_KIT_KEY = 'GET_HEAD_BANNERS_KIT_KEY';


export interface BannerHeadKit {
  name: string;
  params: {
    extraDepositPercents?: string;
  }
}

const getHeadBanner = async (state?: string) => {
  const res = await axios.get<BannerHeadKit>('/contentkit/v1/header-banner', { 
    params: { 
      state,
    }
  })

  return res.data
}

interface Options {
  state?: 'STATE_DEPOSIT_BONUS_INDIVIDUAL' | 'STATE_DEPOSIT_BONUS_REG_BONUS' | 'STATE_DEPOSIT_BONUS_REGULAR';
  enabled?: boolean;
}

export const useGetHeadBanner = ({enabled = true, state}: Options) => {
  return useQuery({
    queryKey: [GET_HEAD_BANNERS_KIT_KEY],
    queryFn: () => getHeadBanner(state),
    enabled,
  })
}
