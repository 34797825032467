import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePostUnlinkSocial } from 'services/user/mutations/usePostUnlinkSocial';
import { useGetUserSocials } from 'services/user/get/useGetUserSocials';
import { SocialNameType } from 'services/user/get/type';
import { Text, Icon } from 'ui/atoms';
import { Modal } from 'ui/molecules/modal_draft'

import { SocialPreloader } from './SocialPreloader';
import { GoogleLink } from './GoogleLink';
import { FacebookLogin } from './FacebookLink';

import * as S from './styles';


const socialsTitleMap = {
  'apple': 'Apple',
  'google': 'Google',
  'facebook': 'Facebook',
}

export const Social: React.FC = () => {
  const { data, isLoading } = useGetUserSocials();
  const [socialModal, seSocialModal] = useState<SocialNameType | null>(null);

  const { mutateAsync: unlinkSocialMutate } = usePostUnlinkSocial();

  const [t] = useTranslation();

  const unLinkSocial = async(social: SocialNameType) => {
    try {
      await unlinkSocialMutate(social);
      seSocialModal(null);
    } catch (error) {
      // TODO добавить обработчик ошибок
    }
  }

  const unLinkModal = (name: SocialNameType) => {
    seSocialModal(name);
  }

  const getSocialLinkComponent = (socialName: SocialNameType) => {
    if (socialName === 'google') return <GoogleLink />;
    if (socialName === 'facebook') return <FacebookLogin />;

    return (
      <Text
        className='cursor-pointer !text-sunray.500'
      >
        Link the account
      </Text>
    )
  }

  if (isLoading && !data) return <SocialPreloader />;
  
  return (
    <>
      {data?.socials.map((social, index) => (
        <S.InformationBlockItem key={`${social.name}_${index}`}>
         <div className='flex'>
            <Icon name={social.name} size="medium" />
            <Text className='ms-[0.8rem]'>{socialsTitleMap[social.name]}</Text>
          </div>

          {social.isLinked 
           ? <Text
                className='cursor-pointer !text-sunray.500'
                onClick={() => unLinkModal(social.name)}
              >
                Unlink the account
              </Text>
           : getSocialLinkComponent(social.name)
          }
        </S.InformationBlockItem>
      ))}

      <Modal
        open={!!socialModal}
        okLabel="Yes"
        onClose={() => seSocialModal(null)}
        onOk={() => unLinkSocial(socialModal!)}
        title="Unlink the account"
        cancelLabel="Cancel"
      >
        <Text className="text-center !text-gray.600" withTranslate={false}>
          {t('Are you sure you want to unlink your account?', { name: socialsTitleMap[socialModal!] || ''})}
        </Text>
      </Modal>
    </>
  )
}
