import React from 'react';
import styled from 'styled-components/macro';

import { Text } from 'ui/atoms';

interface Props {
  items: (string | number)[];
  isMore15Ticket: boolean;
}

const ListWrapper = styled.div`
  &::-webkit-scrollbar {
    /* display: none; */
  }
  overflow-y: scroll;
  display: grid;
  gap: 8px;
  
  /* grid-template-columns: repeat(auto-fit, minmax(165px, 1fr)); */

  @media (min-width: 1024px) {
    max-height: 472px;
  }
`

export const TicketsList: React.FC<Props> = ({ items = [], isMore15Ticket }) => {
  return (
    <ListWrapper
      className={`
        ${!isMore15Ticket ? 'grid-cols-1 justify-center' : 'grid-cols-2 sm:grid-cols-3'}
      `}
    >
      {items.map((item, index) => (
        <Text level={1} key={`${index}_${item}`} className="text-center">
          {item}
        </Text>
      ))}
    </ListWrapper>
  );
};
