import React, { useEffect, PropsWithChildren } from 'react';
import { useUnit } from 'effector-react';

import { $userProfile } from './model';


export const PartnerContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const user = useUnit($userProfile);

  const params = new URLSearchParams(document.location.search);
  const partnerId = params.get("hwp");
  const regPromoCode = params.get("regPromoCode");

  useEffect(() => {
    if (!partnerId || !user) {
      return;
    }

    localStorage.setItem('partnerId', partnerId);
  }, []);

  useEffect(() => {
    if (!regPromoCode || !user) {
      return;
    }

    localStorage.setItem('regPromoCode', regPromoCode);
  }, []);

  return (
    <>
     {children}
    </>
  );
}


