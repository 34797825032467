import { useWindowSize } from '@uidotdev/usehooks'
import { useTranslation } from 'react-i18next'
import { Title } from 'ui/atoms'

import rtlMobileBackground_1 from './background-1-mobile-rtl.webp'
import mobileBackground_1 from './background-1-mobile.webp'
import rtlBackgound_1 from './background-1-rtl.webp'
import backgound_1 from './background-1.webp'
import rtlMobileBackground_2 from './background-2-mobile-rtl.webp'
import mobileBackground_2 from './background-2-mobile.webp'
import rtlBackgound_2 from './background-2-rtl.webp'
import backgound_2 from './background-2.webp'
import rtlMobileBackground_3 from './background-3-mobile-rtl.webp'
import mobileBackground_3 from './background-3-mobile.webp'
import rtlBackgound_3 from './background-3-rtl.webp'
import backgound_3 from './background-3.webp'
import * as Styled from './styled'

const items = [
  {
    title: 'Trade every day',
    text: 'Use any of your real trading accounts from February 28 to March 28',
    background: backgound_1,
    mobileBackground: mobileBackground_1,
    rtlBackground: rtlBackgound_1,
    rtlMobileBackground: rtlMobileBackground_1,
  },
  {
    title: 'New leaderboard every day',
    text: 'Trade at least 0.1 lot to join the leaderboard',
    background: backgound_2,
    mobileBackground: mobileBackground_2,
    rtlBackground: rtlBackgound_2,
    rtlMobileBackground: rtlMobileBackground_2,
  },
  {
    title: 'Win every day',
    text: 'Win withdrawable cash prizes. You can receive multiple prizes during the promo',
    background: backgound_3,
    mobileBackground: mobileBackground_3,
    rtlBackground: rtlBackgound_3,
    rtlMobileBackground: rtlMobileBackground_3,
  },
]

export const OpportunitiesBlock = () => {
  const [t, i18n] = useTranslation()

  const { width } = useWindowSize()
  const isMobile = width && width < 600

  const isRtl = i18n.dir() === 'rtl'

  return (
    <>
      <div className="mb-[8px] text-body.medium leading-body.medium text-gray.600 font-medium text-center">
        {t('Ramadan Challenge')}
      </div>
      <Title level={1} className="mb-[24px] px-[24px] text-center">
        Every day is a new opportunity
      </Title>
      <Styled.List>
        {items.map((item, index) => (
          <Styled.Item
            key={index}
            background={
              isMobile
                ? isRtl
                  ? item.rtlMobileBackground
                  : item.mobileBackground
                : isRtl
                ? item.rtlBackground
                : item.background
            }
          >
            <Title level={4} className="mb-[8px]">
              {item.title}
            </Title>
            <div className="text-body.medium leading-body.medium">
              {t(item.text)}
            </div>
          </Styled.Item>
        ))}
      </Styled.List>
    </>
  )
}
