
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';

import { useIsMobile } from 'libs/hooks/useIsMobile';
import { Button, Checkbox, Select } from 'ui/atoms';
import { AccountLine, WalletLine } from 'ui/molecules';

import {
  $moneyHistoryFilter,
  dropFilterMoneyHistoryEvent,
  setAccountIdsEvent,
  setWalletIdsEvent,
  setExchangerWalletIdsEvent,
} from 'pages/money/transactionsNew/model/moneyHistoryModel';

import * as Styled from './styled';
import { useWindowSize } from 'usehooks-ts';

interface Props {
  accounts: any[];
  wallets: any[];
  exchangerWallets?: any[];
}

export const AccountsSelect: React.FC<Props> = ({ accounts, wallets, exchangerWallets = [] }) => {
  const [t] = useTranslation();

  const {walletIDs, accountIDs, exchangerWalletIDs} = useUnit($moneyHistoryFilter);

  const isMobile = useIsMobile();
  const { width } = useWindowSize();
  const isRtl = document.dir == "rtl";

  // const { data: accounts } = useGetAccounts({});
  // const { data: wallets } = useGetWallets({});
  
  const [accountsSelectValue, setAccountsSelectValue] = React.useState('');
  const [selectedAccounts, setSelectedAccounts] = React.useState<string[]>([]);
  const [selectedWallets, setSelectedWallets] = React.useState<string[]>([]);
  const [selectedExchangerWallets, setSelectedExchangerWallets] = React.useState<string[]>([]);

  const changeAccounts = () => {
    setAccountIdsEvent(selectedAccounts)
    setWalletIdsEvent(selectedWallets)
    setExchangerWalletIdsEvent(selectedExchangerWallets);

    setAccountsSelectValue('filled')
  }

  const resetAccounts = () => {
    setSelectedAccounts([])
    setSelectedWallets([]);
    setSelectedExchangerWallets([]);
    setAccountsSelectValue('');

    // Store reset
    if (!!accountIDs?.length) setAccountIdsEvent([]);
    if (!!walletIDs?.length) setWalletIdsEvent([]);
    if (!!exchangerWalletIDs?.length) setExchangerWalletIdsEvent([]);
  }

  const onCheckAllWallets = (checked: boolean) => {
    if (checked) {
      setSelectedWallets(
        wallets.map((wallet) => wallet.walletID),
      )

      setSelectedExchangerWallets(
        exchangerWallets.map((wallet) => wallet.walletID),
      )
      return;
    }

    setSelectedWallets([]);
    setSelectedExchangerWallets([]);
  };

  const isAllWalletsCheck = (selectedWallets.length + selectedExchangerWallets.length) === wallets.length + exchangerWallets?.length;

  useEffect(() => {
    const eventWatcher = dropFilterMoneyHistoryEvent.watch(() => {
      setSelectedAccounts([]);
      setSelectedWallets([])
      setSelectedExchangerWallets([]);
    });
  
    return () => {
      eventWatcher.unsubscribe();
    }
  }, [])

  return (
    <>
      <Select
        value={accountsSelectValue}
        placeholder="Account / Wallet"
        autoComplete="off"
        optionsWidth={isMobile ? undefined : 343}
        maxHeight={2000}
        listPosition={(width <= 1200 && width >= 850 && !isRtl) ? 'left' : 'right' }
        readOnly
        isHaveSearch={false}
        options={[
          {
            value: 'filled',
            label: `Account / Wallet (${
              selectedAccounts.length + (selectedWallets.length + selectedExchangerWallets.length)
            })`,
            hidden: true,
          },
        ]}
        customContentComp={({ onCloseSelectMenu }) =>
          <Styled.AccountsWrapper>
            { wallets?.length > 0 && (
              <div>
                <Styled.ContentTitle>{t('Wallets')}</Styled.ContentTitle>
                <div>
                  <Styled.SelectOption>
                    <Checkbox
                      design="brand"
                      checked={isAllWalletsCheck}
                      // onChange={(checked) =>
                      //   checked
                      //     ? setSelectedWallets(
                      //         wallets.map((wallet) => wallet.walletID),
                      //       )
                      //     : setSelectedWallets([])
                      // }
                      onChange={onCheckAllWallets}
                    />
                    {t('All Wallets')}
                  </Styled.SelectOption>
                  {wallets?.map((wallet) => (
                    <Styled.SelectOption key={wallet.walletID}>
                      <Checkbox
                        design="brand"
                        checked={selectedWallets.indexOf(wallet.walletID) > -1}
                        onChange={(checked) =>
                          setSelectedWallets(
                            checked
                              ? [...selectedWallets, wallet.walletID]
                              : selectedWallets.filter(
                                  (id) => id !== wallet.walletID,
                                ),
                          )
                        }
                      />
                      <WalletLine {...wallet} />
                    </Styled.SelectOption>
                  ))}

                  {exchangerWallets?.map((wallet) => (
                    <Styled.SelectOption key={wallet.walletID}>
                      <Checkbox
                        design="brand"
                        checked={selectedExchangerWallets.includes(wallet.walletID)}
                        onChange={(checked) =>
                          setSelectedExchangerWallets(
                            checked
                              ? [...selectedExchangerWallets, wallet.walletID]
                              : selectedExchangerWallets.filter(
                                  (id) => id !== wallet.walletID,
                                ),
                          )
                        }
                      />
                      <WalletLine {...wallet} />
                    </Styled.SelectOption>
                  ))}
                </div>
              </div>
            )}
            { !!accounts?.length && (
              <div>
                <Styled.ContentTitle>{t('Accounts')}</Styled.ContentTitle>
                <div>
                  <Styled.SelectOption>
                    <Checkbox
                      design="brand"
                      checked={selectedAccounts.length === accounts.length}
                      onChange={(checked) =>
                        checked
                          ? setSelectedAccounts(
                              accounts.map((account) => account.accountID),
                            )
                          : setSelectedAccounts([])
                      }
                    />
                    {t('All Accounts')}
                  </Styled.SelectOption>
                  {accounts.map((account) => (
                    <Styled.SelectOption key={account.accountID}>
                      <Checkbox
                        design="brand"
                        checked={
                          selectedAccounts.indexOf(account.accountID) > -1
                        }
                        onChange={(checked) =>
                          setSelectedAccounts(
                            checked
                              ? [...selectedAccounts, account.accountID]
                              : selectedAccounts.filter(
                                  (id) => id !== account.accountID,
                                ),
                          )
                        }
                      />
                      <AccountLine {...account} platformNoMap />
                    </Styled.SelectOption>
                  ))}
                </div>
              </div>
            )}
            <Styled.Buttons>
              <Button
                name="cancel"
                size="medium"
                design="tertiary"
                onClick={() => {
                  resetAccounts();
                  onCloseSelectMenu();
                }}
              >
                Cancel
              </Button>
              <Button name="cancel" size="medium" onClick={() => {
                changeAccounts()
                onCloseSelectMenu();
                }}>
                Select
              </Button>
            </Styled.Buttons>
          </Styled.AccountsWrapper>
        }
      />
    </>
  )
}
