import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetCardsList } from 'services/payments/get/useGetCardsList';
import { WithdrawalPage } from 'pages/money/withdrawal/WithdrawalPage'
import { Tabs } from 'ui/molecules'
import { path } from 'libs/path';

import { DepositChoice } from './depositChoice';
import { VerifyCardsTab } from './verifyCards/VerifyCardsTab';
import { InternalTransfersTab } from './internalTransfersTab';

import * as S from './styled';
import { MoneyHistoryList } from './transactionsNew';


export const MoneyPage = () => {
  const navigate = useNavigate()
  const location = useLocation();

  const { data, isLoading } = useGetCardsList();

  const val = useMemo(() => {
    if (location.pathname === path.money.depositTab()) return 'Deposit'
    if (location.pathname === path.money.withdrawalTab()) return 'Withdrawal'
    if (location.pathname === path.money.cardsTab()) return 'My cards'
    if (location.pathname === path.money.internalTransfer()) return 'Internal transfer';
    if (location.pathname === path.money.moneyHistory()) return 'Transaction History'
  }, [location?.pathname]);
  

  const onClickWith = () => navigate(path.money.withdrawalTab());
  const onClickDep = () => navigate(path.money.depositTab());
  const onClickVerify = () => navigate(path.money.cardsTab());
  const onClickTransfer = () => navigate(path.money.internalTransfer());

  useEffect(() => {
    if (
        data && !data?.bankCardInfo.length 
        && !isLoading 
        && location.pathname === path.money.cardsTab()
    ) {
      navigate(path.money.depositTab())
    }
  }, [data, isLoading]);

  const items = [
    {
      label: 'Deposit',
      children: <S.Wrapper><DepositChoice /></S.Wrapper>,
      onClick: onClickDep,
      
    },
    {
      label: 'Withdrawal',
      children: <S.Wrapper><WithdrawalPage /></S.Wrapper>,
      onClick: onClickWith,
    },
    {
      label: 'Transaction History',
      children: <S.Wrapper><MoneyHistoryList /></S.Wrapper>,
      onClick: () => navigate(path.money.moneyHistory()),
    },
    {
      label: 'Internal transfer',
      children: <S.Wrapper><InternalTransfersTab /></S.Wrapper>,
      onClick: onClickTransfer,
    }
  ];

  if (!!data?.bankCardInfo.length) {
    items.push({
      label: 'My cards',
      children: <VerifyCardsTab />,
      onClick: onClickVerify,
    })
  }
  
  return (
    <Tabs
      defaultValue={ val }
      value={val}
      withOutWrapp
      destroyUnactive
      fullHeight={false}
      items={items}
      tabLabelClasses='whitespace-nowrap'
    />
  )
}
