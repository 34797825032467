import React, { useCallback, useMemo } from 'react';
import { useStoreMap, useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { CurrenciesItem } from 'services/payments/get/DepositFormTypes';
import { SourceItem } from 'services/payments/get/types';

import { AmountInput } from 'ui/molecules';
import { Chip, Icon2, Money, Text } from 'ui/atoms';
import { useCurrency } from './useCurrency';
import { useAmountField } from './useAmount';
import { $amountField, onChangeAmountEvent } from '../model';
import { CalculatedAmount } from './CalculatedAmount';
import { useGetRecomendedDeposit } from 'services/payments/get/useGetRecomendedDeposit';
import { getSliceFloatNumber } from 'libs/parsers/getSliceFloatNumber';
import { useIsMobile } from 'libs/hooks/useIsMobile';
import { $amountForBigestPercent } from '../store/depositBonusStore';


type Options = {
  defaultCurrency?: CurrenciesItem;
  destenation?: SourceItem;
  gatewayId: string;
  isDisabled: boolean | undefined;
  amountPrecisions: Record<string, number>;
}

export const AmountField = ({ defaultCurrency, isDisabled, amountPrecisions = {}, gatewayId }: Options) => {
  const amount = useStoreMap($amountField, (state) => state.amount);
  const amountForBigestPercent = useUnit($amountForBigestPercent);

  const isMobile = useIsMobile();
  
  const [t] = useTranslation();

  const { isFlipCurrency, activeCurrency, onFlipCurrency, limits, isLoadingLimits } = useCurrency({ 
    defaultCurrencyObj: defaultCurrency!,
  });
  const { data, isLoading } = useGetRecomendedDeposit(gatewayId);

  const currentPrecisions = amountPrecisions[activeCurrency] || 2;

  const { errorType, isLoadingCalc } = useAmountField(amount, {
    activeCurrency,
    limits,
    defaultCurrency: defaultCurrency!.currencyCode
  });

  const chips = useMemo(() => {
    if (isLoading || !data) return;
    if (!data.currencyChips?.length) return;

    const chipsArray: string[] = [];

    data.currencyChips.forEach((chipObj) => {
      const finded = chipObj.chip[activeCurrency];
      if (!!finded) chipsArray.push(finded); 
    });

    const withoitDoubleChip = Array.from(new Set(chipsArray));

    return { values: withoitDoubleChip, chipCurrency: activeCurrency };
  }, [data, activeCurrency]);

  const errorMessage = () => {
    if (!errorType) return undefined;
    if (isLoadingLimits) return;

    if (errorType === 'LIMIT_ERROR') {
      return `Limit ${ activeCurrency } ${limits.min}-${limits.max}`;
    }

    if (errorType === 'EMPTY') {
      return t('money.customForm.enterAmount');
    }
  }

  return (
    <>
      <AmountInput
        className='!max-w-[440px] mt-[16px]'
        value={amount}
        disabled={isLoadingLimits || isDisabled }
        autoComplete='off'
        placeholder={t('money.customForm.minimumAmount', { activeCurrency, min: limits.min }) as string}
        errorMessage={errorMessage()}
        floatCount={amountPrecisions[activeCurrency]}
        inputClasses='!pe-[60px]'
        onChange={(val) => {
          // Максимальное число в js это число с разрядом в 16 символов
          // не пускаем больше максимального числа
          if (val) {
            const [integer] = (val as string).split('.');
            if (integer?.length >= 15) return;
          }
          onChangeAmountEvent(val as string)
        }}
        suffixType='button'
        suffix={
          <div className='flex lg:gap-[12px] gap-[4px]'>
            { activeCurrency }
            { isFlipCurrency && 
              <Icon2 
                name='arrowsUpDown'
                onClick={ isDisabled ? undefined : onFlipCurrency }
                disabled={ isLoadingLimits || isDisabled }
                className={ 'pointer' }
              />
            }
          </div>
        }
      />

      { (!isMobile && !errorMessage()) && amountForBigestPercent &&
        <Text level={2} withTranslate={false} className='text-left !w-[440px] mt-[8px]'>
          Add <span className='font-medium'><Money currency={amountForBigestPercent.currency} value={Number(amountForBigestPercent.amountNeeded)}/></span> to get a <span className='text-eucalyptus.500 font-medium'>+{amountForBigestPercent.bigestPercent}%</span> deposit bonus
        </Text>
      }

      {!!chips?.values?.length && 
        <div className='mt-[8px] !max-w-[440px] flex gap-[8px] flex-wrap justify-start w-full'>
          {chips.values.map((chipValue, index) => (
            <Chip
              key={`${index}_${chipValue}`}
              // className='grow'
              onClick={() => {
                onChangeAmountEvent(getSliceFloatNumber(chipValue, currentPrecisions));
              }}
            >
              <Text level={2} weight='medium' withTranslate={false}>
                {`${getSliceFloatNumber(chipValue, currentPrecisions)} ${activeCurrency}`}
              </Text>
            </Chip>
          ))}
        </div>
      }

      { isFlipCurrency &&
        <CalculatedAmount
          defaultGatewayCurrency={ defaultCurrency?.currencyCode }
          isLoadingCalc={ isLoadingCalc }
        />
      }
    </>
  )
}
