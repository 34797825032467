import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components/macro'

type TextWeightType = 'regular' | 'medium' | 'bold';

type Props = {
  className?: string;
  children: React.ReactNode;
  level?: 1 | 2 | 3 | 4;
  color?: ColorsVariant;
  weight?: TextWeightType; 
  withTranslate?: boolean;
  onClick?: (e: React.MouseEvent<HTMLParagraphElement>) => void;
}

type ColorsVariant = 'main' | 'subtext';

export const Text = ({
  className,
  children,
  level = 1,
  weight = 'regular',
  color = 'main',
  onClick,
  withTranslate = true,
}: Props) => {
  const [t] = useTranslation()
  let text = children
  if (withTranslate) text = t(String(children))

  return (
    <TextWrap
      className={className}
      weight={ weight }
      level={level}
      color={ color }
      onClick={ onClick }
    >
      {text}
    </TextWrap>
  )
}

const map = ({ level }: any) => ({
  'data-level': level,
})

const TextWrap = styled.p.attrs(map)<{ level: number, weight: TextWeightType, color: ColorsVariant }>`
  font-weight: ${({weight}) => {
    if (weight === 'regular') return 400;

    if (weight === 'medium') return 500;

    if (weight === 'bold') return 700;

    return 400;
  }};

  color: ${(p) => {
    if (p.color === 'subtext') return 'var(--color-gray-600)';

    return 'var(--color-gray-700)';
  }};
 

  ${up('sm')} {
    &[data-level='1'] {
      font-size: var(--body-font-size-regular);
      line-height: var(--body-line-height-regular);
    }

    &[data-level='2'] {
      font-size: var(--body-font-size-medium);
      line-height: var(--body-line-height-medium);
    }

    &[data-level='3'] {
      font-size: var(--body-font-size-small);
      line-height: var(--body-line-height-small);
    }

    &[data-level='4'] {
      font-size: var(--body-font-size-tiny);
      line-height: var(--body-line-height-tiny);
    }
  }
`
