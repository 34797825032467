import { useWindowSize } from '@uidotdev/usehooks'
import { path } from 'libs/path'
import { CountryName } from 'pages/contest/molecules/countryName'
import { icons } from 'pages/contests/images/icons'
import { useTranslation } from 'react-i18next'
import type { Contest, LeaderboardRow } from 'services/ramadan/types'
import { Button, Icon2 } from 'ui/atoms'

import * as Styled from './styled'

const TableRow = (
  props: LeaderboardRow & { isMobile: boolean; isSelected?: boolean },
) => {
  const [t] = useTranslation()

  return (
    <Styled.TableRow selected={!!props.isSelected}>
      <Styled.PlaceWrapper>
        {props.place > 1 ? (
          props.place
        ) : (
          <img
            src={icons[`smallMedal_${props.place}`]}
            alt={`${props.place}`}
          />
        )}
      </Styled.PlaceWrapper>
      <Styled.NameWrapper>
        {props.name}
        <CountryName code={props.country} />
      </Styled.NameWrapper>
      <Styled.RegularCell>
        {props.isMobile && <Styled.CellTitle>{t('Lots')}</Styled.CellTitle>}
        {props.lots}
      </Styled.RegularCell>
    </Styled.TableRow>
  )
}

export const Leaderboard = (props: Pick<Contest, 'leaderboard'>) => {
  const [t] = useTranslation()

  const { width } = useWindowSize()
  const isMobileMode = width && width < 600

  return (
    <Styled.Container>
      <Styled.Title level={2}>Leaderboard</Styled.Title>
      <div className="mb-[32px] text-center text-body.regular leading-body.regular">
        {t('Hurry up to trade! The leaderboard restarts every 24 hours.')}
      </div>
      <div>
        <div>
          {!isMobileMode && (
            <Styled.TableHead>
              <div>{t('Place')}</div>
              <div className="ps-[16px] text-left">{t('Participant')}</div>
              <div>{t('Lots')}</div>
            </Styled.TableHead>
          )}

          <TableRow
            {...props.leaderboard[0]}
            isMobile={!!isMobileMode}
            isSelected
          />
        </div>

        <Styled.TopTraders>
          <Icon2 name="arrowLongDownIcon" size="small" />
          {t('Lucky winner')}
          <Icon2 name="arrowLongDownIcon" size="small" />
        </Styled.TopTraders>

        <div>
          {props.leaderboard.slice(1).map((leader) => (
            <TableRow
              {...leader}
              key={leader.place}
              isMobile={!!isMobileMode}
            />
          ))}
        </div>
      </div>

      <Button
        design="secondary"
        size="small"
        className="mt-[16px] mx-auto"
        to={path.ramadan.results()}
      >
        See the winners of the past days
      </Button>
    </Styled.Container>
  )
}
