import React, { useEffect } from 'react';

import { TransactionList } from './components/List';
import { Filters } from './components/filters';
import { useResetStore } from './utils/useResetStore';

import { Spinner2, Title } from 'ui/atoms';
import { useGetTransactionFilter } from 'services/money/get';

import { resetMoneyHistoryEvent } from './model';
import * as S from './styled';


export const MoneyHistoryList = () => {
  useResetStore();

  const { data: filter, isLoading } = useGetTransactionFilter();

  useEffect(() => {
    return () => {
      resetMoneyHistoryEvent();
    }
  }, []);
  

  if (isLoading || !filter) return <Spinner2 size='large' />
  
  return (
    <S.OuterWrapper>
    <Title level={2} className='mb-[8px]'>
      Transaction History
    </Title>
    <Filters data={filter} />
    <TransactionList />
    
  </S.OuterWrapper>
  )
}
