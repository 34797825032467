import { name } from 'libs/validators';
import * as Yup from 'yup';

// export const schema = Yup.object().shape({
//   firstName: name,
//   lastName: name,
//   contactNumber: Yup.string().required('Required'),
//   country: Yup.string().required('Required'),
//   address: Yup.string().required('Required'),
//   size: Yup.string().required('Required'),
//   postalCode: Yup.number().optional(),
// })

export const getSchemaGiftOrder = (isHaveSiseField: boolean) => {
  const obj =  Yup.object().shape({
    firstName: name,
    lastName: name,
    contactNumber: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    size: Yup.lazy((val) => {
      if (isHaveSiseField) return Yup.string().required('Complete this field')
      return Yup.string().optional();
    }),
    postalCode: Yup.string().required('Complete this field'),
  })

  return obj;
};