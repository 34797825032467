import React from 'react';
import { Block, Icon2, Input, Text, Title } from 'ui/atoms';

import { CopyIcon } from 'ui/molecules/CopyButton/CopyIcon';

interface Props {
  partnerId: string;
  partnerLink: string;
}

export const RefererLink: React.FC<Props> = ({ partnerId, partnerLink }) => {

  return (
    <Block>
      <div className="w-full flex">
        <Title level={3}>Referral link</Title>
        <Icon2 name="link" className="ms-[6px] text-sunray.500" />

        <Text className="ms-auto !text-gray.600">Parnter ID</Text>
        <Text className="ms-[4px] !font-medium">{partnerId}</Text>
      </div>

      <div className="mt-[2.4rem]">
        <Input
          name="link"
          value={partnerLink}
          inputClasses='!pe-[48px]'
          autoFocus={false}
          autoComplete="off"
          suffixType="button"
          suffix={
            <CopyIcon
              tooltipText='Copied'
              copiedText={partnerLink}
              className='ms-[8px]'
              iconSize='medium'
              copyIconClass='text-sunray.500'
            />
        }
        />
      </div>
    </Block>
  )
}
