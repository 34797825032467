import { setIsChatLoaded } from 'features/user/model'
import { useEffect } from 'react'
import Zendesk, { ZendeskAPI } from 'react-zendesk'
import { useGetJWT } from 'services/zendesk'

// ZendeskAPI недостаточно для реализации всей необходимой логики,
// поэтому используется window.zE

export const ChatWidget = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const { refetch } = useGetJWT()

  const initializeMessengerListeners = async () => {
    try {
      if (!window.zE) throw new Error('Zendesk not initialized.')

      ZendeskAPI('messenger:on', 'open', () => {
        ZendeskAPI(
          'messenger',
          'loginUser',
          async (callback: (token: string) => void) => {
            try {
              const { data: jwtToken } = await refetch()

              callback(jwtToken || '')
            } catch (error) {
              console.error('Error fetching JWT token:', error)
            }
          },
        )
      })
    } catch (error) {
      console.error('Failed to initialize Zendesk messenger listeners:', error)
    }
  }

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    const waitForZendesk = async () => {
      const timeout = 5000
      const interval = 100
      let elapsedTime = 0

      while (!window.zE && elapsedTime < timeout) {
        await new Promise((resolve) => setTimeout(resolve, interval))
        elapsedTime += interval
      }

      if (window.zE) {
        await initializeMessengerListeners()
      } else {
        console.error('Zendesk widget did not load within the timeout period.')
      }
    }

    waitForZendesk()
  }, [isLoggedIn])

  return (
    <Zendesk
      defer
      zendeskKey="84faf6b6-64bf-41ed-ad24-ace5a7992428"
      onLoaded={() => setIsChatLoaded(true)}
    />
  )
}
