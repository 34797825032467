import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useActionButton } from 'pages/ramadan/utils/useActionButton';
import { Title, Text, Button } from 'ui/atoms';
import { useIsMobile } from 'libs/hooks/useIsMobile';

import BgPng from './bgX2.png';
import BgMob from './bgMob.png';
import charity1 from './charity1.png';
import charity2 from './charity2.png';
import charity3 from './charity3.png';
import videoImage from './videoPng.png';

import * as S from './styled';
import { Modal } from 'ui/molecules/modal_draft';
import { Trans, useTranslation } from 'react-i18next';
import { ParticipantStatus } from 'services/ramadan/types';

interface Props {
  isMadeDeposit: boolean;
  isJoined: boolean;
  // participantStatus: ParticipantStatus;
}


export const CharityInfo: React.FC<Props> = ({ isJoined, isMadeDeposit }) => {
  const isMobile = useIsMobile();
  const [t] = useTranslation();

  const [videoModal, setVideoModal] = useState(false);

  const { buttonLabel, onClickButton, isLoading } = useActionButton({isJoined, isMadeDeposit});

  return (
    <div className='xl:w-[880px] w-full md:py-[32px] py-[24px] bg-eucalyptus.100'>
      <div className='w-full flex flex-col gap-[8px] items-center px-[44px] md:px-[48px]'>
        <Text level={2} weight='medium'>Headway Charity</Text>
        <Title level={1} className='text-center'>Ramadan is the time of good deeds</Title>
      </div>

      <S.BgWrapper url={isMobile ? BgMob : BgPng}>
        <S.Card className='md:min-h-[354px]'>
          <div>
            <Title level={isMobile ? 3 : 2} className='text-center md:text-left'>They wait for our help</Title>
            <Text className='mt-[16px] md:pr-[32px] text-center md:text-left'>
              This holy month, we exchange every standard lot you trade into $1 to donate to your local charity organizations. Let’s help your communities together!
            </Text>
          </div>

          <div className='w-full flex md:gap-[32px] gap-[24px] md:flex-row flex-col'>
            <div className='flex-1 flex items-center flex-col'>
              <S.PartnerImgWrapp>
                <LazyLoadImage src={charity1} width={'40px'} height={'40px'}/>
              </S.PartnerImgWrapp>
              <Text className='mt-[8px] text-center' level={2} weight='medium'>Nour Fe Elbuyot, Egypt</Text>
            </div>

            <div className='flex-1 flex items-center flex-col'>
              <S.PartnerImgWrapp>
                <LazyLoadImage src={charity2} width={'40px'} height={'40px'}/>
              </S.PartnerImgWrapp>
              <Text className='mt-[8px] text-center' level={2} weight='medium'>Yayasan Gema Insan Amanah, Indonesia</Text>
            </div>

            <div className='flex-1 flex items-center flex-col'>
              <S.PartnerImgWrapp>
                <LazyLoadImage src={charity3} width={'40px'} height={'40px'}/>
              </S.PartnerImgWrapp>

              <Text className='mt-[8px] text-center' level={2} weight='medium'>Dignity for Children Foundation, Malaysia</Text>
            </div>
          </div>

          {/* { participantStatus === 'UNREGISTERED' &&
            <Button className='md:w-[192px] w-full' design='secondary' onClick={}>Learn more</Button>
          } */}
        </S.Card>

        <S.Card className='md:!w-[272px]  !justify-between'>
          <div>
            <Title level={isMobile ? 3 : 2} className='text-center md:text-left'>Last year charity</Title>
            <Text className='mt-[16px] md:pr-[32px] text-center md:text-left'>
              We gifted a trip to Umrah to one of our traders.
            </Text>
          </div>

          <LazyLoadImage
            className='md:w-[224px] w-[231px] h-[130px] cursor-pointer'
            src={videoImage} 
            onClick={() => setVideoModal(true)}
          />
        </S.Card>
      </S.BgWrapper>

      <Modal
        open={videoModal}
        onClose={() => setVideoModal(false)}
        width={1000}
        footer={false}
      >
        <S.VideoWrapper>
          <iframe
            className="absolute w-full h-full"
            id="ytplayer"
            src={`https://www.youtube.com/embed/09iwZrnHglY?autoplay=1&mute=1&si=3C9mFWrQLNTwBhfQ`}
            allowFullScreen
            allow="autoplay; encrypted-media"
            title="How it works"
          />
        </S.VideoWrapper>
        <S.VideoHint>
          <Trans
            t={t}
            i18nKey="If the video is not playing, watch it on YouTube"
          >
            If the video is not playing, watch it on{' '}
            <a
              // href={`https://www.youtube.com/watch?v=${videoLink}`}
              href={`https://www.youtube.com/watch?v=09iwZrnHglY`}
              target="_blank"
              rel="noreferrer"
              className="text-sunray.700 font-medium"
            >
              YouTube
            </a>
          </Trans>
        </S.VideoHint>
      </Modal>

      {(!isJoined || !isMadeDeposit) && 
        <div className='w-full mt-[10px] flex justify-center'>
          <Button design='primary' className='md:w-[240px] w-full' onClick={onClickButton} loading={isLoading}>
            { buttonLabel }
          </Button>
        </div>
      }
    </div>
  )
}
