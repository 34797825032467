import { path } from 'libs/path'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  OrderForm,
  useGetGiftDetails,
  useGetWidgetInfo,
  useGiftOrder,
  useTargetGift
} from 'services/giftshop'
import { Back, Icon2, Spinner2, Text, Title } from 'ui/atoms'
import { Modal } from 'ui/molecules/modal_draft'
import { useIsFirstRender, useWindowSize } from 'usehooks-ts'

import { useLanguageChange } from '../hooks'
import { GiftshopBanner } from '../molecules/banner'
import { ChangeTargetModal } from '../molecules/changeTargetModal'
import { ThanksModal } from '../molecules/thanksModal'
import { GiftOrderForm } from './molecules/giftOrder/GiftOrderForm'
import * as S from './styled'

export const GiftDetails = () => {
  const formRef = useRef<HTMLDivElement>(null)

  const { width } = useWindowSize()
  const isMobileMode = width < 571

  const isFirstRender = useIsFirstRender()

  const { giftId } = useParams()

  const [formValues, setFormValues] = useState<OrderForm | null>(null)

  const [isTargetModalVisible, setIsTargetModalVisible] = useState(false)
  const [isThanksModalVisible, setIsThanksModalVisible] = useState(false)
  const [isConfirmationOrderVisible, setIsConfirmationOrderVisible] =
    useState(false)

  const {
    data: details,
    isFetching,
    refetch: refetchGiftDetails,
  } = useGetGiftDetails(+(giftId as string))
  const { refetch: refetchWidgetInfo } = useGetWidgetInfo(false)
  const {
    mutateAsync: targetGift,
    isLoading: targetLoading,
    isSuccess: targetChanged,
  } = useTargetGift()
  const {
    mutateAsync: orderGift,
    isLoading: orderLoading,
    isSuccess: orderPlaced,
  } = useGiftOrder(+(giftId as string))

  useLanguageChange(refetchGiftDetails)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: isFirstRender ? 'instant' : 'smooth' })

    refetchGiftDetails()
  }, [giftId])

  useEffect(() => {
    if (targetChanged) {
      refetchWidgetInfo()
    }
  }, [targetChanged])

  useEffect(() => {
    if (orderPlaced) {
      setIsConfirmationOrderVisible(false)

      setIsThanksModalVisible(true)
    }
  }, [orderPlaced])

  const handleSubmitOrder = (values: OrderForm) => {
    setFormValues(values)
    setIsConfirmationOrderVisible(true)
  }

  return (
    <S.Container>
      <Back to={path.giftshop.entry()} className="!m-0 !w-auto" size="small" />

      {isFetching ? (
        <S.SpinnerWrapper>
          <Spinner2 />
        </S.SpinnerWrapper>
      ) : details ? (
        <S.ContentWrapper>
          <S.ImageWrapper isTarget={details.isTarget}>
            <S.Price
              value={details.price}
              size={isMobileMode ? 'medium' : 'large'}
            />
            <img src={details.imageURL} alt={details.title} loading="lazy" />
          </S.ImageWrapper>
          <S.TextWrapper>
            <Title
              level={isMobileMode ? 2 : 1}
              className="!leading-[40px] text-center"
              withTranslate={false}
            >
              {details.title}
            </Title>
            <Text
              level={isMobileMode ? 2 : 1}
              className="text-center"
              withTranslate={false}
            >
              {details.description}
            </Text>
          </S.TextWrapper>
          {!details.isTarget ||
          details.orderAvailability !== 'NOT_AVAILABLE' ? (
            <S.Buttons>
              {!details.isTarget ? (
                <S.OrderButton
                  name="target"
                  prefix={<Icon2 name="star" />}
                  design="secondary"
                  onClick={() =>
                    details.isExistTargetGift
                      ? setIsTargetModalVisible(true)
                      : targetGift(+(giftId as string))
                  }
                  disabled={targetLoading}
                  loading={targetLoading}
                >
                  Target
                </S.OrderButton>
              ) : null}
              {details.orderAvailability !== 'NOT_AVAILABLE' ? (
                <S.OrderButton
                  name="order-now"
                  onClick={() =>
                    details.orderAvailability === 'AVAILABLE_WITH_FORM'
                      ? formRef.current?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start',
                        })
                      : setIsConfirmationOrderVisible(true)
                  }
                >
                  Order now
                </S.OrderButton>
              ) : null}
            </S.Buttons>
          ) : null}
          <GiftshopBanner inner />

          {details.orderAvailability === 'AVAILABLE_WITH_FORM' ? (
            <S.OrderWrapper ref={formRef}>
              <Title level={isMobileMode ? 2 : 1}>Order your gift</Title>

              <S.OrderText level={isMobileMode ? 2 : 1}>
                Please fill in the shipping form below. We will use this
                information to send you the gift.
              </S.OrderText>

              <GiftOrderForm
                onSubmit={handleSubmitOrder}
                disabled={orderLoading}
                formAdditionalFields={details.formAdditionalFields}
              />
            </S.OrderWrapper>
          ) : null}

          <ChangeTargetModal
            giftId={details.id}
            isOpen={isTargetModalVisible}
            onClose={() => setIsTargetModalVisible(false)}
          />

          <Modal
            open={isConfirmationOrderVisible}
            onOk={() => orderGift(formValues)}
            onClose={() => setIsConfirmationOrderVisible(false)}
            okButtonProps={{ disabled: orderLoading, loading: orderLoading }}
            title="Confirm your order"
            okLabel="Confirm"
            cancelLabel="Back"
          >
            <Text className="!text-gray.600">
              Your Crowns will cover the gift cost, and our Customer Care
              managers will process your request.
            </Text>
          </Modal>
        </S.ContentWrapper>
      ) : null}

      <ThanksModal
        isOpen={isThanksModalVisible}
        onClose={() => setIsThanksModalVisible(false)}
      />
    </S.Container>
  )
}
