import { useWindowSize } from '@uidotdev/usehooks'
import { path } from 'libs/path'
import { useTranslation } from 'react-i18next'
import { useGetContestInfo } from 'services/ramadan'
import { Button, Title } from 'ui/atoms'

import { CharityInfo } from './molecules/charityInfo'
import { CharityProgress } from './molecules/charityProgress'
import { ContestFooter } from './molecules/contestFooter'
import { ContestHeader } from './molecules/contestHeader'
import { DailyResults } from './molecules/dailyResults'
import { DisqualifiedBlock } from './molecules/disqualifiedBlock'
import { HallOfFame } from './molecules/hallOfFame'
import { Organization } from './molecules/hallOfFame/OrganizationBlock'
import { JoinBlock } from './molecules/joinBlock'
import { Leaderboard } from './molecules/leaderboard'
import { ColorMessage, SimpleMessage } from './molecules/messageBlock'
import { JoinedModal } from './molecules/modals/JoinedModal'
import { OpportunitiesBlock } from './molecules/opportunitiesBlock'
import { FridayPrizes } from './molecules/prizes/fridayPrizes'
import { WeekPrizes } from './molecules/prizes/weekPrizes'
import { Terms } from './molecules/terms'
import * as Styled from './styled'

export const RamadanContest = () => {
  const [t] = useTranslation()

  const { width } = useWindowSize()
  const isMobile = width && width < 600

  const { data } = useGetContestInfo({ isHallOfFame: false })
  
  return (
    <>
      {data ? (
        <Styled.ContentContainer>
          <ContestHeader data={data} />

          <Styled.ContestBody>
            {data.promo.status !== 'REGISTRATION' && data.charity && (
              <div className="mb-[56px] mobile:mb-[40px]">
                <CharityProgress
                  startDate={data.promo.promoStartsTs}
                  endDate={data.promo.promoEndsTs}
                  progressBarPercentage={data.charity.progressBarPercentage}
                  collectedAmount={data.charity.collectedAmount}
                  isEnded={data.promo.status === 'ENDED'}
                />
              </div>
            )}

            {data.promo.status === 'ENDED' && (
              <>
                <div className="mb-[56px] mobile:mb-[40px]">
                  <Organization />
                </div>
                <HallOfFame
                  winners={data.winners}
                  daysCount={data.promo.daysCount}
                />
              </>
            )}

            {data.promo.status === 'ACTIVE' &&
              data.participant.status === 'DISQUALIFIED' && (
                <DisqualifiedBlock />
              )}

            {data.promo.status === 'ACTIVE' &&
              data.promoDay &&
              data.promoDay.currentDay.dayType === 'WEEKEND' && (
                <div className="my-[56px] mobile:my-[40px]">
                  <SimpleMessage title="Ramadan Challenge: Come back on Monday">
                    {t(
                      'Lots that you trade during the weekend are collected for the Headway Charity program. Keep on doing a good deed!',
                    )}
                  </SimpleMessage>
                </div>
              )}

            {data.promo.status === 'ACTIVE' &&
              data.participant.status === 'UNREGISTERED' &&
              data.promoDay &&
              data.promoDay.currentDay.dayType === 'SUPER_FRIDAY' && (
                <div className="my-[56px] mobile:my-[40px]">
                  <SimpleMessage title="Today is Super Friday!">
                    {t(
                      'Join the Ramadan Challenge now, deposit $20 in a single transaction, and seize the day!',
                    )}
                    <br />
                    {t('Your win is a win for our charitable organizations.')}
                  </SimpleMessage>
                </div>
              )}

            {data.promo.status === 'ACTIVE' &&
              data.participant.status === 'ACTIVE' &&
              data.promoDay &&
              data.promoDay.currentDay.dayType !== 'WEEKEND' &&
              data.participant.dailyResults && (
                <div>
                  <Title level={2} className="mb-[24px] text-center">
                    Ramadan Challenge
                  </Title>
                  <Styled.Prizes>
                    {data.promoDay.currentDay.dayType === 'SUPER_FRIDAY' ? (
                      <FridayPrizes
                        isExtended
                        bestTrader={data.promo.prizes.fridayDayPrize.bestTrader}
                        lucky={data.promo.prizes.fridayDayPrize.lucky}
                      />
                    ) : (
                      <WeekPrizes
                        title="Today’s prizes"
                        bestTrader={
                          data.promo.prizes.regularDayPrize.bestTrader
                        }
                        lucky={data.promo.prizes.regularDayPrize.lucky}
                      />
                    )}
                    <DailyResults
                      place={data.participant.dailyResults.place}
                      lotsCount={data.participant.dailyResults.lots}
                      ticketsCount={data.participant.dailyResults.ticketsCount}
                      dayID={data.promoDay.currentDay.dayID}
                    />
                  </Styled.Prizes>
                </div>
              )}

            {(data.promo.status === 'REGISTRATION' ||
              (data.promo.status === 'ACTIVE' &&
                data.participant.status === 'UNREGISTERED')) && (
              <>
                <JoinBlock
                  isJoined={data.participant?.isJoined}
                  isMadeDeposit={data.participant?.isMadeDeposit}
                  status={data.participant?.status}
                  promoStatus={data.promo.status}
                />

                <div className="mt-[56px] mobile:mt-[40px]">
                  <OpportunitiesBlock />
                </div>

                <div className="mt-[32px]">
                  <Title level={2} className="mb-[24px] text-center">
                    Daily prizes
                  </Title>
                  <Styled.Prizes>
                    <WeekPrizes
                      title="All week"
                      bestTrader={data.promo.prizes.regularDayPrize.bestTrader}
                      lucky={data.promo.prizes.regularDayPrize.lucky}
                    />
                    <FridayPrizes
                      bestTrader={data.promo.prizes.fridayDayPrize.bestTrader}
                      lucky={data.promo.prizes.fridayDayPrize.lucky}
                    />
                  </Styled.Prizes>
                </div>
              </>
            )}

            {data.promo.status === 'ACTIVE' &&
              data.promoDay &&
              data.promoDay.currentDay.dayType !== 'WEEKEND' && (
                <Leaderboard leaderboard={data.leaderboard} />
              )}

            {data.promo.status === 'REGISTRATION' && (
              <div className="mt-[56px] mobile:mt-[40px]">
                <CharityInfo
                  isJoined={data.participant.isJoined}
                  isMadeDeposit={data.participant.isMadeDeposit}
                />
              </div>
            )}

            {(data.promo.status === 'REGISTRATION' ||
              (data.promo.status === 'ACTIVE' &&
                data.participant.status !== 'ACTIVE')) && (
              <div className="mt-[56px] mobile:mt-[40px]">
                <Terms />
              </div>
            )}

            {data.promo.status === 'ACTIVE' &&
              data.participant.status === 'ACTIVE' && (
                <div className="mt-[56px] mobile:mt-[40px]">
                  <ContestFooter />
                </div>
              )}

            {data.promo.status === 'ENDED' && (
              <div className="mt-[56px] mobile:mt-[40px]">
                <ColorMessage
                  title={t('Keep trading on Islamic accounts')}
                  text={t(
                    'Enjoy a better trading experience with no swaps on Headway.',
                  )}
                >
                  <Button
                    size={isMobile ? 'small' : 'medium'}
                    design="secondary"
                    className="min-w-[200px] mobile:w-full"
                    to={path.money.depositTab()}
                  >
                    Deposit now
                  </Button>
                </ColorMessage>
              </div>
            )}

            <JoinedModal />
          </Styled.ContestBody>
        </Styled.ContentContainer>
      ) : (
        'loading...'
      )}
    </>
  )
}
