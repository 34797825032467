
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useIsMobile } from 'libs/hooks/useIsMobile';
import { path } from 'libs/path';

import { Title, Text, Button } from 'ui/atoms';

import bgMob from './leadboardMob.png';

import * as S from './styled';


export const LeaderBoard = () => {
  const isMobile = useIsMobile();
  const nav = useNavigate();

  const onDeposit = () => {
    nav(path.money.depositTab());
  }

  return (
      // isMobile 
      // ? (
      //     <S.LeaderBoardBanner url={bgMob}>
      //       <Title level={3} className='text-center'>
      //         Want to go up the leaderboard?
      //       </Title>
      //       <Text level={1} className='mt-[16px] text-center'>
      //         Trade with bigger volumes! Make a deposit now to increase your trading opportunities.
      //       </Text>

      //       <Button className='mt-[24px] w-full' design='primary' onClick={onDeposit}>
      //         Deposit now
      //       </Button>
      //     </S.LeaderBoardBanner>
      // )
      // : (
        <div className='lg:w-[500px] px-[24px] py-[32px] bg-[#009000] rounded-[8px]'>
          <Title level={ isMobile ? 3 : 2} className='md:text-left text-center !text-white'>
            Want to go up the leaderboard?
          </Title>
          <Text level={1} className='md:text-left text-center mt-[16px] !text-white'>
            Trade with bigger volumes! Make a deposit now to increase your trading opportunities.
          </Text>

          <Button className='mt-[24px] lg:w-[260px] w-full lg:mb-0 mb-[18px]' design='secondary' onClick={onDeposit}>
            Deposit now
          </Button>
        </div>
      // )
  )
}
