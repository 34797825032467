import React, { useState, useCallback, useEffect } from 'react';
import { useUnit } from 'effector-react';

import { useDelDepositPromocode } from 'services/money/mutations';
import { Icon2, Skeleton, Text } from 'ui/atoms';

import { PromoCodeModal } from './PromoCodeModal';
import { $bonusDeposit, $bonusPromocode, setBonusPromocodeEvent } from '../store/depositBonusStore';


interface Props {
  codeFromBe?: string;
  promoRefetch: () => void;
}

export const PromoCode: React.FC<Props> = ({ codeFromBe, promoRefetch }) => {
  const [isModal, setIsModal] = useState(false);

  const { mutateAsync, isLoading: isDeletePromoLoading } = useDelDepositPromocode();

  const storeCode = useUnit($bonusPromocode);
  const isActiveCheck = useUnit($bonusDeposit);

  const onCloseModal = useCallback(() => {
    setIsModal(false);
  }, [promoRefetch])

  const onSuccessModal = useCallback(() => {
    promoRefetch();
  }, [promoRefetch])

  const onDeletePromo = async () => {
    if (isDeletePromoLoading) return;
    try {
      await mutateAsync();
      setBonusPromocodeEvent(null);
      promoRefetch();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (codeFromBe && !storeCode && codeFromBe !== storeCode) {
      setBonusPromocodeEvent(codeFromBe);
    }
  }, [codeFromBe]);

  // if (!isActiveCheck && !storeCode) return null;

  return (
    <div className='mt-[8px] w-full h-[32px] flex items-center justify-between'>
      {storeCode 
        ? <>
          <Text level={2} weight={'medium'} color='main'>Promo code</Text>
          <Text withTranslate={false} level={2} color='main' className='flex items-center'>
            {storeCode}
            <Icon2
              name='xMarkIcon'
              className='cursor-pointer ml-[4px]'
              size='base'
              onClick={onDeletePromo}
            />
          </Text>
        </> 
        : <>
          <Text
            className='!text-bronze.500 cursor-pointer'
            weight='medium'
            level={2}
            onClick={() => setIsModal(true)}
          >
            I have a promo code
          </Text>

          {isModal && <PromoCodeModal closeModal={onCloseModal} onSuccessModal={onSuccessModal} />}
        </>
      }
    </div>
  )
}
