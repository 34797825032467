import { useDebounce, useWindowSize } from '@uidotdev/usehooks'
import { path } from 'libs/path'
// todo: унифицировать эти кнопки
import { BackButton } from 'pages/copytrade/molecules/backButton'
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetExchangeRecipients } from 'services/wallet/get/useGetExchangeRecipients'
import { useExchange } from 'services/wallet/mutations/useExchange'
import { useGetExchangerWallets } from 'services/wallet/get/useGetExchangerWallets'
import { Button, Icon2, Input, Spinner2, Title } from 'ui/atoms'

import { getFullName } from '../utils'
import * as S from './styled'

export const ExchangeTransfer = () => {
  const [t] = useTranslation()

  const { state } = useLocation();
  const { data: wallets, isLoading } = useGetExchangerWallets({ noRefetch: true });
  const enabledReqRef = useRef(false);

  const nav = useNavigate();

  const { width } = useWindowSize()
  const isMobileMode = width && width < 600

  const [accountSearchValue, setAccountSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(accountSearchValue, 300)

  const [amountValue, setAmountValue] = useState('')

  const [selectedAccount, setSelectedAccount] = useState('')

  const walletId = useMemo(() => {
    if (!wallets || !wallets.items?.length) return '';

    return wallets.items[0].exchangerWalletID;
  }, [wallets]);

  const {
    data: recipients,
    refetch,
    isError: isRecipientsError,
  } = useGetExchangeRecipients({
    params: {
      fromExchangerWalletID: state?.id || walletId,
      toMTLogin: debouncedSearchValue,
    },
    enabled: enabledReqRef.current
  })

  const { 
    data: exchangeResult, 
    mutateAsync: exchange, 
    isLoading: isCreatingTransfer,
    isSuccess 
  } = useExchange()

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const output = event.target.value.replace(/[^.\d]/g, '').split('.')

    setAmountValue(
      output.length > 1 ? output.shift() + '.' + output.join('') : output[0],
    )
  }

  const handleChangeLogin = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value?.replace(/[^.\d]/g, '');
    setAccountSearchValue(val);
  }

  const handleTransfer = () => {
    exchange({
      fromExchangerWalletID: state?.id || '',
      toAccountID: selectedAccount,
      amount: amountValue,
    })
  }

  const isError = state?.amount && +amountValue > +state.amount

  const currentRecipient = recipients?.find(
    (recipient) => recipient.accountID === selectedAccount,
  )

  useEffect(() => {
    if (debouncedSearchValue) {
      enabledReqRef.current = true;
      refetch();
    }
  }, [debouncedSearchValue])

  useEffect(() => {
    if (recipients?.length === 1) {
      setSelectedAccount(recipients[0].accountID)
    }
  }, [recipients]);

  useEffect(() => {
    if (!isLoading && wallets?.items.length === 0) {
      nav(path.dashboard.entry());
    }
  }, [wallets, isLoading]);

  if (isLoading && !state?.id) return <Spinner2 size='large'/>;

  return (
    <S.Wrapper>
      {!isSuccess ? (
        <>
          <BackButton to={path.exchanger.entry()} />
          <S.ContentContainer>
            <S.TransferTitle level={isMobileMode ? 3 : 2}>
              Transfer to client
            </S.TransferTitle>
            {selectedAccount && currentRecipient && (
              <div className="mt-[8px] text-body.medium leading-body.medium font-medium text-center">
                {getFullName(
                  currentRecipient.firstName,
                  currentRecipient.middleName,
                  currentRecipient.lastName,
                )}
              </div>
            )}
            <S.Fields>
              <div>
                <Input
                  placeholder="Client account number"
                  value={accountSearchValue}
                  onChange={handleChangeLogin}
                  maxLength={30}
                  state={isRecipientsError ? 'error' : 'hint'}
                  suffix={
                    recipients?.length === 1 ? (
                      <Icon2 name="check" className="text-eucalyptus.700" />
                    ) : null
                  }
                  errorMessage={isRecipientsError ? t('Account not found') as string : undefined}
                />
                {recipients && recipients.length >= 1 && (
                  <div className='mt-[16px]'>
                    {recipients.length > 1 && 
                      <div className="font-medium text-gray.600 text-body.medium leading-body.medium">
                        {t('Several accounts found:')}
                      </div>
                    }
                    {recipients.map((recipient, index) => (
                      <S.RadioWrapper
                        key={index}
                        isActive={selectedAccount === recipient.accountID}
                        onClick={() => setSelectedAccount(recipient.accountID)}
                      >
                        <div>
                          <div className="font-medium text-body.medium leading-body.medium flex items-center">
                            {debouncedSearchValue}
                            <div className='
                              ml-[6px] text-body.small leading-body.small text-center
                              bg-gray.300 rounded-[4px] px-[4px] h-[18px]
                            '
                            >
                              { recipient.platform }
                            </div>
                          </div>
                          <div className="mt-[2px] text-body.small leading-body.small">
                            {getFullName(
                              recipient.firstName,
                              recipient.middleName,
                              recipient.lastName,
                            )}
                          </div>
                        </div>
                      </S.RadioWrapper>
                    ))}
                  </div>
                )}
              </div>
              <Input
                placeholder="Amount"
                value={amountValue}
                onChange={handleAmountChange}
                suffix={<div className="font-medium">USD</div>}
                state={isError ? 'error' : 'hint'}
                errorMessage={isError && t('Insufficient funds. Available for transfer:', {amount: `${state.amount} USD`})}
              />
            </S.Fields>
            {!isError && 
              <S.Amount>
                {t('Available for transfer:', {
                  amount: state?.amount ? ` ${state.amount} USD` : '',
                })}
              </S.Amount>
            }
            <S.Actions>
              <Button
                design="secondary"
                prefix={<Icon2 name="arrowLeft" />}
                to={path.exchanger.entry()}
                size={isMobileMode ? 'medium' : 'large'}
              >
                Back
              </Button>
              <S.TransferButton
                disabled={!amountValue || !selectedAccount || isError || isCreatingTransfer}
                loading={ isCreatingTransfer }
                size={isMobileMode ? 'medium' : 'large'}
                onClick={handleTransfer}
              >
                {t('Transfer to client')}
              </S.TransferButton>
            </S.Actions>
          </S.ContentContainer>
        </>
      ) : (
        <S.SuccessScreen>
          <Title level={isMobileMode ? 3 : 2}>Success</Title>
          <S.SuccessText>
            {t('Your payment is processed successfully.')}
          </S.SuccessText>
          <S.TransactionInfo>
            <div className="flex items-center justify-between">
              {t('Transaction ID')}
              <span className="font-medium">{exchangeResult.transferID}</span>
            </div>
            <div className="flex items-center justify-between">
              {t('Amount')}
              <span className="font-medium">{amountValue} USD</span>
            </div>
          </S.TransactionInfo>
          <S.DashboardLink
            size={isMobileMode ? 'medium' : 'large'}
            to={path.dashboard.entry()}
          >
            Go to Dashboard
          </S.DashboardLink>
        </S.SuccessScreen>
      )}
    </S.Wrapper>
  )
}
