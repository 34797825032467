import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { InputField, InputSuffix } from 'ui/atoms/input/styled';
import { Input as UiInput, Title, Text } from 'ui/atoms';
import { CopyIcon } from 'ui/molecules/CopyButton';
import { path } from 'libs/path';

import { PromoYour2024Type } from '../api/useGetYour2024';
import { Card } from './Card';
import { useIsMobile } from 'libs/hooks/useIsMobile';


interface Props {
  data: PromoYour2024Type;
}

export const PromoActive: React.FC<Props> = ({ data }) => {
  const [t] = useTranslation();

  const isMobile = useIsMobile();

  return (
    <>
      <div className='flex-1'>
        <Title level={isMobile ? 2 : 1} className='text-center md:text-left w-full'>
          Your 2024 with Headway
        </Title>
        <Text level={isMobile ? 2 : 1} className='md:mt-[24px] mt-[16px] text-center md:text-left' withTranslate={false}>
          <Trans
            i18nKey={'your2024.page.description'}
            t={t}
            default={`This year, you have made huge progress on the way to your dream. Want to know what you were especially good at? Check your result below! Download it before January 10 to share on social media. Don’t forget to tag Headway👑`}
            components={{
              1: (<span className='font-medium text-body.regular'>&nbsp;before January 10</span>),
            }}
          />
        </Text>

        { isMobile && <div className='mt-[24px] flex flex-col items-center rounded-[16px]'>
          <Card
            data={data.card}
          />
        </div>}

        <Title className='md:mt-[48px] mt-[32px] text-center md:text-left w-full' level={isMobile ? 3 : 2}>Get a gift for inviting a friend</Title>
        <Text level={isMobile ? 2 : 1} withTranslate={false} className='md:mt-[16px] text-center md:text-left mt-[12px]'>
          <Trans
            i18nKey={'your2024.page.giftForFriends'}
            t={t}
            default={'Share the joy of trading with your friends – invite them to sign up on Headway by January 20. Use the link below, and for every friend who joins, you’ll get +1 Crown. Track your progress in the Giftshop Activity History.'}
            components={{
              1: (<span className='font-medium text-body.regular'>&nbsp;by January 20</span>),
              2: (
              <a
                className='font-medium text-body.regular text-bronze.500'
                href={`/user${path.giftshop.history()}`}
                rel="noreferrer"
              >
                &nbsp;Giftshop Activity History.
              </a>),
            }}
          />
        </Text>

        <Input
          value={data.refLink}
          className='md:max-w-[400px] h-[48px] w-full md:mt-[16px] mt-[20px]'
          suffix={
            <CopyIcon
            tooltipText='Copied'
            copiedText={data.refLink}
            copyIconClass='text-bronze.500'
          />
          }
        />

        <div className='md:mt-[58px] mt-[20px] flex justify-center md:justify-start'>
          <a
            className='font-medium text-body.regular text-bronze.500'
            href='/year-results-2024-terms/'
            target="_blank"
            rel='noreferrer'
          >
            {t('Terms and conditions')}
          </a>
        </div>
      </div>
      {!isMobile && 
        <div className='flex-1 ml-[20px] flex flex-col items-center bg-gray.300 md:min-h-[701px] rounded-[16px] py-[48px]'>
          <Card
            data={data.card}
          />
        </div>
      }
    </>
  )
}

const Input = styled(UiInput)`
  margin: 16px 0;

  ${InputField} {
    padding-inline-end: 49px;
  }

  ${InputSuffix} {
    pointer-events: initial;
    top: 1px;
    inset-inline-end: 13px;
    padding-inline-start: 8px;
    height: calc(100% - 2px);
    transition: background-color (--transition);
  }
`