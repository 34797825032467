import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CreateDepositResponse } from 'services/payments/mutations/useCreateDeposit';
import { SourceItem } from 'services/payments/get/types';
import { useGetDepositForm } from 'services/payments/get/useGetDepositForm';
import { AccountSelect } from 'pages/money/molecules/newAccountSelect';

import { Alert } from 'ui/molecules/alerts/styled';
import { Button, Icon2, Spinner2, Title, Text } from 'ui/atoms';
import { $destenation, onResetAmountEvent, onResetCalcAmountEvent, setDestenationEvent, updateAmountFieldEvent } from './model';
import { AmountField } from './amount/AmountField';
import { ReciveBlock } from './ReciveBlock';
import { SubmitButton } from './SubmitButton';
import { useTranslation } from 'react-i18next';
import { ApprovePagesCombine } from '../approvePages';
import { Form } from '../../molecules/dynamicForm';
import { DepositBonus } from './bonus';
import { ErrorBoundary } from 'features/errorBoundary';
import { bonusStoreResetEvent, setDepositBonusEvent } from './store/depositBonusStore';
import { Banners } from './banners/Banners';
import { useStoreMap } from 'effector-react';

export type CustomFormDepProps = {
  gatewayId: string;
  destinationList: SourceItem[];
  destination?: SourceItem;
  onSuccessCreateDep: (url: CreateDepositResponse) => void;
  onBack: () => void;
}

export const CustomFormDep = ({ onBack, gatewayId, destinationList, destination, onSuccessCreateDep }: CustomFormDepProps) => {
  const { data, isFetching } = useGetDepositForm(gatewayId);

  const [resp, setResp] = useState<CreateDepositResponse | null>();
  const hasDepositBonus = useStoreMap($destenation, (state) => state?.hasDepositBonus);

  const [t] = useTranslation();
  
  const defaultCurrency = useMemo(() => {
    if (!data?.currencies) return undefined;

    return data.currencies[0];
  }, [data?.currencies]);

  const methods = useForm({
    defaultValues: {},
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const isMaintenance = data?.maintenanceInfo?.isMaintenance;

  const amountPrecisions = useMemo(() => {
    if (!data) return {};

    const precisionsObj: Record<string, number> = {};

    data.fields.some(field => {
      const isAmount = field.type === 'FIELD_TYPE_AMOUNT';

      if (isAmount) {
        field.params?.FIELD_TYPE_AMOUNT?.byCurrencies?.forEach(item => {
          precisionsObj[item.currency] = item.precision;
        });
      }

      return isAmount
    })

    return precisionsObj;
  }, [data]);

  const onChangeDestenation = (val: SourceItem) => {
    setDestenationEvent(val);
    onResetCalcAmountEvent();
    updateAmountFieldEvent({
      activeCurrency: defaultCurrency?.currencyCode as string,
      amount: '',
      errorType: undefined,
    });
    if (val.type !== 'OT_ACCOUNT') setDepositBonusEvent(false);
  };

  const onSuccess = (resp: CreateDepositResponse) => {
    if (resp.requestType === 'RT_CRYPTO') {
      setResp(resp);
      return;
    }

    onSuccessCreateDep(resp);
  }
  
  useEffect(() => {
    return () => {
      setDestenationEvent(null);
      onResetCalcAmountEvent();
      onResetAmountEvent();

      bonusStoreResetEvent();
    }
  }, []);

  if (!isFetching && !data) return (
    <div className='w-full mb-[4rem]'>
      <Button
        name='backButton'
        design='tertiary'
        // className='w-[16rem]'
        onClick={ onBack }
      >
        <Icon2 name='arrowLeft' className='me-3'/>
        <Text>Back</Text>
      </Button>
    </div>
  );  

  if (resp) return (
    <ApprovePagesCombine
      resp={resp}
      depositData={data!}
      onBack={() => setResp(null)}
      onSuccessCreateDep={ onSuccessCreateDep }
    />
  );
  
  return (
    <FormProvider {...methods}>
      <div className='w-full mb-[4rem]'>
        <Button
          name='backButton'
          design='tertiary'
          // className='w-[16rem]'
          onClick={ onBack }
        >
          <Icon2 name='arrowLeft' className='me-3'/>
          <Text level={2} weight='medium'>Back</Text>
        </Button>
        {isFetching
          ? (
            <div className='w-full flex justify-center'>
              <Spinner2 size='large'/>
            </div>
          ) : (
            <>
              <div className='flex justify-center w-full items-center flex-col gap-[24px]'>
                {data?.maintenanceInfo?.maintenanceMessage && (
                  <Alert type='pending' className='lg:w-[520px] w-full'>
                    { data.maintenanceInfo.maintenanceMessage }
                  </Alert>)
                }
                <img src={data?.gatewayLogo} alt='gateway_logo' className='w-[64px] h-[64px] rounded-full' />
                <Title level={3}>{data?.gatewayName}</Title>
              </div>

              <Banners />

              <div className='md:mt-[24px] mt-[32px] w-full flex flex-col items-center'>
                <AccountSelect
                  onSelect={onChangeDestenation}
                  classes='lg:w-[440px] w-full !mt-[0px]'
                  data={destinationList}
                  defaultValue={ destination?.id as string  }
                  isDisabled={ isMaintenance }
                />

                <AmountField
                  defaultCurrency={defaultCurrency}
                  gatewayId={ gatewayId }
                  isDisabled={ isMaintenance }
                  amountPrecisions={ amountPrecisions }
                />

                {hasDepositBonus && 
                  <ErrorBoundary fallback={<></>}>
                    <DepositBonus />
                  </ErrorBoundary>
                }
                
                <Form formFields={data?.fields} />
              </div>

              <div className='mt-[48px]'>
                <div className='flex justify-center'>
                  <Icon2 name='checkCircle' className='mr-[12px] text-eucalyptus.500' />
                  <Text weight='medium' className='!text-gray.600'>{`${data?.commissionString}`}</Text>
                </div>

                <div className='flex justify-center mt-[16px]'>
                  <Icon2 name='clockSolid' className='mr-[12px] text-eucalyptus.500' />
                  <Text weight='medium' className='!text-gray.600'>
                    {t('money.customForm.processing', { processingTime: data?.processingTime || '1 min' } )}
                  </Text>
                </div>
              </div>

              <ReciveBlock defaultCurrency={ defaultCurrency!.currencyCode } />
              <SubmitButton onSuccessCreateDep={ onSuccess } isDisabled={ isMaintenance }/>
            </>
          )}
      </div>
    </FormProvider>
  )
}
