import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { WinnersItem } from 'services/ramadan/types'
import { Button, Text } from 'ui/atoms'
import { Modal } from 'ui/molecules/modal_draft'

import { DayCardCategory } from './DayCardCategory'
import * as S from './styled'
import { TicketsModal } from './TicketsModal'

interface Props {
  winner: WinnersItem
}
export const DayCard: React.FC<Props> = ({ winner }) => {
  const [t] = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const isMultipleOf5 = +winner.day.dayID % 5 === 0

  return (
    <S.DayCards
      isFriday={isMultipleOf5}
      isYou={winner.lucky?.isYou || winner.bestTrader?.isYou}
    >
      <Text
        withTranslate={false}
        level={1}
        weight="medium"
        className={`
          text-center
          ${isMultipleOf5 ? '!text-white' : ''}`}
      >
        <Trans i18nKey="Day 1" values={{ value: winner.day.dayID }} />
      </Text>

      <div className="mt-[16px] grow rounded-[8px] bg-white py-[16px] px-[12px]">
        <DayCardCategory
          category={winner.bestTrader}
          titleLabel="Trader of the day"
          footerLabel={t('Lots')}
          footerValue={winner.bestTrader.lots}
        />

        <div className="my-[24px] border-t-[1px] border-gray.300" />

        <DayCardCategory
          category={winner.lucky}
          titleLabel="Lucky winner"
          footerLabel={t('Ticket')}
          footerValue={winner.lucky?.ticketID}
        />
      </div>

      <div className="mt-[16px] flex gap-[16px] w-full">
        <Button
          design="secondary"
          className="w-full"
          href={winner.RuffleResultsLink}
          target="_blank"
          size="small"
        >
          Raffle results
        </Button>
        <Button
          design="secondary"
          size="small"
          className="w-full"
          onClick={() => setIsModalOpen(true)}
        >
          My tickets
        </Button>
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="My ticket numbers"
        width={600}
        footer={false}
        isScrollContent
        titleClasses="text-center"
      >
        <TicketsModal dayID={winner.day.dayID} isOpen={isModalOpen} />
      </Modal>
    </S.DayCards>
  )
}
