import { useUnit } from 'effector-react';
import React from 'react';

import { Money, Text, Title } from 'ui/atoms';
import { delEmptyNumFloat } from 'ui/molecules/hooksForm/FormInput';
import { useGetReceiveAmount } from './utils/useGetReciveAmount';
import { $bonusDeposit, $bonusDepositData } from './store/depositBonusStore';
import { useIsMobile } from 'libs/hooks/useIsMobile';

type Props = {
  defaultCurrency: string;
}

export const ReciveBlock = ({ defaultCurrency }: Props) => {
  const { receiveAmount, receiveCurrency} = useGetReceiveAmount();

  const isMobile = useIsMobile();

  const bonus = useUnit($bonusDepositData);
  const isActiveBonus = useUnit($bonusDeposit);

  // const isOneCurrency = destenationCurrency === defaultCurrency;

  return (
    <div className='mt-[44px] flex flex-col items-center'>
      <Text weight='medium' level={2}  className='!text-gray.600'>money.customForm.willReceive</Text>
      <div className='flex items-end mt-[12px]'>
        <Title withTranslate={ false } level={1} className='!text-eucalyptus.600 mr-[8px]'>{delEmptyNumFloat(receiveAmount) || '0.00'}</Title>
        <Text withTranslate={ false } weight='medium' className='mb-[4px] !text-gray.600'>
          { receiveCurrency }
        </Text>
      </div>

      {isActiveBonus && 
        <div className='flex gap-[12px] mt-[12px] items-center'>
          <Text withTranslate={false} level={ isMobile ? 3 : 2} color='subtext' weight='medium'>
            {`+${bonus?.bonusPercent}% bonus`}
          </Text>
          <Text withTranslate={false} level={2} weight='medium'>
            <Money currency={receiveCurrency} value={Number(bonus?.bonusAmount ?? 0)}/>
          </Text>
        </div>
      }
    </div>
  )
}
