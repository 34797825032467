
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { addDays, endOfDay, startOfDay, format } from 'date-fns';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'libs/hooks/useIsMobile';
import { toUnixTimeString } from 'libs/dateUtils';
import { Button, DatePicker, Select } from 'ui/atoms';

import {
  setStatusesEvent,
  Statuses,
  setTimelineEvent,
  setTransactionsTypeEvent,
  $moneyHistoryFilter,
  dropFilterMoneyHistoryEvent,
} from 'pages/money/transactionsNew/model/moneyHistoryModel';

import * as Styled from './styled';
import { AccountsSelect } from './AccountsSelect';
import { TransactionFilter } from 'services/money/get';
import { selectCloseMenuEvent } from 'ui/atoms/select';

type Props = {
  type?: 'exchanger';
  data: TransactionFilter;
}

export const Filters: React.FC<Props> = ({ type, data }) => {
  const isMobile = useIsMobile();
  const isExchanger = type === 'exchanger';

  const [t] = useTranslation();

  const { transactionType, statuses } = useUnit($moneyHistoryFilter);

  const [dateSelectValue, setDateSelectValue] = React.useState('');
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates

    setStartDate(start)
    setEndDate(end)
  }

  const changeDates = () => {
    selectCloseMenuEvent();
    if (startDate && endDate) {
      setDateSelectValue('custom');
      const to = format(endDate!, 'dd/MM/yyyy')
      const from = format(startDate!, 'dd/MM/yyyy')

      setTimelineEvent({
        from,
        to,
      })

      return;
    }
  }

  const resetDates = () => {
    setStartDate(null)
    setEndDate(null)
    setDateSelectValue('all');
    selectCloseMenuEvent();
  }

  const transactionsTypes = useMemo(() => {
    if (!data?.transactionTypes?.length) return [];

    const arr = [{
      label: t('All types'),
      value: 'all'
    }];

    data.transactionTypes.forEach((item) => {
      arr.push({
        label: t(item.name),
        value: item.value,
      })
    });

    return arr;
  }, []);

  const transactisStatuses = useMemo(() => {
    if (!data?.statuses?.length) return [];

    const arr = [{ label: t('Any status'), value: 'all' }];

    data.statuses.forEach((item) => {
      arr.push({
        label: t(item.name),
        value: item.value,
      })
    });

    return arr;
  }, []);

  React.useEffect(() => {
    // todo: перенести в хук @_@
    if (dateSelectValue) {
      if (dateSelectValue !== 'custom') {
        setStartDate(null)
        setEndDate(null)
      }

      if (dateSelectValue === 'custom') {
        return;
      }

      let from = dateSelectValue === 'all' ? '0' : format(addDays(new Date(), -dateSelectValue), 'dd/MM/yyyy');
      const to = format(new Date(), 'dd/MM/yyyy');

      // if (dateSelectValue !== 'all') {
      //   from = dateSelectValue === 'custom'
      //     ? format(startDate!, 'dd/MM/yyyy')
      //     : format(addDays(new Date(), -dateSelectValue), 'dd/MM/yyyy');
      // }

      setTimelineEvent({
        from,
        to,
      })
    }
  }, [dateSelectValue]);

  useEffect(() => {
    const eventWatcher = dropFilterMoneyHistoryEvent.watch(() => {
      setDateSelectValue('');
    });
  
    return () => {
      eventWatcher.unsubscribe();
    }
  }, [])

  return (
    <Styled.Wrapper>
      <Select
        placeholder="Transaction Type"
        value={ transactionType }
        autoComplete="off"
        options={transactionsTypes}
        onSelect={(setTransactionsTypeEvent as (val: string) => void)}
        readOnly
        isHaveSearch={false}
      />
      <Select
        value={dateSelectValue}
        placeholder="Timeframe"
        autoComplete="off"
        options={[
          { label: t('All time'), value: 'all' },
          { label: t('Last week'), value: '7' },
          { label: t('Last month'), value: '30' },
          { label: t('Last 3 months'), value: '90' },
          { label: t('Custom date'), value: 'custom', hidden: true },
        ]}
        onSelect={setDateSelectValue}
        optionsWidth={isMobile ? undefined : 375}
        readOnly
        // @ts-ignore
        // prefix={<Icon2 name='calendar' size='base' />}
        isHaveSearch={false}
        maxHeight={690}
        customContent={
          <Styled.DatePickerWrapper>
            <DatePicker
              selected={startDate}
              // @ts-expect-error
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              // @ts-expect-error
              selectsRange
            />
            {startDate && endDate && (
              <Styled.PickerButtons>
                <Button
                  name="cancel"
                  size="medium"
                  design="tertiary"
                  onClick={resetDates}
                >
                  Cancel
                </Button>
                <Button name="cancel" size="medium" onClick={changeDates}
                >
                  Select
                </Button>
              </Styled.PickerButtons>
            )}
          </Styled.DatePickerWrapper>
        }
      />

      {!isExchanger && 
        <AccountsSelect wallets={data.wallets} accounts={data.accounts} exchangerWallets={data.exchangerWallets} />
      }

      <Select
        placeholder="Status"
        autoComplete="off"
        inputClasses='lg:!w-auto !min-w-[110px]'
        value={ statuses }
        readOnly
        isHaveSearch={false}
        onSelect={(val) => setStatusesEvent(val as Statuses | 'all')}
        options={transactisStatuses}
      />
    </Styled.Wrapper>
  )
}
