import { getDateFromUnix } from 'libs/dateUtils'
import { path } from 'libs/path'
import React from 'react'
import { Trans } from 'react-i18next'
import { Button, Link, Text, Title } from 'ui/atoms'
import { Progress } from 'ui/molecules'

import * as S from './styled'

interface Props {
  startDate: string
  endDate: string
  collectedAmount: string
  progressBarPercentage: string
  hideButton?: boolean
  isEnded?: boolean
}

export const CharityProgress: React.FC<Props> = ({
  startDate = 'March 3',
  endDate = 'March 28',
  collectedAmount,
  progressBarPercentage,
  isEnded,
  hideButton,
}) => {
  const startLabel =
    startDate && !isEnded && getDateFromUnix(+startDate, 'MMMM d')
  const endLabel = endDate && !isEnded && getDateFromUnix(+endDate, 'MMMM d')

  if (isEnded) {
    return (
      <S.CharityWrapper>
        <div className="md:w-[600px] w-full">
          <Title level={2} className="text-center mb-[16px]">
            Headway Charity
          </Title>
          <Text color="subtext" className="text-center">
            During this month of Ramadan, we have collected:
          </Text>
        </div>

        <Title
          level={1}
          withTranslate={false}
          className="text-center mt-[24px] !text-eucalyptus.600"
        >
          ${collectedAmount}
        </Title>

        <div className="flex flex-col mt-[24px] items-center lg:max-w-[680px]">
          <Text level={1} color="subtext" className="text-center">
            These funds will be shortly donated to the charitable organizations.
            Stay tuned on our social media to learn more about our collective
            help to the local communities.
          </Text>
          <Text color="subtext" level={1} className=" mt-[10px]">
            Thank you, traders!
          </Text>
        </div>
      </S.CharityWrapper>
    )
  }

  return (
    <S.CharityWrapper>
      <div className="md:w-[600px] w-full">
        <Title level={2} className="text-center mb-[16px]">
          Headway Charity
        </Title>
        <Text color="subtext" className="text-center">
          1 traded lot = $1 for charitable organizations.
        </Text>
      </div>

      <div className="md:w-[600px] w-full mt-[24px]">
        <div className="flex justify-between mb-[8px]">
          <Text color="subtext" level={3}>
            {startLabel}
          </Text>
          <Text color="subtext" level={3}>
            {endLabel}
          </Text>
        </div>
        <Progress
          progress={+progressBarPercentage}
          progressClasses="!bg-eucalyptus.500 !rounded-full !h-[16px]"
          wrapClasses="!bg-eucalyptus.100 !rounded-full !h-[16px]"
        />

        <div className="w-full flex gap-[8px] justify-center items-center md:items-end mt-[24px] md:flex-row flex-col text-body.medium leading-body.medium">
          <Trans
            i18nKey="$12,341.63 collected for good deeds"
            values={{ value: `$${collectedAmount}` }}
            // @ts-expect-error
            components={[<Title level={1} withTranslate={false} className='mb-[-2px]' />]}
          />
        </div>
      </div>

      {!hideButton && (
        <Link to={path.ramadan.results()} className="w-full md:w-[192px]">
          <Button
            className="mt-[24px] !h-[32px] md:w-[192px] w-full"
            design="secondary"
          >
            <Text level={2} weight="medium">
              Learn more
            </Text>
          </Button>
        </Link>
      )}
    </S.CharityWrapper>
  )
}
