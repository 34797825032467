import styled from 'styled-components'

import icon from './icon.svg'

export const Button = styled.button`
  width: 28px;
  height: 28px;
  background-image: url(${icon});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`
