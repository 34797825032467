import styled, { css } from 'styled-components/macro'

export const ContentContainer = styled.div`
  padding-bottom: 56px;
  background-color: var(--color-gray-100);
  border-radius: 8px;
  box-shadow: 0px 2px 4px -1px #2621190f, 0px 4px 6px -1px #2621191a;

  @media (max-width: 599px) {
    padding-bottom: 16px;
  }
`

export const ContestBody = styled.div`
  margin: 0 auto;
  padding-top: 48px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 920px;
  width: 100%;

  @media (max-width: 599px) {
    padding-top: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
`

export const Prizes = styled.div`
  display: grid;
  grid-template-columns: 500fr 360fr;
  gap: 20px;

  @media (max-width: 1280px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`