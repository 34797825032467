import React, { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { path } from 'libs/path';
import { Text, Icon2, Title, Input, Button } from 'ui/atoms';
import { CopyIcon } from 'ui/molecules/CopyButton/CopyIcon';

export type BtcPayload = {
  qr: string;
  currency: string;
  amount: string
}

type Props = {
  payload: BtcPayload;
  address: string;
  transactionID: string;
}

type CopiedVariants = 'address' | 'amount';

export const BtcForm: React.FC<Props> = ({ payload, address, transactionID }) => {
  const [copied, setCopied] = useState<CopiedVariants | undefined>();

  const navigate = useNavigate();
  const [t] = useTranslation();

  const TooltipBody = forwardRef((props, ref) => {
    return <Icon2 iconRef={ref} size='small' name='check' className='text-eucalyptus.600 ml-[0.8rem]' />;
  });

  const text = `${payload.amount} ${payload.currency}`;

  const onMakeDep = () => {
    navigate({
      pathname: `/${path.money.resultDeposit()}`,
      search: `?status=pending&amount=${payload?.amount}&id=${transactionID}&currency=${payload?.currency}&isResultAmount=true`
    });
  }

  return (
    <div className='w-full lg:mt-[2rem] mt-[1.6rem]'>
      <div className='flex justify-center w-full items-center gap-[8px] flex-wrap'>
        <Title level={2} className='h-full min-h-[4rem]'>
          common.send
        </Title>

        <div className='min-h-[4rem] flex items-baseline border-b-[1px] border-sunray.500'>
          <Title 
            level={2}
            withTranslate={false}
            className='h-full'
          >
            { text }
          </Title>
          <CopyIcon
            tooltipText='Copied'
            copiedText={text}
            className='ms-[8px]'
            iconSize='small'
            copyIconClass='text-bronze.500'
            onCopied={() => setCopied('amount')}
          />
        </div>

        <Title level={2} className='h-full min-h-[4rem]'>
          money.coinpayment.address
        </Title>
      </div>

      <div className='lg:mt-[3.2rem] mt-[16px] flex flex-col items-center w-full'>
        <Input
          disabled
          value={ address }
          suffixType='button'
          className=' lg:!w-[45rem] lg:h-[5.2rem] !w-full'
          inputClasses='lg:h-[5.2rem] !pr-[45px]'
          suffix={
            <CopyIcon
              tooltipText='Copied'
              copiedText={address}
              onCopied={() => setCopied('address')}
            />
          }
        />

        <div className='flex items-center mt-[6px]'>
          <Icon2 name='exclamationCircle' className='mr-[8px] text-opal.500'/>
          <Text level={3}>money.coinpayment.helper</Text>
        </div>
      </div>

      <div className='flex justify-center w-full items-center flex-col mt-[3.2rem]'>
        <img src={payload?.qr} alt='qr-payment' className='lg:w-[18.8rem] lg:h-[18.8rem] w-[14.4rem] h-[14.4rem]' />
      </div>

      <div className='lg:mt-[4rem] mt-[2.4rem]'>
        <div className='flex justify-center'>
          <Icon2 name='checkCircle' className='me-[12px] text-eucalyptus.500' />
          <Text weight='medium' className='!text-gray.600'>money.coinpayment.commission</Text>
        </div>

        <div className='flex justify-center mt-[16px]'>
          <Icon2 name='clock' className='text-gray.600 me-[12px]' />
          <Text weight='medium' className='!text-gray.600'>
            money.coinpayment.processing
          </Text>
        </div>
      </div>

      <div className='flex mt-[4rem] justify-center'>
        <Button
          name='madePayment'
          className='lg:min-w-[24rem] w-full lg:w-auto'
          onClick={onMakeDep}
        >
          {t('money.coinpayment.made-payment')}
        </Button>
      </div>
    </div>
  )
}
