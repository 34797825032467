import * as React from 'react'
import styled from 'styled-components/macro'

import { path } from '../../../libs/path'
import { Link } from '../../../ui/atoms'
import logoImage from './logo.svg'

type Props = {
  size?: 'small' | 'medium' | 'large'
}

export const Logo: React.FC<Props> = ({ size = 'medium' }) => {
  return (
    <LogoWrap to={path.root()}>
      <LogoImage size={size} src={logoImage} alt="Headway" />
    </LogoWrap>
  )
}

const map = ({ size }: Props) => ({
  'data-size': size,
})

const LogoWrap = styled(Link)`
  display: flex;
  align-items: center;
`

const LogoImage = styled.img.attrs(map)<Props>`
  height: auto;

  &[data-size='small'] {
    width: 121px;
  }

  &[data-size='medium'] {
    width: 151px;
  }

  &[data-size='large'] {
    width: 218px;
  }

  &:hover {
    opacity: 0.75;
  }
`
