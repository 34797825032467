import styled, { css } from 'styled-components'
import { Title as UiTitle } from 'ui/atoms'

export const Container = styled.div`
  margin: 56px 0;
  display: grid;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 599px) {
    margin: 40px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Title = styled(UiTitle)`
  margin-bottom: 16px;
  text-align: center;
`

export const TableHead = styled.div`
  border-top: 1px solid var(--color-gray-300);
  border-left: 1px solid var(--color-gray-300);
  border-right: 1px solid var(--color-gray-300);
  height: 48px;
  display: grid;
  grid-template-columns: 80px 1fr 170px;
  align-items: center;
  text-align: center;
  color: var(--color-gray-600);
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

export const TableRow = styled.div<{ selected: boolean }>`
  border: 1px solid var(--color-gray-300);
  border-top: none;
  min-height: 88px;
  display: grid;
  grid-template-columns: 80px 1fr 170px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);

  @media (max-width: 599px) {
    padding: 15px 12px;
    grid-template-columns: 36px 180px 1fr;
    gap: 4px;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  &:first-child {
    border-top: 1px solid var(--color-gray-300);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    @media (max-width: 599px) {
      border-top: none;
    }
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: var(--color-sunray-100);

      @media (max-width: 599px) {
        border: none;
      }
    `}
`

export const PlaceWrapper = styled.div`
  display: grid;
  place-items: center;

  @media (max-width: 599px) {
    justify-items: start;
  }

  img {
    width: 48px;
    height: auto;

    @media (max-width: 599px) {
      min-width: 48px;
      max-width: 48px;
      margin-top: 6px;
      margin-inline-start: -8px;
    }
  }
`

export const NameWrapper = styled.div`
  padding-top: 7px;
  padding-bottom: 7px;
  padding-inline-start: 16px;
  display: grid;
  gap: 8px;
  align-content: center;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 500;

  @media (max-width: 599px) {
    padding-bottom: 6px;
    padding-inline-start: 0;
    gap: 4px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const RegularCell = styled.div`
  display: grid;
  place-items: center;

  @media (max-width: 599px) {
    place-items: end;
    align-content: start;
    line-height: 32px;
  }
`

export const CellTitle = styled.div`
  margin-bottom: 4px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-gray-600);
  font-weight: normal;
`

export const TopTraders = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-regular);
  color: var(--color-sunray-700);
  font-weight: 500;
`
