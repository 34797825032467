import { path } from 'libs/path'
import { useTranslation } from 'react-i18next'
import { Button, Title } from 'ui/atoms'

export const Terms = () => {
  const [t] = useTranslation()

  return (
    <div className="grid gap-[16px] place-items-center">
      <Title level={2} className="text-center mobile:mb-[8px]">
        Terms
      </Title>
      <div className="max-w-[800px] text-center text-body.regular leading-body.regular">
        {t(
          'Make any deposit and trade on any of your real accounts. Every day the leaderboard restarts. Each 0.1 lot brings you a raffle ticket. At the end of the trading day, two traders receive a prize: 1st – for the most traded lots, 2nd – lucky winner in the raffle.',
        )}
      </div>
      <Button
        design="tertiary-brand"
        size="small"
        href={path.ramadan.terms()}
        target="_blank"
      >
        Terms and conditions
      </Button>
    </div>
  )
}
