import styled, { css } from 'styled-components'

import backgroundLeft from './background-left.webp'
import backgroundMobile from './background-mobile.webp'
import backgroundRight from './background-right.webp'

export const Wrapper = styled.div<{ colored?: boolean }>`
  padding: 32px 130px;
  min-height: 160px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  text-align: center;
  background-color: var(--color-gray-200);
  background-image: url(${backgroundLeft}), url(${backgroundRight});
  background-repeat: no-repeat;
  background-position: left center, right center;
  background-size: contain;
  border-radius: 8px;

  @media (max-width: 599px) {
    padding: 32px 24px;
    height: 280px;
    background-image: url(${backgroundMobile});
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: bottom center;
  }

  ${({ colored }) =>
    colored &&
    css`
      min-height: auto;
      display: grid;
      justify-items: center;
      background-color: #009000;

      @media (max-width: 599px) {
        padding: 32px 24px 80px 24px;
        height: auto;
        background-color: var(--color-gray-200);
      }
    `}
`
