import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useWindowSize } from 'usehooks-ts';
import { addDays, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { toUnixTimeString } from 'libs/dateUtils';
import { Button, DatePicker, Icon2 } from 'ui/atoms';
import { BottomSheet } from 'ui/molecules';

import { periods } from './utils';

import * as S from './styled';

export type SetTimePropType = {
  from: string;
  to: string;
}

export type DatePeriods = 'all' | 'custom' | string;
export type CalendarPostion = 'left' | 'right';

interface Props {
  className?: string;

  position?: CalendarPostion;
  label?: string;
  values?: SetTimePropType & {
    period?: string;
    from: Date;
    to: Date;
  };
  hideOptions?: boolean;
  setTimeline: (e: SetTimePropType,  period?: DatePeriods) => void;
  customMapperDates?: (val: Date) => string;
}

type OptionItem = {
  label: string;
  value: string;
  hidden?: boolean;
}

export const DateRangeIcon: React.FC<Props> = ({ 
  setTimeline, customMapperDates, className, label, values,
  hideOptions,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dateSelectValue, setDateSelectValue] = useState(values?.period || '');
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { width } = useWindowSize();
  const isMobileMode = width < 600;

  const [t] = useTranslation();

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
    setDateSelectValue('all');
    setIsOpenModal(false);
  };

  // Принимает параметры startDateValue и endDateValue, чтобы не указывать их в зависимости в кэшировании
  const getRangeDate = useCallback((startDateValue: Date | null, endDateValue: Date | null) => {
    if (!startDateValue || !endDateValue) return '';
  
    return `${format(startDateValue, 'dd/MM/yyyy')}-${format(endDateValue, 'dd/MM/yyyy')}`
  }, []);

  const getOptions = useMemo((): OptionItem[] => {
    const customPeriodValue = {
      label: 'Custom date',
      value: 'custom',
      hidden: true
    };

    const isPreseteriod = periods.some(val => val.value === dateSelectValue);

    if (!isPreseteriod && startDate && endDate) {
      const customValue = getRangeDate(startDate, endDate);
      customPeriodValue.label = customValue;
      customPeriodValue.value = customValue;
    }

    if (hideOptions) return [customPeriodValue];

    const options = [...periods, customPeriodValue];
    return options;
  }, [dateSelectValue, endDate, isMobileMode, startDate, hideOptions]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates

    setStartDate(start)
    setEndDate(end)
  }

  const changeDates = () => {
    if (!startDate || !endDate) return;

    const selectValue = getRangeDate(startDate, endDate);

    setDateSelectValue(selectValue);
    const mapper = customMapperDates || toUnixTimeString;
    
    setTimeline({
      from: mapper(startDate as Date),
      to: mapper(endDate as Date)
    }, selectValue);
    setIsOpenModal(false);
  }

  const onSelectRange = (period: string) => {
    if (!period) return;
    
    setStartDate(null);
    setEndDate(null);
    setDateSelectValue(period);

    const mapper = customMapperDates || toUnixTimeString;

    setTimeline({
      from: period === 'all' ? '0' : mapper(addDays(new Date(), -period)),
      to: mapper(new Date()),
    }, period);
  }

  useEffect(() => {
    if (!values) return;

    const isPreseteriod = periods.some(val => val.value === dateSelectValue);
    
    setDateSelectValue(values?.period || '');
    if (isPreseteriod) {
      return;
    }
    setStartDate(values.from);
    setEndDate(values.to);
  }, [values]);

  return (
    <>
    <Icon2 name='calendar'
      onClick={() => setIsOpenModal(true)}
      className={className} 
    />
    <BottomSheet
      isOpen={isOpenModal}
      title={label}
      contentClasses='!max-h-[85vh]'
      onClose={() => {
        setIsOpenModal(false);
      }}
      >
        {
          getOptions.map(({ value, label, hidden }, key) => (
            <Option
              key={key}
              onClick={() => {
                // setActiveValue(value)
                setIsOpenModal(false);
                onSelectRange(value)
              }}
              hidden={hidden}
            >
              {t(label)}
            </Option>
          ))
         }
          <S.DatePickerWrapper hideTopline={hideOptions}>
            <DatePicker
              selected={startDate}
              // @ts-expect-error
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              // @ts-expect-error
              selectsRange
            />
            {startDate && endDate && (
              <S.PickerButtons>
                <Button
                  name="cancel"
                  size="medium"
                  design="tertiary"
                  onClick={resetDates}
                >
                  Cancel
                </Button>
                <Button name="cancel" size="medium" onClick={changeDates}>
                  Select
                </Button>
              </S.PickerButtons>
            )}
          </S.DatePickerWrapper>
      </BottomSheet>
    </>
  )
}

const Option = styled.div`
  padding: 9px 16px;
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-200);
  }
`