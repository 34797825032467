import { format } from 'date-fns'
import i18next from 'i18next'
import { localeMap } from 'libs/dateUtils'
import { transactionIcons } from 'pages/money/transactionsNew'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ZendeskAPI } from 'react-zendesk'
import { TransactionItem } from 'services/money/get'
import { Button, Text, TipyTooltip } from 'ui/atoms'
import { useWindowSize } from 'usehooks-ts'

import * as Styled from './styled'
import { TransactionDestop } from './TransactionDestop'
import { TransactionMobile } from './TransactionMobile'

const locale = localeMap[i18next.language]

const formatDate = (date: string) =>
  format(new Date(+date * 1000), 'd LLL yyyy, hh:mm aaa', {
    locale,
  })

export const Transaction = ({ data }: { data: TransactionItem }) => {
  const [t] = useTranslation()

  const [isOpened, setIsOpened] = useState(false)

  const { width } = useWindowSize()
  const isMobileMode = width < 1200

  const handleCopy = () => {
    navigator.clipboard.writeText(data.detailsForCopy)
  }

  const handleContactSupport = () => {
    ZendeskAPI('messenger', 'open')
  }
  
  return (
    <Styled.Wrapper>
      <Styled.VisibleInfo
        opened={isOpened}
        onClick={() => setIsOpened(!isOpened)}
      >
        <Styled.LeftIcon src={transactionIcons[data.direction]} />
        { isMobileMode ? <TransactionMobile data={data} /> : <TransactionDestop data={data} /> }
      </Styled.VisibleInfo>
      {isOpened ? (
        <Styled.HiddenInfo>
          <Styled.DetailsGrid>
            <Details title="Created" value={formatDate(data.createdTs)} />
            <Details
              title="From"
              value={data.srcName}
            />
            <Details
              title="To"
              value={data.dstName}
            />
            <Details
              title="Type"
              value={t(data.type)}
            />
            <Details
              title="Transaction status"
              value={
                <Styled.Status status={data.status.level}>
                  {t(data.status.name)}
                </Styled.Status>
              }
            />
            <Details
              title="Status updated"
              value={formatDate(data.updatedTs)}
            />
            <Details title="Transaction ID" value={data.transactionID} />
            { data?.note && (
              <Details
                title="Note"
                value={
                  <Text level={ isMobileMode ? 3 : 2} className='lg:max-w-[400px] max-w-[210px] break-words'>
                    {data.note}
                  </Text>
                }
              />) 
            }
          </Styled.DetailsGrid>
          {data.infoPanel && data.infoPanel.level === 'LEVEL_ERROR' ? (
            <Styled.Warning error>
              {data.infoPanel.text}
            </Styled.Warning>
          ) : null}
          <Styled.Buttons>
          {data.detailsForCopy && 
            <TipyTooltip
              content={
                <div>
                  <Text weight='medium' level={3}>{'Copied'}</Text>
                </div>
              }
              trigger='click'
              >
                <Button
                  size="small"
                  name="repeat"
                  design="secondary"
                  onClick={handleCopy}
                >
                  Copy details
                </Button>
              </TipyTooltip>
            }
            {data.actions.includes('ACTION_CONTACT_CUSTOMER_CARE') && (
              <Button size="small" name="repeat" onClick={handleContactSupport}>
                Contact support
              </Button>
            )}
          </Styled.Buttons>
        </Styled.HiddenInfo>
      ) : null}
    </Styled.Wrapper>
  )
}

const Details = ({
  title,
  value,
}: {
  title: string
  value: React.ReactNode
}) => {
  const [t] = useTranslation()

  return (
    <Styled.DetailsRow>
      <Styled.DetailsTitle>{t(title)}</Styled.DetailsTitle>
      <Styled.DetailsValue>{value}</Styled.DetailsValue>
    </Styled.DetailsRow>
  )
}
