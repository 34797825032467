import { useQuery } from '@tanstack/react-query';

import { MoneyHistoryFilterStore, Statuses } from 'pages/money/transactionsNew/model';

import { axios } from 'services/axios';
import { mapFilterToReqData } from './mapper';
import { TransactionResponse } from './typesTransactionNew';

export const GET_TRANSACTION_KEY = 'GET_TRANSACTION_KEY';


type Options<T> = ReqOptions & {
  onSelect?: (data: TransactionResponse) => T;
}

type ReqOptions = {
  filter: MoneyHistoryFilterStore;
  page: number;
  perPage?: number;
}


const getTransactions = async ({ filter, page, perPage }: ReqOptions) => {
  const reqData = mapFilterToReqData(filter);

  const resp = await axios.post<TransactionResponse>(`money-history/v1/transactions`, {
    page,
    perPage,
    filter: reqData,
  });

  return resp.data;
}

export const useGetTransactions = <T=TransactionResponse>({ filter, page = 1, perPage = 20, onSelect }: Options<T>) => {
  return useQuery({
    // @ts-ignore
    queryKey: [GET_TRANSACTION_KEY, page, perPage, filter],
    queryFn: () => getTransactions({ filter, page, perPage }),
    staleTime: 200,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    select: onSelect,
  })
}
