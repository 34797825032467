import React, { useState, useMemo } from 'react';
import { isAxiosError } from 'axios';

import { ActivatePromocodeErrorResp, useActiveDepositPromocode } from 'services/money/mutations';
import { Modal } from 'ui/molecules/modal_draft'
import { Icon2, Input } from 'ui/atoms';

import { setBonusPromocodeEvent } from '../store/depositBonusStore';

interface Props {
  closeModal: () => void;
  onSuccessModal: () => void;
}


export const PromoCodeModal: React.FC<Props> = ({ closeModal, onSuccessModal }) => {
  const [code, setCode] = useState('');

  const { mutateAsync, error, isLoading, reset } = useActiveDepositPromocode();

  const onChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    reset();
    setCode(e.target.value || '');
  }

  const onDropModal = () => {
    if (isLoading) return;
    closeModal();
  }

  const onDropInput = () => {
    setCode('')
    reset();
  }

  const onActiveCode = async () => {
    try {
      if (!code || isLoading) return;

      await mutateAsync({
        code,
      });

      setBonusPromocodeEvent(code);
      onSuccessModal?.();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  }

  const errorMessage = useMemo((): string | undefined => {
    if (!error) return;
    if (isAxiosError(error)) {
      const errorData = error.response?.data as ActivatePromocodeErrorResp;
      return (errorData?.details?.validationErrors?.items?.[0].msg);
    }

    return 'Internal server error';

  }, [error]);

  return (
    <Modal
      title="Use promo code"
      cancelLabel='Cancel'
      okLabel='Activate'
      onCancel={onDropModal}
      onOk={onActiveCode}
      open={true}
      onClose={onDropModal}
      okButtonProps={{
        disabled: isLoading,
      }}
    >
      <div className='w-full'>
        <Input
          name="code"
          placeholder="Enter promo code"
          value={code}
          maxLength={100}
          errorMessage={errorMessage}
          autoFocus
          inputClasses='!pr-[40px]'
          suffix={code && <Icon2 name='xMarkIcon' onClick={onDropInput} className='cursor-pointer !text-gray.600' />}
          suffixType='button'
          onChange={onChangeCode}
        />
      </div>
    </Modal>
  )
}
