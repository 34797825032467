
import React, { useEffect, useRef } from 'react';
import { useUnit } from 'effector-react';

import { Toggle } from 'ui/atoms';

import { $bonusDeposit, setDepositBonusEvent } from '../store/depositBonusStore';

interface Props {
  defaultState?: boolean;
}

export const BonusToggle: React.FC<Props> = ({ defaultState }) => {
  const checked = useUnit($bonusDeposit);
  const isSetDefault = useRef(false);

  useEffect(() => {
    if (isSetDefault.current) return;
  
    if (typeof defaultState === 'boolean') {
      isSetDefault.current = true;
      setDepositBonusEvent(defaultState);
    }
  }, [defaultState])
  

  return (
    <Toggle
      size="large"
      checked={checked}
      // disabled={!userID}
      onChange={() => setDepositBonusEvent(!checked)}
  />
  )
}
