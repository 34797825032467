import { Header } from 'pages/contests/contestDetails/styled'
import styled from 'styled-components/macro'

export const Wrapper = styled(Header)`
  background-color: #009000;

  @media (max-width: 599px) {
    background-color: var(--color-gray-200);
  }
`
