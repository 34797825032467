import React, { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isAxiosError } from 'axios';

import { useGetHeadBanner } from 'services/banners/get/useGetHeadBanner';
import { useActiveDepositPromocode } from 'services/money/mutations';
import { TOAST_60_SEC_TIME } from 'ui/molecules/toasts/ToastItem';
import { setToastEvent } from 'ui/molecules/toasts/useToasts';
import { Text } from 'ui/atoms';

import { ResponseError } from 'libs/request';
import { path } from 'libs/path';

import { Individual } from './banners/Individual';
import { Regular } from './banners/Regular';
import { RegBonus } from './banners/RegBonus';
import { useTranslation } from 'react-i18next';

interface Props {
  onBannerShowed: (val: boolean) => void;
}

export const HeadBanners: React.FC<Props> = ({ onBannerShowed }) => {
  const { data: headBanner, refetch } = useGetHeadBanner({ enabled: true });

  const [t] = useTranslation();

  const nav = useNavigate();
  const { mutateAsync } = useActiveDepositPromocode();

  let [searchParams, setSearchParams] = useSearchParams();

  const bannerComp = useMemo(() => {
    if (!headBanner) return null;
    if (headBanner.name === 'depositBonus.individual') return <Individual banner={headBanner} />
    if (headBanner.name === 'depositBonus.regular') return <Regular banner={headBanner} />
    if (headBanner.name === 'depositBonus.regBonus') return <RegBonus banner={headBanner} />

    return null;
  }, [headBanner]);

  const onActivateBonus = async (bonusName: string) => {
    const isMobile = window?.innerWidth < 600;
    try {
      // Стандартный хук useIsMobile в первый рендер дает ширину 0
      // Поэтому всегда считалось бы мобилкой
      const resp = await mutateAsync({ code: bonusName });
      const promoPercent = resp.data?.bonusPercent;
      
      refetch();
      searchParams.delete('bonus');
      setSearchParams(searchParams);

      setToastEvent({
        type: 'success',
        title: t('Promo code activated'),
        duration: isMobile ? TOAST_60_SEC_TIME : undefined,
        description: (
          <Text 
            level={1}
            className='!text-white'
            withTranslate={false}
          >
            {t(`You have bonus on your next deposit.`, { value: promoPercent })}
            <span
              className='text-medium text-sunray.500 cursor-pointer ml-[4px]'
              onClick={() => {
                nav(path.money.depositTab());
                setToastEvent(null);
              }}
            >
              {t('Deposit now')}
            </span>
          </Text>
        ),
      });
      
    } catch (error) {
      if (isAxiosError(error)) {
        searchParams.set('bonus', '');
        const axError = error.response?.data as ResponseError;
        if (!axError) return;

        setToastEvent({
          type: 'error',
          duration: isMobile ? TOAST_60_SEC_TIME : undefined,
          title: axError.details?.validationErrors?.items?.[0].msg,
          description: 'Please verify the code for errors or enter a different promo code.',
        });
        return;
      }
      
      setToastEvent({
        type: 'error',
        title: 'Internal server error',
        duration: isMobile ? TOAST_60_SEC_TIME : undefined,
      });
    }
  }

  useEffect(() => {
    onBannerShowed(bannerComp !== null);
  }, [bannerComp]);
  
  useEffect(() => {
    const bonusUrl = searchParams.get('bonus');
    if (!bonusUrl) return;
    
    onActivateBonus(bonusUrl);
  }, []);
  
  return (
    bannerComp
  )
}
