import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axios } from 'services/axios'

import { GET_HEAD_BANNERS_KIT_KEY } from '../get/useGetHeadBanner';

const hideHeadBanner = async (name: string) => {
  await axios.patch('/contentkit/v1/header-banner/hide', { name });
}

export const useHideHeadBanner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: hideHeadBanner,
    onSuccess(data) {
      queryClient.invalidateQueries([GET_HEAD_BANNERS_KIT_KEY]);
    },
  })
}
