import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useUnit } from 'effector-react'

import {
  Button,
  Checkbox,
  Hint,
  Input,
  Select,
  Title,
} from '../../../../ui/atoms'
import { AccountLeverage } from '../types'
import { useAccountSettings } from './hooks'
import {
  setActiveCurrency,
  setActiveFixRate,
  setActiveLeverage,
  setActiveSwapFree,
  setInitialBalance,
  submitCreateAccount,
} from './model'
import { useNavigate } from 'react-router-dom'
import { path } from 'libs/path'

import { $copyingStrategy } from 'pages/copytrade/model'
import { createAccount } from '../model'

export const AccountsCreateSettings = () => {
  const [t] = useTranslation();

  const copyingStrategy = useUnit($copyingStrategy);

  const nav = useNavigate();

  const {
    currencies,
    leverages,
    initialBalance,
    activeCurrency,
    activeFixRate,
    activeLeverage,
    activeSwapFree,
    isCreateAccountLoading,
    isSwapFreeAvailable,
    isFixRateAvailable,
    activeTariffName,
  } = useAccountSettings()

  React.useEffect(() => {
    if (!isFixRateAvailable) {
      setActiveFixRate(false)
    }
  }, [isFixRateAvailable]);


  React.useEffect(() => {
    const unwatch = createAccount.done.watch((done) => {
      window.scrollTo({ top: 0, behavior: 'instant' })
      nav(copyingStrategy
        ? path.copytrade.startCopying.accountSettings()
        : path.accounts.details(done.result.body.accountInfo.accountID)
      );
    });
  
    return () => {
      unwatch?.();
    }
  }, []);
  

  return (
    <>
      <AccountSettings>
        <Title level={1}>Account settings</Title>
        <AccountLavarage>
          <Select
            value={activeLeverage}
            label={
              <Hint
                textContent={t(
                  'A ratio at which Headway multiplies account funds for trading. Available leverage depends on the account’s equity and can increase potential profit as well as risk.',
                )}
              >
                <AccountLabel>{t('Select Leverage')}</AccountLabel>
              </Hint>
            }
            options={leverages.items.map((l) => ({
              label: `1:${l}`,
              value: `${l}`,
            }))}
            onSelect={(leverage) =>
              setActiveLeverage(leverage as AccountLeverage)
            }
          />
        </AccountLavarage>
        <AccountCurrency>
          <AccountLabel>
            <Hint
              textContent={t(
                'A currency of your trading account with which you trade on the platform.',
              )}
            >
              <AccountLabel>{t('Choose currency')}</AccountLabel>
            </Hint>
          </AccountLabel>
          <AccountCurrencyButtons>
            {currencies.map((item, key) => (
              <Button
                size="large"
                design={
                  item.currency === activeCurrency ? 'primary' : 'secondary'
                }
                name={`${item.currency}`}
                key={key}
                onClick={() => setActiveCurrency(item.currency)}
              >
                {item.currency}
              </Button>
            ))}
          </AccountCurrencyButtons>
        </AccountCurrency>
        {isFixRateAvailable && (
          <AccountCheckbox>
            <Hint
              textContent={t(
                'Fix Rate allows you to fund your account at the rate of 1 USD = 10,000 IDR. If you enable the Fix Rate, you can’t undo it later.',
              )}
            >
              <AccountLabel>Fix rate</AccountLabel>
            </Hint>
            <Checkbox
              name="settings-fix-rate"
              checked={activeFixRate}
              design="brand"
              onChange={(isChecked) => {
                setActiveFixRate(isChecked)
              }}
            />
          </AccountCheckbox>
        )}
        {isSwapFreeAvailable && (
          <AccountCheckbox>
            <Hint
              textContent={t(
                'If your account is Swap-Free (also referred to as Islamic), you don’t pay overnight swaps and interest for your open positions.',
              )}
            >
              <AccountLabel>Swap Free</AccountLabel>
            </Hint>
            <Checkbox
              name="settings-swap-free"
              checked={activeSwapFree}
              design="brand"
              onChange={(isChecked) => {
                setActiveSwapFree(isChecked)
              }}
            />
          </AccountCheckbox>
        )}
        {activeTariffName.includes('demo') && (
          <AmountInput
            label={<AccountLabel>Initial Amount</AccountLabel>}
            value={initialBalance}
            onChange={(e) => setInitialBalance(+e.target.value)}
          />
        )}
      </AccountSettings>
      <AccountCreateNextButton
        loading={isCreateAccountLoading}
        disabled={isCreateAccountLoading}
        name="account-create"
        size="medium"
        onClick={() => {
          submitCreateAccount();
        }}
      >
        Create account
      </AccountCreateNextButton>
    </>
  )
}

const AccountSettings = styled.div`
  margin-top: 60px;
  margin-bottom: 54px;
  width: 50%;
`

const AccountLavarage = styled.div`
  margin-top: 60px;
`

const AccountLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 500;
`

const AccountCreateNextButton = styled(Button)`
  min-width: 260px;
  margin-top: auto;
  margin-inline-start: auto;
`

const AccountCurrency = styled.div`
  margin-top: 40px;
`

const AccountCurrencyButtons = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 24px;
`

const AccountCheckbox = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const AmountInput = styled(Input)`
  margin-top: 40px;
`
