import { path } from 'libs/path'
import { setPublishedAccount } from 'pages/copytrade/model'
import { accountChoosed } from 'pages/money/depositChoice/model'
import formatFn from 'date-fns/format';
import * as React from 'react'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Block, Button, Hint, Icon2, Money, Tag, Title } from 'ui/atoms'
import { BonusBanner, MetaTraders, TradeModal } from 'ui/molecules'
import { Text } from 'ui/atoms'
import { CopyIcon } from 'ui/molecules/CopyButton/CopyIcon'
import { Modal } from 'ui/molecules/modal_draft'

import { accountPlatformName, accountTypesName } from '../list'
import { LeverageDialog } from '../list/page'
import { onChangeAccount } from '../model'
import { AccountRename } from '../rename'
import { SwapFree } from '../swapFree'
import { Account } from '../types'
import { DemoAddBalance } from './DemoAddBalance'
import { useAccountDetails } from './hooks'
import { MetaTraderSettings } from './MetaTraderSettings'
import { getDateObjFromUnix } from 'libs/dateUtils'

export const AccountDetails = () => {
  const [t] = useTranslation()
  const { accountID, account } = useAccountDetails()
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)

  const {
    name,
    type,
    isFixRate,
    tariffName,
    platform,
    balance,
    currency,
    isSwapFree,
    login,
    password,
    mtServerInfo,
    availableActions,
    isDepositBonusActivated,
    depositBonusInfo,
  } = account

  const onClickDeposit = () => {
    accountChoosed(account.accountID)
    navigate(path.money.entry())
  }

  const onClickWithdrawal = () => {
    accountChoosed(account.accountID)
    navigate(path.money.withdrawalTab())
  }

  const onSetNewBalance = (id: string, newAccountData: Account) => {
    onChangeAccount(newAccountData)
  }

  const onGetBonusDate = () => {
    if (!depositBonusInfo?.profitPayoutTs) return '';
  
    const closeTimeObj = getDateObjFromUnix(+depositBonusInfo.profitPayoutTs);

    return formatFn(closeTimeObj, 'MMMM d, yyyy')
  }
  const isPro = tariffName.toLowerCase().includes('pro')

  return (
    <AccountsContainer>
      <AccountsContent>
        <Block>
          <DetailsBlock scheme="ghost">
            <DetailsHeader>
              <DetailsTitleContainer>
                <AccountRename accountID={accountID}>
                  <DetailsTitle level={2}>{name}</DetailsTitle>
                </AccountRename>
              </DetailsTitleContainer>
              <DetailsTags>
                {isFixRate && <Tag design="eucalyptus">Fixed rate</Tag>}
                <Tag design={type === 'PAAT_DEMO' ? 'azure' : 'sunray'}>
                  {accountTypesName[type]}
                </Tag>
                <Tag design="gray">{accountPlatformName[platform]}</Tag>
                {tariffName && (
                  <Tag design="ghost">{tariffName.replace('demo', '')}</Tag>
                )}
              </DetailsTags>
            </DetailsHeader>
            <DetailsContent>
              <DetailsRow>
                <Hint
                  textContent={t(
                    'Total cash available for trading, withdrawals, and internal transfers.',
                  )}
                >
                  {t('Balance')}
                </Hint>
                <DetailsValue>
                  <Money value={balance} currency={currency} />
                </DetailsValue>
              </DetailsRow>

              {isDepositBonusActivated &&
                <>
                  <DetailsRow className='h-[48px]'>
                    <Hint
                      textContent={t(
                        'The deposit bonus boosts your trading. The bigger your deposit, the higher the bonus. Maximum amount: $20,000.',
                      )}
                    >
                      {t('Bonus')}
                    </Hint>
                    <DetailsValue>
                      <Money value={Number(depositBonusInfo?.bonusAmount) || 0.00} currency={currency} />
                    </DetailsValue>
                  </DetailsRow>

                  <DetailsRow className='h-[48px]'>
                    <Hint
                      textContent={t(
                        'The withdrawable bonus is calculated monthly based on trading activity. It equals up to 30% of the spread (or commission for specific accounts). The amount is credited to your balance and deducted from the bonus.',
                      )}
                    >
                      {t('Withdrawable bonus')}
                    </Hint>
                    <DetailsValue>
                      <Money value={Number(depositBonusInfo?.profitAmount) || 0.00} currency={currency} />
                    </DetailsValue>
                  </DetailsRow>

                  <Text level={2} color='subtext' withTranslate={false}>
                    {`${t('Goes to balance')}`}:
                    <span className='font-medium ml-[4px]'>
                      {onGetBonusDate()}
                    </span>
                  </Text>
                  <Text level={2} color='subtext' withTranslate={false} className='mt-[4px] max-w-[500px]'>
                    {`${t('Once all conditions are met, the amount will be automatically transferred to your account balance.')}`} 
                    <span className='ml-[4px] text-bronze.500 cursor-pointer'>
                      <a href='/deposit-bonus-terms/' target='_blank' rel="noreferrer">
                         {t('View details')}
                      </a>
                    </span>
                  </Text>
                </>
              }
            </DetailsContent>
            <DetailsFooter>
              {type === 'PAAT_DEMO' && (
                <DemoAddBalance
                  id={account.accountID}
                  onSetNewBalance={onSetNewBalance}
                />
              )}
              {type !== 'PAAT_DEMO' && (
                <Button
                  size="small"
                  name="details-deposit"
                  onClick={onClickDeposit}
                  design="primary"
                  prefix={<Icon2 name="arrowDownOnSquare" size="small" />}
                >
                  Deposit
                </Button>
              )}
              {type !== 'PAAT_DEMO' && (
                <Button
                  size="small"
                  name="details-withdrawal"
                  onClick={onClickWithdrawal}
                  design="secondary"
                  prefix={<Icon2 name="arrowDownOnSquare" size="small" />}
                >
                  Withdrawal
                </Button>
              )}
              <Button
                size="small"
                name="details-trade"
                onClick={() => setOpenModal(true)}
                design="secondary"
                prefix={<Icon2 name="arrowTrendingUp" size="small" />}
              >
                Trade
              </Button>
            </DetailsFooter>
          </DetailsBlock>
          {/* // */}
          <BlockContainer>
            <Title level={3}>Account settings</Title>
            <DetailsBlock scheme="ghost">
              <DetailsRow>
                <Hint
                  textContent={t(
                    'A ratio at which Headway multiplies account funds for trading. Available leverage depends on the account’s equity and can increase potential profit as well as risk.',
                  )}
                >
                  {t('Leverage')}
                </Hint>
                <DetailsValue>
                  <LeverageDialog accountID={accountID} />
                </DetailsValue>
              </DetailsRow>
              {availableActions.indexOf('ACTION_CHANGE_SWAP_FREE') !== -1 ? (
                <DetailsRow>
                  <Hint
                    textContent={t(
                      'If your account is Swap-Free (also referred to as Islamic), you don’t pay overnight swaps and interest for your open positions.',
                    )}
                  >
                    {t('Swap Free')}
                  </Hint>
                  <DetailsValue>
                    <SwapFree accountID={accountID} isSwapFree={isSwapFree} />
                  </DetailsValue>
                </DetailsRow>
              ) : null}
            </DetailsBlock>
          </BlockContainer>
          {/* // */}
          <BlockContainer>
            <Title level={3}>MetaTrader settings</Title>
            <MetaTraderSettings
              login={login}
              mtServerInfo={mtServerInfo}
              password={password}
              accountID={accountID}
            />
          </BlockContainer>
          {/* // */}
          {type !== 'PAAT_DEMO' && (
            <BlockContainer>
              <Title level={3}>Copytrade settings</Title>
              <DetailsBlock scheme="ghost">
                <DetailsRow>
                  {t('Publish this account as a strategy')}
                  <button
                    type="button"
                    className="font-medium text-bronze.500"
                    onClick={() => {
                      setPublishedAccount(account.accountID)

                      navigate(path.copytrade.createStrategy())
                    }}
                  >
                    {t('Publish')}
                  </button>
                </DetailsRow>
                <DetailsRow>
                  {t('Copy other traders on this account')}
                  <Link
                    to={path.copytrade.investorEntry()}
                    className="font-medium text-bronze.500 whitespace-nowrap"
                  >
                    {t('See strategies')}
                  </Link>
                </DetailsRow>
              </DetailsBlock>
            </BlockContainer>
          )}
          {/* // */}
          <BlockContainer>
            <Title level={3}>Account information</Title>
            <DetailsBlock scheme="ghost">
              <DetailsRow>
                <Hint
                  textContent={t(
                    'Fix Rate allows you to fund your account at the rate of 1 USD = 10,000 IDR. If you enable the Fix Rate, you can’t undo it later.',
                  )}
                >
                  Fix rate
                </Hint>
                <DetailsValue>{t(isFixRate ? 'Yes' : 'No')}</DetailsValue>
              </DetailsRow>
              <DetailsRow>
                <Hint
                  textContent={t(
                    'A fee paid to a broker for buying or selling assets.',
                  )}
                >
                  {t('Commission')}
                </Hint>
                <DetailsValue>
                  {t(isPro ? 'Up to 1.5$ each side per lot' : 'No')}
                </DetailsValue>
              </DetailsRow>
              <DetailsRow>
                <Hint
                  textContent={t(
                    'A gap between the bid (buying) and the ask (selling) price of an asset.',
                  )}
                >
                  {t('Spread')}
                </Hint>
                <DetailsValue>
                  <Trans
                    t={t}
                    i18nKey="Floating from 0.3"
                    values={{ number: isPro ? '0.0' : '0.3' }}
                  />
                </DetailsValue>
              </DetailsRow>
              <DetailsRow>
                <Hint
                  textContent={t(
                    'The total quantity of lots, shares, or contracts traded for an asset.',
                  )}
                >
                  {t('Order volume')}
                </Hint>
                <DetailsValue>
                  {isPro
                    ? t('From 0.1 to 1000 lots')
                    : t('From 0.01 to 1000 cent lots')}
                </DetailsValue>
              </DetailsRow>
              <DetailsRow>
                <Hint
                  textContent={t(
                    'The total available number of open and scheduled positions in MetaTrader.',
                  )}
                >
                  {t('Maximum open positions and pending orders')}
                </Hint>
                <DetailsValue>
                  {isPro
                    ? t('Unlimited')
                    : account.tariffName === 'cent'
                    ? 300
                    : 200}
                </DetailsValue>
              </DetailsRow>
              <DetailsRow>
                <Hint
                  textContent={t(
                    'The time needed to open an order at the available price, depending on market depth, without re-quotes.',
                  )}
                >
                  {t('Market execution time')}
                </Hint>
                <DetailsValue>{t('From 0.16 sec')}</DetailsValue>
              </DetailsRow>
            </DetailsBlock>
          </BlockContainer>
        </Block>
      </AccountsContent>
      <AccountsAside>
        <MetaTraders aside />
        <BonusBanner />
      </AccountsAside>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        okLabel="Back"
        title="Trade"
        width={400}
      >
        <TradeModal details={account} />
      </Modal>
    </AccountsContainer>
  )
}

const AccountsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const AccountsContent = styled.div`
  width: 70%;

  @media (max-width: 900px) {
    width: 100%;
  }
`

const AccountsAside = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 20px;

  @media (max-width: 900px) {
    width: 100%;
  }
`

const BlockContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const DetailsTitle = styled(Title)`
  line-height: 0;
`

const DetailsTitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const DetailsTags = styled.div`
  display: flex;
  text-transform: capitalize;
`

const DetailsHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 570px) {
    flex-wrap: wrap;
  }
`

const DetailsContent = styled.div`
  padding-top: 24px;
  transition: var(--transition);
`

export const DetailsRow = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
`

export const DetailsValue = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  text-align: end;
  flex-wrap: wrap;
  justify-content: flex-end;
`

export const DetailsBlock = styled(Block)`
  ${DetailsRow} {
    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
`

const DetailsFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding-top: 24px;
  color: var(--color-gray-700);

  @media (max-width: 470px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const DetailsCopy = styled(CopyIcon)`
  margin-inline-start: 8px;
`
