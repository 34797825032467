import { combine, createEvent, createStore, restore, sample } from 'effector'

import { ClientError, fx, mapResponseError, path } from 'libs'
import { $uploadFiles } from '../../atoms/upload/model'
import { getVerification } from '../../model'
import { PersonProps, VerificationProps } from '../../types'
import { toPath } from 'features/router'

export const mounted = createEvent()

export const submitForm = createEvent<PersonProps>();
export const submitEditForm = createEvent<PersonProps>()

export const postVerification = fx<VerificationProps, {}>({
  method: 'PUT',
  url: `/user/v1/verification`,
})

export const editVerification = fx<VerificationProps, {}>({
  method: 'PUT',
  url: `/user/v1/profile`,
})

// handle country

export const setCountry = createEvent<string>()
export const $country = restore(setCountry, '');

sample({
  clock: getVerification.doneData,
  fn: (doneData) => doneData.body.country,
  target: setCountry,
})

// combine postVerification payload

sample({
  source: combine($country, $uploadFiles),
  clock: submitForm,
  fn: (source, submitForm) => {
    const { firstName, lastName, middleName, dateOfBirth, address, country: defaultCountry, documentNumber } = submitForm
    const [country, uploadFiles] = source
    const fileNames = uploadFiles
      .map(({ fileName }) => fileName)
      .filter((fileName) => Boolean(fileName))

    return {
      body: {
        firstName,
        lastName,
        middleName,
        dateOfBirth,
        country: country || defaultCountry,
        address,
        fileNames,
        documentNumber
      },
    }
  },
  target: postVerification,
})

// Edit profile
sample({
  source: combine($country, $uploadFiles),
  clock: submitEditForm,
  fn: (source, submitEditForm) => {
    const { firstName, lastName, middleName, dateOfBirth, address, country: defaultCountry, documentNumber } = submitEditForm
    const [country, uploadFiles] = source
    const fileNames = uploadFiles
      .map(({ fileName }) => fileName)
      .filter((fileName) => Boolean(fileName))

    return {
      body: {
        firstName,
        lastName,
        middleName,
        dateOfBirth,
        country: country || defaultCountry,
        address,
        fileNames,
        documentNumber
      },
    }
  },
  target: editVerification,
});


sample({
  clock: postVerification.doneData,
  fn: () => {
    toPath(path.dashboard.verification.progress());
    return {};
  },
  target: getVerification,
})

sample({
  clock: editVerification.doneData,
  fn: (data) => {

    toPath(path.dashboard.profile.entry());
    return {};
  },
  target: getVerification,
})

// handle errors

export const $errors = createStore<ClientError<VerificationProps> | null>(null)

$errors
  .on(postVerification.failData, (_, failData) =>
    mapResponseError<VerificationProps>(failData.body),
  )
  .reset([mounted, submitForm])
