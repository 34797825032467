import React, { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useStore, useUnit } from 'effector-react'

import { $copyingSettings, $keepStrategyUntil } from 'pages/copytrade/model'
import { path } from 'libs/path'

import clockSrc from 'styles/img/clock.png'
import clockSrcWeb from 'styles/img/clock.webp'
import errorSrc from 'styles/img/error.png'
import errorSrcWeb from 'styles/img/error.webp'
import successSrc from 'styles/img/success.png'
import successSrcWeb from 'styles/img/success.webp'
import { Button, Icon2, Money, Text, Title } from 'ui/atoms'

import * as S from './styled'

export interface TransactionStatusProps {
  status?: 'error' | 'pending' | 'approved'
  title?: string
  subTitle?: string
  currency: string
  amount: string
  buttonLink?: string
  buttonText?: string
  id: string
  isLinkOpenNewTab?: boolean
}

const currencyWithoutRound = ['BTC', 'ETH', 'LTC', 'BCH'];

export const TransactionStatus: React.FC<TransactionStatusProps> = ({
  status,
  currency,
  amount,
  title,
  id,
  isLinkOpenNewTab,
  subTitle,
  buttonLink,
  buttonText,
}) => {
  const { currentStep } = useStore($copyingSettings)
  const keepStrategyUntil = useStore($keepStrategyUntil)

  const imgSrc = useMemo(() => {
    if (status === 'pending') return { main: clockSrc, webp: clockSrcWeb };
    if (status === 'error') return { main: errorSrc, webp: errorSrcWeb };
    if (status === 'approved') return { main: successSrc, webp: successSrcWeb };
    if (status === 'success') return { main: successSrc, webp: successSrcWeb };
    return { main: clockSrc, webp: clockSrcWeb };
  }, [status])

  const navigate = useNavigate();

  const link = buttonLink || path.money.moneyHistory();

  const moneyComponent = () => {
    if (currencyWithoutRound.includes(currency)) return `${currency} ${amount}`;

    return <Money currency={currency} value={+amount} />
  }

  const onClickButton = () => {
    navigate(link, { replace: true });
  }

  return (
    <div className="mt-[5rem] flex flex-col items-center">
      <picture>
        <source srcSet={imgSrc.webp} type="image/webp" className="w-[8rem]" />
        <img src={imgSrc.main} alt="question" className="w-[8rem]" />
      </picture>
      
      <div className="mt-[2.4rem] flex gap-[2.4rem] flex-col items-center">
        <Title level={2}>{title}</Title>
        <Text className="lg:w-[70rem] w-full text-center">{subTitle}</Text>

        <S.InfoWrapper>
          <S.InfoItem>
            <Text className="text-gray.600">Transaction ID</Text>
            <Text className="!font-medium">{id}</Text>
          </S.InfoItem>

          <S.InfoItem>
            <Text className="text-gray.600">Amount</Text>
            <Text className="!font-medium" withTranslate={false}>
              {moneyComponent()}
            </Text>
          </S.InfoItem>
        </S.InfoWrapper>

        {isLinkOpenNewTab ? (
          <Link to={link!} className="mt-[4rem]" target={buttonLink ? "_blank" : ''}>
            <Button name="dashboard" design="primary" className="mt-[4rem]">
              {buttonText || 'Check transaction'}
            </Button>
          </Link>
        ) : (
          <Button
            name="dashboard"
            design="primary"
            className="mt-[4rem]"
            onClick={onClickButton}
          >
            {buttonText || 'Check transaction'}
          </Button>
        )}

        {currentStep === 'Depositing' &&
          keepStrategyUntil &&
          new Date().getTime() < keepStrategyUntil && (
            <S.CopytradeInfo>
              Finish setting up your investor account in Copytrade to start
              copying trades.{' '}
              <Link
                to={path.copytrade.startCopying.replenishAccount()}
                className="text-bronze.500 font-medium inline-flex items-center gap-[4px]"
              >
                Back to Copytrade
                <Icon2
                  name="arrowRight"
                  size="custom"
                  className="w-[15px] h-[15px]"
                />
              </Link>
            </S.CopytradeInfo>
          )}
      </div>
    </div>
  )
}
