import React, { useCallback, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { domToPng } from 'modern-screenshot'

import styled from 'styled-components/macro';
import { useUnit } from 'effector-react';

import { Button, Icon2, Text, Title } from 'ui/atoms';

import { PromoYour2024Colors, PromoYour2024Type } from '../api/useGetYour2024';
import { GoldPng, SilverPng, BronzePng, GreenPng, LogoPng } from './img';
import { $userProfile } from 'features/user';
import { CARD_CATEGORY } from './cardCategory';
import { useDownloadFile } from 'pages/ib/utils/useDownloadFile';



interface Props {
  data: PromoYour2024Type['card'];
}

const colors: Record<PromoYour2024Colors, string> = {
  COLOR_GOLD: 'bg-[#F4D124]',
  COLOR_SILVER: 'bg-gray.300',
  COLOR_BRONZE: 'bg-[#F4AB83]',
  COLOR_GREEN: 'bg-eucalyptus.300',
  COLOR_INVALID: 'bg-eucalyptus.100'
}

const iconStatus: Record<PromoYour2024Colors, string> = {
  COLOR_GOLD: GoldPng,
  COLOR_SILVER: SilverPng,
  COLOR_BRONZE: BronzePng,
  COLOR_GREEN: GreenPng,
  COLOR_INVALID: GreenPng,
}

export const Card: React.FC<Props> = ({ data }) => {
  const user = useUnit($userProfile);
  const cardRef = useRef<HTMLDivElement>(null);
  const { isLoading, startDownload } = useDownloadFile()

  const [t] = useTranslation();

  const onDownload = useCallback(() => {
    if (cardRef.current === null) {
      return
    }

    domToPng(cardRef.current)
      .then((dataUrl) => {
        startDownload(dataUrl, `your2024_${user?.firstName}.png`);
      })
      .catch((err) => {
        console.log(err)
      })
  }, [cardRef]);

  const isFirstStart = !data?.categoryID && !data?.rank;
  
  return (
    <>
      <div ref={cardRef} className='!w-[300px] !h-[533px] bg-black p-[3px] rounded-[20px]'>
        <CardStyled className={`${colors[data.color]}`}>
          <CardImg img={iconStatus[data.color]} />
          <div className='h-1/2 w-full flex flex-col items-center flex-1 justify-between'>
            <div>
              <Text
                level={3}
                weight='medium'
                withTranslate={false}
                className='text-center'
              >
                {t('Congratulations', { name: user?.firstName})}
              </Text>

              {data?.rank && 

                <Title className='mt-[24px] text-center' level={2} withTranslate={false}>
                  <Trans
                    i18nKey={'your2024.page.card'}
                    t={t}
                    values={{ rank: data.rank }}
                    default={`In 2024, you are in TOP ${data.rank}% of traders`}
                    components={{
                      1: (<br></br>),
                      2: (<br></br>)
                    }}
                  />
                </Title>
              }

              {!!data.categoryID && 
                <Text
                  level={3}
                  weight='medium'
                  className='text-center mt-[40px]'
                >
                  {CARD_CATEGORY[data.categoryID] || ''}
                </Text>
              }

              { isFirstStart && 
                <div className='w-full flex flex-col items-center'>
                  <Text
                    level={2}
                    weight='bold'
                    className='text-center mt-[30px] w-[225px]'
                    >
                      In 2024, you have only just started with Headway.
                  </Text>
                  <Text
                    level={2}
                    weight='bold'
                    className='text-center mt-[20px] w-[225px]'
                    >
                      Keep going, and next year you may be in the TOP 1% of our best traders.
                  </Text>
                  <Text
                    level={2}
                    weight='bold'
                    className='text-center mt-[20px] w-[225px]'
                    >
                    Happy holidays!
                  </Text>
                </div>
              }
            </div>

            <div className='flex justify-between w-full px-[10px] pb-[5px]'>
              <img src={LogoPng} className='w-[80px]' />
              <Text weight='medium' level={3} withTranslate={false}>hw.online</Text>
            </div>
          </div>
        </CardStyled>
      </div>
      <div className='mt-[24px] w-full md:max-w-[240px] '>
        <Button
          className='w-full h-[48px]'
          prefix={<Icon2 name="arrowDownTray" />}
          onClick={onDownload}
          loading={ isLoading }
        >
          {t('Download')}
        </Button>
      </div>
    </>
  )
}


const CardStyled = styled.div`
  border-radius: 20px;
  width: 100%;
  height: 100%;
`;

const CardImg = styled.div<{img: string}>`
  width: 100%;
  height: 50%;
  background-image: url(${p => p.img});
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
`;