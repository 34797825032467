import styled, { css } from 'styled-components/macro'
import { Title } from 'ui/atoms'

import closingIcon from '../images/closing-icon.webp'
import step_1 from '../images/how-it-works-1.webp'
import step_2 from '../images/how-it-works-2.webp'
import step_3 from '../images/how-it-works-3.webp'
import preparingIcon from '../images/preparing-icon.webp'

export const ContentContainer = styled.div`
  padding-bottom: 72px;
  background-color: var(--color-gray-100);
  border-radius: 8px;
  box-shadow: 0px 2px 4px -1px #2621190f, 0px 4px 6px -1px #2621191a;

  @media (max-width: 599px) {
    padding-bottom: 16px;
  }
`

export const Header = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: 690fr 390fr;
  align-items: start;
  gap: 20px;
  background-color: var(--color-gray-300);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media (max-width: 860px), (min-width: 1051px) and (max-width: 1170px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 599px) {
    padding: 0;
    padding-bottom: 20px;
    gap: 24px;
    border-radius: 8px;
  }
`

export const ContestImage = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  background-color: var(--color-gray-700);

  @media (max-width: 599px) {
    grid-row: 1 / 2;
    padding-bottom: 53%;
    height: auto;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom center;

    @media (max-width: 599px) {
      object-position: center center;
    }
  }
`

export const ContestInfo = styled.div`
  padding: 24px 40px;
  min-height: 376px;
  background-color: var(--color-gray-100);
  border-radius: 8px;

  @media (max-width: 599px) {
    padding: 0 12px;
    min-height: auto;
    background-color: transparent;
    border-radius: 0;
  }
`

export const InfoTopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-gray-600);

  @media (max-width: 599px) {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--color-gray-700);
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const InfoBottomWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: end;

  @media (max-width: 699px) {
    margin-top: 32px;
    display: grid;
  }

  @media (max-width: 599px) {
    margin-top: 32px;
    grid-template-columns: 1fr;
  }
`

export const ContestTitle = styled.h1`
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 53px;
  line-height: 61px;
  font-weight: 500;

  @media (max-width: 599px) {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 1.35;
  }
`

export const ContestDescription = styled.div`
  margin-bottom: 24px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);

  @media (max-width: 599px) {
    margin-bottom: 16px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const ContestTerms = styled.div`
  display: flex;
  gap: 32px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 500;

  @media (max-width: 599px) {
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const TermTitle = styled.span`
  margin-inline-end: 8px;
`

export const ContestBody = styled.div`
  margin: 0 auto;
  padding-top: 56px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 920px;
  width: 100%;

  @media (max-width: 599px) {
    padding-top: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
`

export const WinnersGrid = styled.div`
  display: grid;
  grid-template-columns: 500fr 360fr;
  gap: 20px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`

export const WinnersColumn = styled.div<{
  background?: string
  isFullwidth?: boolean
}>`
  padding: 24px 20px;
  height: 285px;
  display: flex;
  flex-direction: column;
  font-size: var(--body-font-size-regular);
  line-height: 32px;
  background-color: var(--color-gray-300);
  background-image: ${({ background }) => `url(${background})`};
  background-size: auto 100%;
  background-position: right bottom;
  background-repeat: no-repeat;
  border-radius: 8px;

  @media (max-width: 767px) {
    height: auto;
    background-size: 100% auto;

    &:nth-child(1) {
      min-height: 360px;
    }

    &:nth-child(2) {
      min-height: 160px;
    }
  }

  ${({ isFullwidth }) =>
    isFullwidth &&
    css`
      padding: 24px 20px 12px;
      align-items: center;
      text-align: center;

      @media (max-width: 767px) {
        padding: 24px 20px;

        &:nth-child(1) {
          min-height: 380px;
        }
      }

      @media (max-width: 599px) {
        align-items: flex-start;
        text-align: left;
      }
    `}
`

export const WinnersTitle = styled(Title)`
  margin-bottom: 16px;
`

export const HowItWorksWrapper = styled.div`
  margin-top: 16px;
  display: grid;
  justify-content: center;
  gap: 56px;
  grid-template-columns: repeat(3, 200px);

  @media (max-width: 819px) {
    gap: 40px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

export const HowItWorksStep = styled.div`
  padding-top: 88px;
  display: grid;
  gap: 8px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 80px auto;

  &:nth-child(1) {
    background-image: url(${step_1});
  }

  &:nth-child(2) {
    background-image: url(${step_2});
  }

  &:nth-child(3) {
    background-image: url(${step_3});
  }
`

export const TermsWrapper = styled.div`
  margin-top: 56px;
  padding: 0 40px;
  display: grid;
  gap: 16px;
  justify-items: center;
  text-align: center;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);

  @media (max-width: 767px) {
    padding: 0;
  }

  @media (max-width: 599px) {
    margin-top: 40px;
  }
`

export const VotesWrapper = styled.div<{
  background?: string
  leftImage: string
  rightImage: string
  vsIcon?: string
}>`
  height: 140px;
  display: grid;
  grid-template-columns: 130px 130px;
  gap: 140px;
  align-items: center;
  justify-content: center;
  color: var(--color-gray-100);
  text-align: center;
  background-color: var(--color-gray-700);
  border-radius: 8px;
  background-image: ${({ vsIcon, leftImage, rightImage, background }) =>
    `url(${vsIcon}), url(${leftImage}), url(${rightImage}), url(${background})`};
  background-repeat: no-repeat;
  background-position: center center, left center, right center, center center;
  background-size: 56px auto, auto 100%, auto 100%, cover;

  @media (max-width: 767px) {
    height: 160px;
    gap: 40px;
  }
`

export const VotesTitle = styled.div`
  margin-bottom: 8px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 500;

  @media (max-width: 599px) {
    margin-bottom: 4px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const UserInfoGrid = styled.div<{ columns: 1 | 2 }>`
  margin: 56px 0;
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns === 1 ? '500px' : '360px 1fr'};
  gap: 8px 20px;
  justify-content: center;

  @media (max-width: 890px) {
    margin: 40px 0;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  &:first-child {
    margin-top: 0;
  }
`

export const InfoWrapper = styled.div`
  border: 1px solid var(--color-gray-300);
  padding: 24px 20px;
  min-height: 348px;
  display: grid;
  border-radius: 8px;

  @media (max-width: 890px) {
    min-height: auto;
  }

  @media (max-width: 599px) {
    /* margin-top: 8px; */
  }
`

export const UserInfoWrapper = styled(InfoWrapper)`
  margin-top: 0;
  gap: 24px;
  align-content: start;

  @media (max-width: 890px) {
    grid-template-columns: 1fr 1fr;
    gap: 32px 20px;
  }
`

export const AccountInfoWrapper = styled(InfoWrapper)`
  align-content: center;
`

export const AccountStatusTitle = styled(Title)<{
  status: 'preparing' | 'closing'
}>`
  margin-bottom: 16px;
  padding-top: 144px;
  text-align: center;
  background-image: ${({ status }) =>
    status === 'preparing' ? `url(${preparingIcon})` : `url(${closingIcon})`};
  background-repeat: no-repeat;
  background-size: 128px auto;
  background-position: top center;

  @media (max-width: 599px) {
    margin-bottom: 8px;
    padding-top: 136px;
  }
`

export const AccountStatusText = styled.div`
  color: var(--color-gray-600);
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  text-align: center;

  @media (max-width: 599px) {
    margin-bottom: 4px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`
