import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

import type { Contest } from '../types'

export const GET_RAMADAN_CONTEST_INFO = 'ramadan-contest-info'

const getСontestInfo = async (isHallOfFame: boolean) => {
  const res = await axios.get<Contest>('/ramadan2025/v1/info', {
    params: {
      isHallOfFame,
    },
  })

  return res.data
}

interface Options {
  isHallOfFame?: boolean
}

export const useGetContestInfo = ({ isHallOfFame = false }: Options) => {
  return useQuery({
    queryKey: [GET_RAMADAN_CONTEST_INFO, isHallOfFame],
    staleTime: 3000,
    cacheTime: 5000,
    queryFn: () => getСontestInfo(isHallOfFame),
  })
}
