import { createEvent, createStore, restore, sample } from 'effector'
import { persist } from 'effector-storage/local'
// import { persist as persistSesion } from 'effector-storage/session'
import { fx } from 'libs'
import { Account } from 'services/accounts/types'
import { Strategy } from 'services/copytrade/types'
import {
  InternalTransferDestinationsProps,
  InternalTransferDestinationsResponse,
} from 'services/internal-transfers/types'

export const setRemovedStrategy = createEvent<string>()
export const $removedStrategy = restore(setRemovedStrategy, '')

export const setStrategyCreationAlertVisible = createEvent<boolean>()
export const $isStrategyCreationAlertVisible = restore(
  setStrategyCreationAlertVisible,
  false,
)

export const setStrategyUpdateAlertVisible = createEvent<boolean>()
export const $isStrategyUpdateAlertVisible = restore(
  setStrategyUpdateAlertVisible,
  false,
)

export const setStopCopyingAlertVisible = createEvent<boolean>()
export const $isStopCopyingAlertVisible = restore(
  setStopCopyingAlertVisible,
  false,
)

export const addStrategyToCompare = createEvent<{
  strategy: Strategy
  userID: string
}>()
export const removeStrategyFromCompare = createEvent<{
  strategyID: string
  userID: string
}>()
export const clearStrategiesComparisonList = createEvent<string>()
export const setStrategiesComparisonList = createEvent<Strategy[]>()

export const $strategiesComparisonList = createStore<Strategy[]>([])
  .on(setStrategiesComparisonList, (_, strategies) => strategies)
  .on(addStrategyToCompare, (payload, { strategy, userID }) => {
    const list = [...payload, strategy]

    localStorage.setItem(
      `strategiesComparisonList-${userID}`,
      JSON.stringify(list),
    )

    return list
  })
  .on(removeStrategyFromCompare, (payload, { strategyID, userID }) => {
    const list = payload.filter(
      (strategy) => strategy.strategyID !== strategyID,
    )

    localStorage.setItem(
      `strategiesComparisonList-${userID}`,
      JSON.stringify(list),
    )

    return list
  })
  .on(clearStrategiesComparisonList, (_, userID) => {
    localStorage.setItem(
      `strategiesComparisonList-${userID}`,
      JSON.stringify([]),
    )

    return []
  })

export const setTraderAreaInfoVisibility = createEvent<boolean>()
export const $isTraderAreaInfoVisible = restore(
  setTraderAreaInfoVisibility,
  true,
)

persist({
  store: $isTraderAreaInfoVisible,
  key: 'isTraderAreaInfoVisible',
})

export const setInvestorBoardInfoVisibility = createEvent<boolean>()
export const $isInvestorBoardInfoVisible = restore(
  setInvestorBoardInfoVisibility,
  true,
)

persist({
  store: $isInvestorBoardInfoVisible,
  key: 'isInvestorBoardInfoVisible',
})

export const setCopyingStrategy = createEvent<Strategy>()
export const resetCopyingStrategy = createEvent()

export const $copyingStrategy = restore(setCopyingStrategy, null).reset(
  resetCopyingStrategy,
)

// persistSesion({
//   store: $copyingStrategy,
//   key: 'copyingStrategy',
// })

export const setAccountCreationAlertVisible = createEvent<boolean>()
export const $isAccountCreationAlertVisible = restore(
  setAccountCreationAlertVisible,
  false,
)

export const setCopyingStrategyID = createEvent<string>()
export const setCopyOpenedPos = createEvent<boolean>()
export const setCopyingStrategyProportion = createEvent<number>()
export const setInvestorAccountID = createEvent<string>()
export const setCommissionType = createEvent<string>()
export const setCurrentStep = createEvent<string>()
export const resetCopyingSettings = createEvent()

export const $copyingSettings = createStore({
  strategyID: '',
  investorAccountID: '',
  proportion: 1,
  isCopyOpenedPos: false,
  commissionType: '',
  currentStep: '',
})
  .on(setCopyingStrategyID, (payload, strategyID) => ({
    ...payload,
    strategyID,
  }))
  .on(setCopyOpenedPos, (payload, isCopyOpenedPos) => ({
    ...payload,
    isCopyOpenedPos,
  }))
  .on(setCopyingStrategyProportion, (payload, proportion) => ({
    ...payload,
    proportion,
  }))
  .on(setInvestorAccountID, (payload, investorAccountID) => ({
    ...payload,
    investorAccountID,
  }))
  .on(setCommissionType, (payload, commissionType) => ({
    ...payload,
    commissionType,
  }))
  .on(setCurrentStep, (payload, currentStep) => ({
    ...payload,
    currentStep,
  }))
  .reset(resetCopyingSettings)

persist({
  store: $copyingSettings,
  key: 'copyingSettings',
})

export const setNewAccountForCopying = createEvent<Account | null>()
export const $newAccountForCopying = restore(setNewAccountForCopying, null)

export const setStartCopyingAlertVisible = createEvent<boolean>()
export const $isStartCopyingAlertVisible = restore(
  setStartCopyingAlertVisible,
  false,
)

export const updateStartCopyingAccounts = createEvent()
const getStartCopyingAccounts = fx<
  { strategyID: string },
  { items: Account[] }
>({
  method: 'GET',
  url: '/copytrade/v1/strategy/available-copying-accounts',
})

// используем destinations как sources
const getInternalTransferSources = fx<
  InternalTransferDestinationsProps,
  InternalTransferDestinationsResponse
>({
  method: 'GET',
  url: '/internal-transfers/v1/internal-transfer/destinations',
})

type PaymentAvailabilityParams = {
  fixedCommission: {
    commission: string
    srcAmount: string
    dstCurrency: string
    dstAmount: string
    isAvailable: boolean
    dstIsFixRate: boolean
  } | null
  percentCommission: {
    commission: string
    isAvailable: boolean
  } | null
}

const checkPaymentAvailability = fx<
  { strategyID: string; investorAccountID: string },
  PaymentAvailabilityParams
>({
  method: 'GET',
  url: '/copytrade/v2/strategy/check-availability-subscription-payment',
})

sample({
  source: $copyingStrategy,
  clock: updateStartCopyingAccounts,
  filter: (copyingStrategy) => !!copyingStrategy,
  fn: (copyingStrategy) => ({
    query: { strategyID: copyingStrategy!.strategyID },
  }),
  target: getStartCopyingAccounts,
})

sample({
  clock: setNewAccountForCopying,
  filter: (account) => !!account,
  fn: (account) => account!.accountID,
  target: setInvestorAccountID,
})

sample({
  source: $copyingSettings,
  clock: [setInvestorAccountID, getStartCopyingAccounts.done],
  filter: (copyingSettings) => !!copyingSettings.investorAccountID,
  fn: ({ strategyID, investorAccountID }) => ({
    query: {
      strategyID,
      investorAccountID,
    },
  }),
  target: checkPaymentAvailability,
})

sample({
  source: $copyingSettings,
  clock: [setInvestorAccountID, getStartCopyingAccounts.done],
  filter: (copyingSettings) => !!copyingSettings.investorAccountID,
  fn: ({ investorAccountID }) => ({
    query: {
      srcID: investorAccountID,
      srcIDType: 'PUBLIC_API_INTERNAL_TRANSFER_ACCOUNT_ID_TYPE_ACCOUNT',
    },
  }),
  target: getInternalTransferSources,
})

export const $accounts = restore(
  getStartCopyingAccounts.doneData.map((res) => res.body.items),
  [],
)

export const $internalTransferSources = restore(
  getInternalTransferSources.doneData.map((res) => res.body),
  null,
)

export const $paymentAvailabilityParams = restore(
  checkPaymentAvailability.doneData.map((res) => res.body),
  null,
)

export const changeStrategiesFiltersField = createEvent<{
  field: string
  value: any
}>()

export const resetStrategiesFilters = createEvent()

export const $strategiesFilters = createStore({
  'filter.isFavorites': false,
  'filter.copyingAccountIDs': [] as string[],
  'filter.platform': null as string | null,
  'filter.risk': null as string | null,
  'filter.commission.from': null as string | null,
  'filter.commission.to': null as string | null,
  'filter.percentCommission.from': null as string | null,
  'filter.percentCommission.to': null as string | null,
  'filter.strategyName': null as string | null,
  'sorting.field': 'FIELD_AI_ASSESSMENT' as string | null,
})
  .on(changeStrategiesFiltersField, (payload, { field, value }) => ({
    ...payload,
    [field]: value,
  }))
  .reset(resetStrategiesFilters)

export const setPublishedAccount = createEvent<string>()
export const $publishedAccount = restore(setPublishedAccount, null)

export const setKeepStrategyUntil = createEvent<number>()
export const $keepStrategyUntil = restore(setKeepStrategyUntil, null)

persist({
  store: $keepStrategyUntil,
  key: 'keepStrategyUntil',
})
