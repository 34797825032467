import { useWindowSize } from '@uidotdev/usehooks'
import { ReactNode } from 'react'
import { Title } from 'ui/atoms'

import * as Styled from './styled'

export const SimpleMessage = ({
  title,
  children,
}: {
  title: string
  children: ReactNode
}) => {
  const { width } = useWindowSize()
  const isMobile = width && width < 600

  return (
    <Styled.Wrapper>
      <Title level={isMobile ? 3 : 2} className="mb-[16px]">
        {title}
      </Title>
      {children}
    </Styled.Wrapper>
  )
}

export const ColorMessage = ({
  title,
  text,
  children,
}: {
  title: string
  text: string
  children?: ReactNode
}) => {
  const { width } = useWindowSize()
  const isMobile = width && width < 600

  return (
    <Styled.Wrapper colored>
      <Title
        level={isMobile ? 3 : 2}
        className="mb-[16px] text-gray.100 mobile:text-gray.700"
      >
        {title}
      </Title>
      <div className="mb-[24px] text-body.regular leading-body.regular mobile:text-body.medium mobile:leading-body.medium  text-gray.100 mobile:text-gray.700">
        {text}
      </div>
      {children}
    </Styled.Wrapper>
  )
}
