import styled from "styled-components/macro";

export const DayCardWrapper = styled.div`
  margin-top: 32px;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1260px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 599px) {
    margin-top: 16px;
    gap: 16px;
    grid-template-columns: 1fr;
  } 
`

export const DayCards = styled.div<{isYou: boolean; isFriday: boolean}>`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 16px;

  ${({ isYou, isFriday }) => {
    if (isFriday) return `background-color: #009000;`;
    if (isYou) return 'background-color: var(--color-eucalyptus-100);';

    return 'background-color: var(--color-gray-300);';
  }}
  @media (max-width: 600px) {
    width: 100%;
  } 
`;

export const DayCardPlaceholder = styled.div`
  border-radius: 16px;
  padding: 16px;
  border: 1px solid var(--color-gray-400);
  display: flex;
  flex-direction: column;
  /* background-color: var(--color-gray-300); */
  height: 200px;

  @media (max-width: 600px) {
    width: 100%;
  } 
`;

export const OrganizationBlock = styled.div`
  width: 100%;
  background-color: var(--color-gray-200);
  padding: 24px 20px;
  border-radius: 8px;

  // Desk
  @media (min-width: 1280px) {
    width: 880px;
    padding: 32px 24px;
  } 
`;

export const OrganizationCard = styled.div`
  border-radius: 8px;
  background-color: white;
  flex: 1;
  padding: 24px 20px 16px 20px;

  // Desk
  @media (min-width: 1024px) {
    padding: 24px
  } 
`;
