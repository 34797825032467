import { path } from 'libs/path'
import { useEffect, useRef, useState } from 'react'
import { useGetExchangerWallets } from 'services/wallet/get/useGetExchangerWallets'

import { MenuItemProps } from '../../ui/molecules/menu'
import { useCountryCurrent } from 'libs/hooks/useCountryCurrent'

export const menuItemsObj: MenuItemProps[] = [
  {
    icon: 'squareMenu',
    path: path.dashboard.entry(),
    label: 'Dashboard',
  },
  {
    icon: 'rectangleStack',
    path: path.accounts.entry(),
    label: 'My Accounts',
  },
  {
    icon: 'user',
    path: path.ib.entry(),
    label: 'IB Area',
  },
  {
    icon: 'banknotes',
    path: path.money.entry(),
    label: 'Finance',
  },
  {
    icon: 'gift',
    path: path.giftshop.entry(),
    label: 'Giftshop',
  },
  {
    icon: 'arrowsDiagonal',
    path: path.copytrade.investorEntry(),
    label: 'Copytrade',
  },
  {
    icon: 'cup',
    // path: path.contests.entry(),
    label: 'Contests',
    items: [
      {
        icon: 'stars',
        path: path.contests.entry(),
        label: 'Demo',
      }
    ],
  },
  // {
  //   icon: 'newYear',
  //   path: path.your2024(),
  //   label: 'Your 2024',
  // },
]

const exchangerMenuPoint = {
  icon: 'exchange',
  path: path.exchanger.entry(),
  label: 'Exchanger Area',
} as MenuItemProps

const ramadanMenuPoint = {
  icon: 'ramadan',
  path: path.ramadan.entry(),
  label: 'Ramadan',
  isNew: true
} as MenuItemProps

export const useGetSidebar = () => {
  const isExchangerAreaAddRef = useRef(false);
  const isRamadanAddRef = useRef(false);

  const [menuItems, setMenuItems] = useState(menuItemsObj)
  const {
    data: exchangerWallets,
    isLoading,
  } = useGetExchangerWallets({ noRefetch: true });

  const { country, isLoading: countryIsLoading } = useCountryCurrent();

  // RAMADAN
  useEffect(() => {
    if (countryIsLoading) return;
    
    if (!country?.isMuslim) {
      if (isRamadanAddRef.current) {
        const newMenus = menuItems.map(item => {
          if (item.label === 'Contests') {
            item.items = [
              {
                icon: 'stars',
                path: path.contests.entry(),
                label: 'Demo',
              }
            ]
          }
          return item;
        });
        setMenuItems(newMenus);
        isRamadanAddRef.current = false;
        return;
      }

      return;
    }
    
    if (isRamadanAddRef.current) return;

    const newMenus = menuItems.map(item => {
      if (item.label === 'Contests') {
        // item.items?.push(ramadanMenuPoint);
        return {
          ...item,
          items: item.items?.length ? [...item.items, ramadanMenuPoint] : [ramadanMenuPoint],
        }
      }
      return item;
    });
    setMenuItems(newMenus);

    isRamadanAddRef.current = true;
  }, [country, menuItems]);

  // Exchanger Area
  useEffect(() => {
    if (isLoading) return
    
    if (!exchangerWallets?.items?.length) {
      isExchangerAreaAddRef.current = false
      return
    }

    if (isExchangerAreaAddRef.current) return

    const newMenus = [...menuItems]
    newMenus.splice(3, 0, exchangerMenuPoint);
    setMenuItems(newMenus)

    isExchangerAreaAddRef.current = true
  }, [exchangerWallets, menuItems]);

  // console.log(menuItems);
  
  
  return menuItems
}
