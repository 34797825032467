import { useCallback, useEffect, useState } from "react";
import { useStoreMap, useUnit } from "effector-react";

import { useGetWithdrawalLimits } from "services/payments/mutations/useGetWithdrawalLimits";
import { CurrenciesItem } from "services/payments/get/DepositFormTypes";

import { $amountField, $calcAmount, $destenation, onResetCalcAmountEvent, onSetAmountCurrencyEvent, updateAmountFieldEvent } from "../model";
import { $customGatewayId } from "../../model";


export const useCurrency = (defaultCurrencyObj: CurrenciesItem) => {
  const gatewayId = useUnit($customGatewayId);
  const { destenationCurrency, isFixRate, destinationID, destinationType } = useStoreMap($destenation, (state) => ({ 
    destenationCurrency: state?.currency || '',
    isFixRate: state?.isFixRate,
    destinationType: state?.type!,
    destinationID: state?.id!
  }));

  const calcAmount = useUnit($calcAmount);
  const activeCurrency = useStoreMap($amountField, (state) => (state!.activeCurrency || defaultCurrencyObj.currencyCode));

  const [limits, setLimits] = useState({
    min: defaultCurrencyObj.minLimit,
    max: defaultCurrencyObj.maxLimit,
  });

  const { mutateAsync, isLoading } = useGetWithdrawalLimits();

   const onFetchLimits = useCallback(
    async (newCurrency: string) => {
      if (!gatewayId) return;

      try {
        let currency = newCurrency;
        if (isFixRate && newCurrency === 'USD') currency = 'USF';

        const resp = await mutateAsync({ 
          gatewayID: gatewayId,
          currency,
          destinationType,
          destinationID,
        });

        setLimits({
          max: resp.maxLimit,
          min: resp.minLimit
        })
      } catch (error) {
      }
    },
    [gatewayId, isFixRate, destinationType, destinationID],
  );

  const isFlipCurrency = defaultCurrencyObj.currencyCode !== destenationCurrency;
  
  const onFlipCurrency = useCallback(
    () => {
      if (!isFlipCurrency) return;

      const newCurrency = activeCurrency === defaultCurrencyObj.currencyCode 
        ? destenationCurrency 
        : defaultCurrencyObj.currencyCode;
      
      if (calcAmount && calcAmount.currency === newCurrency) {
        updateAmountFieldEvent({
          activeCurrency: calcAmount.currency,
          amount: calcAmount.convertedAmount,
        });
        return;
      }

      onSetAmountCurrencyEvent(newCurrency);
    },
    [activeCurrency, defaultCurrencyObj.currencyCode, destenationCurrency, isFlipCurrency, calcAmount],
  );

  // Меняем лимиты и активную валюту, если валюта аккаунта равна валюте гетвея
  useEffect(() => {
    if (isFlipCurrency) return;

    // setActiveCurrency(defaultCurrencyObj.currencyCode);
    onSetAmountCurrencyEvent(defaultCurrencyObj.currencyCode);
    // setLimits({
    //   max: defaultCurrencyObj.maxLimit,
    //   min: defaultCurrencyObj.minLimit,
    // });
    onResetCalcAmountEvent();
  }, [defaultCurrencyObj, isFlipCurrency]);

  useEffect(() => {
    if (!activeCurrency || activeCurrency !== defaultCurrencyObj.currencyCode) return;
    onSetAmountCurrencyEvent(defaultCurrencyObj.currencyCode);
  }, []);

  useEffect(() => {
    onFetchLimits(activeCurrency);
  }, [activeCurrency, destinationID, destinationType, onFetchLimits]);
  
  
  return {
    activeCurrency,
    limits,
    isFlipCurrency,
    isLoadingLimits: isLoading,
    onFlipCurrency
  }
}