import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Modal } from 'ui/molecules/modal_draft'
import { useIsMobile } from 'libs/hooks/useIsMobile';
import { Title, Text, Button } from 'ui/atoms';
import { path } from 'libs/path';

import { ContestFooterModal } from './ContestFooterModal';
import { LeaderBoard } from './LeaderBoard';


export const ContestFooter = () => {
  const [isModal, setIsModal] = useState(false);
  const isMobile = useIsMobile();
  const nav = useNavigate();

  const onDeposit = () => {
    nav(path.money.depositTab());
  }

  return (
    <div className='xl:w-[880px] lg:flex-row flex-col flex gap-[20px]'>
      <LeaderBoard />

      <div className='md:min-h-auto min-h-[260px] lg:w-[360px] w-full md:px-[24px] px-[20px] md:py-[32px] py-[24px] bg-eucalyptus.100 rounded-[8px]'>
        <Title level={isMobile ? 3 : 2} className='md:text-left text-center'>
          Prizes and conditions
        </Title>
        <Text level={1} className='md:text-left text-center mt-[16px]'>
          Everything you need to know about the Headway Ramadan Challenge.
        </Text>

        <Button className='mt-[24px] w-full' design='secondary' onClick={() => setIsModal(true)}>
          See details
        </Button>
      </div>

        <Modal
          title=""
          open={isModal}
          width={isMobile ? undefined : 560}
          footer={false}
          isScrollContent
          overlayClasses='!items-baseline'
          onClose={() => setIsModal(false)}
        >
          <ContestFooterModal />
        </Modal>
    </div>
  )
}
