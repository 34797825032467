import { createEvent, createStore, restore, sample } from 'effector'
import { fx } from 'libs'

import { User, UserProfile, UserRole } from './types'

export const updateUser = createEvent<Partial<UserProfile>>()
export const setUserProfile = createEvent<UserProfile>()
export const setUserInitial = createEvent<UserProfile>()
export const setUserEmail = createEvent<string>()

export const $userProfile = restore(setUserProfile, {
  userID: '',
  firstName: '',
  lastName: '',
  email: '',
  country: '',
  isEmailConfirmed: false,
  avatarURL: '',
  isVerified: false,
  userRoles: null,
})

export const getUser = fx<void, User>({
  method: 'GET',
  url: '/user/v1/user',
})

// for GTM
// todo: remove duplicates
export const getUserAfterLoginByEmail = fx<void, User>({
  method: 'GET',
  url: '/user/v1/user',
})
export const getUserAfterLoginBySocial = fx<void, User>({
  method: 'GET',
  url: '/user/v1/user',
})
export const getUserAfterRegisterBySocial = fx<void, User>({
  method: 'GET',
  url: '/user/v1/user',
})

export const setUserAuthRole = createEvent<any>()
export const setUserNonAuthRole = createEvent<any>()

export const $userRole = createStore<UserRole | null>(null)
  .on(setUserAuthRole, () => 'authorized')
  .on(setUserNonAuthRole, () => 'unauthorized')

export const $isUserEmailConfirmed = createStore<boolean | null>(null)

// check if user email confirmed

sample({
  clock: getUser.doneData,
  fn: (doneData) => doneData.body.user.isEmailConfirmed,
  target: $isUserEmailConfirmed,
})

// update profile model

sample({
  clock: getUser.doneData,
  fn: (doneData) => ({
    ...doneData.body.user,
    userRoles: doneData.body.userRoles,
  }),
  target: setUserProfile,
})

sample({
  clock: getUser.doneData,
  fn: () => {
    const role: UserRole = 'authorized'
    return role
  },
  target: $userRole,
})

// updated user name

sample({
  source: $userProfile,
  clock: updateUser,
  fn: (currentUserData, newUserData) => {
    return {
      ...currentUserData,
      ...newUserData,
    }
  },
  target: setUserProfile,
})

// initial event in UserContainer(first load)

sample({
  clock: setUserInitial,
  target: [setUserProfile, setUserAuthRole],
})

sample({
  clock: setUserInitial,
  fn: (user) => user.isEmailConfirmed,
  target: $isUserEmailConfirmed,
})

// set user email

sample({
  source: $userProfile,
  clock: setUserEmail,
  fn: (source, email) => {
    return { ...source, email }
  },
  target: $userProfile,
})

// zendesk helper
export const setIsChatLoaded = createEvent<boolean>()

export const $isChatLoaded = restore(setIsChatLoaded, false)