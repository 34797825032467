import React, { useCallback } from 'react';

import { useGetKitBanners } from 'services/banners/get/useGetKitBanners';
import { RegBonus } from './RegBonus';

export const Banners = () => {
  const { data: bannersData } = useGetKitBanners({section: 'SECTION_FINANCE'});

  const getBanner = useCallback(
    () => {
      if (!bannersData?.items?.length) return null;

      const bannerComp: any[] = [];

      bannersData.items.forEach(item => {
        if (item.name === 'finance.depositBonus.regBonus') {
          bannerComp.push(<RegBonus name={item.name} item={item.params as any} canBeClosed={item.canBeClosed} />) 
        }

        return null
      });
      
      return bannerComp;
    },
    [bannersData],
  );

  if (!bannersData?.items?.length) return null;

  return (
    <div className='mt-[24px] w-full flex justify-center'>
      {getBanner()}
    </div>
  )
}
