import React, { useDeferredValue, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next';
import { useGetTickets } from 'services/ramadan/get/useGetTickets'
import { Icon2, Input, Spinner2, Text } from 'ui/atoms';

import { TicketsList } from './TicketsList';

interface Props {
  dayID: number;
  isOpen: boolean;
}

export const TicketsModal: React.FC<Props> = ({ dayID, isOpen }) => {
  const { data, isLoading, error } = useGetTickets({ dayID, enabled: isOpen });

  const [t] = useTranslation();

  const [ticketSearch, setTicketSearch] = useState('');
  const searchDeffered = useDeferredValue(ticketSearch);

  const isMore15Ticket = (data?.ticketIDs?.length || 0) >= 15;
  
  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value?.replace(/\D/g, '');

    setTicketSearch(val);
  }

  const searhedList = useMemo(() => {
    if (!data?.ticketIDs?.length) return [];

    return data.ticketIDs.filter(item => item?.toString()?.includes(searchDeffered));
  }, [searchDeffered, data]);


  const isEmptySearch = useMemo(() => {
    if (!data?.ticketIDs?.length) return false;
    if (!searchDeffered) return false;

    return searhedList.length < 1;
  }, [data, searhedList, searchDeffered]);

  if (isLoading) return (
    <Spinner2 size='medium' />
  )

  if (!data || error) return null;

  return (
    <div className='md:max-h-[600px] max-h-[600px] overflow-y-scroll'>
      <Text className="text-center" level={1} withTranslate={false}>
        <Trans i18nKey="Trading day 5 of 20:" values={{ value: dayID }} />
      </Text>

      {isMore15Ticket && (
        <div className="w-full flex justify-center mt-[16px]">
          <Input
            name="ticketName"
            value={ticketSearch}
            inputClasses="h-[40px]"
            className="lg:!w-[300px] w-full"
            onChange={onChangeSearch}
            placeholder="Search number"
            errorMessage={isEmptySearch ? t('Number not found') as string : undefined}
            state={isEmptySearch ? 'error' : undefined}
            suffix={<Icon2 name="magnifyingGlassIcon" />}
            suffixType="icon"
          />
        </div>
      )}

      <div className="lg:mt-[24px] mt-[16px]">
        <TicketsList items={searhedList} isMore15Ticket={isMore15Ticket} />
      </div>
    </div>
  )
}
