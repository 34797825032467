import {
  Field as FormikField,
  FieldProps as FormikFieldProps,
} from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { InputState } from '../input'
import { Select } from '../select'
import { FieldSelectProps } from '.'
import { A3codes } from 'libs/A3codes'
import { useDebounce } from 'usehooks-ts'

export const LegacyCountrySelect = ({
  className,
  value = '',
  name,
  label,
  placeholder,
  onSelect,
  disabled,
  error,
  isHaveSearch,
  defaultValue = '',
  validate = true,
}: FieldSelectProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  // Вспомогательный стейт для поиска страны
  const [input, setInput] = useState('');
  // Вэлью для выбранного элемента, он прокидывается наверх и является значением формы
  const [choosenCountry, setChoosenCountry] = useState(defaultValue);

  const [isDefaultSearch, setIsDefaultSearch] = useState(true);

  const inputDebounce = useDebounce(input, 200);

  const countryList = useMemo(() => {
    const getHidden = (country: string) => {
      let hidden = false;
      if (!!inputDebounce && !isDefaultSearch) {
        hidden = !country.toLowerCase().includes(inputDebounce.toLowerCase());
      }
      
      return hidden;
    }

    return A3codes.map((country) => ({
      value: country.code,
      label: country.country,
      hidden: getHidden(country.country),
    }))
  }, [inputDebounce]);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    setIsDefaultSearch(false);
  }

  const onSelectOpt = (val: string) => {
    onSelect?.(val);
    setChoosenCountry(val);
    setIsDefaultSearch(false);
  };

  const onFindCountryByCode = useCallback((code: string) => {
    const finded = countryList.find(item => item.value === code);
    return finded;
  }, []);

  const onFindCountryByLabel = useCallback((code: string) => {
    const finded = countryList.find(item => item.label === code);
    return finded;
  }, []);

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
  }

  const onBlurSelect = (blurFn: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const finded = onFindCountryByLabel(input);

    const newValue = finded?.value || '';
    
    setChoosenCountry(newValue)
    setInput(finded?.label || '');
    onSelect?.(newValue);

    blurFn?.(e);
    setIsFocused(false);
  }

  useEffect(() => {
    const finded = onFindCountryByCode(value);
    if (!finded) return;
    
    setChoosenCountry(finded.value);
  }, [value]);

  useEffect(() => {
    const finded = onFindCountryByCode(choosenCountry);
    
    setInput(finded?.label || '');
  }, [choosenCountry]);

  const [t] = useTranslation();

  return (
    <FormikField name={name}>
      {(props: FormikFieldProps) => {
        const { field, meta } = props
        const { touched } = meta
        const { onBlur } = field

        const isError =
          touched && validate && (Boolean(error) || Boolean(meta.error))
        const isValid =
          touched && validate && !(Boolean(meta.error) || Boolean(error))

        let errMsg = 'error'

        if (error) errMsg = error
        if (meta.error) errMsg = meta.error

        let state: InputState = 'hint'

        if (isError) state = 'error'
        if (isValid) state = 'valid'

        return (
          <FieldWrap className={className} data-name={name}>
            <Select
              name={name}
              className={className}
              value={isFocused ? input : choosenCountry}
              label={label}
              options={countryList}
              onChangeInput={onChangeInput}
              isHaveSearch={isHaveSearch}
              placeholder={placeholder}
              onFocus={onFocus}
              disabled={disabled}
              autoComplete='off'
              onBlur={onBlurSelect(onBlur)}
              onSelect={onSelectOpt}
              state={state}
            />
            {isError && <FieldState state={state}>{t(errMsg)}</FieldState>}
          </FieldWrap>
        )
      }}
    </FormikField>
  )
}

const FieldWrap = styled.div``

type FieldStateProps = {
  state: InputState
}

const fieldMap = ({ state }: FieldStateProps) => ({
  'data-state': state,
})

const FieldState = styled.div.attrs(fieldMap)<FieldStateProps>`
  font-size: var(--body-font-size-medium);
  line-height: 1;
  color: var(--color-gray-600);
  margin-top: 6px;

  &[data-state='error'] {
    color: var(--color-opal-600);
  }

  &[data-state='validated'] {
    color: var(--color-eucalyptus-600);
  }
`