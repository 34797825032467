import React, { useState } from 'react'
import { useGetPartnerPage } from 'services/ib/get/useGetPartnerPage'
import { Icon2, Input, Text, Title } from 'ui/atoms'
import { CopyIcon } from 'ui/molecules/CopyButton/CopyIcon'

import { WithdrawalModal } from '../modals/WithdrawalModal'
import { HaventIbBannerWidget } from '../molecules/promo/HaventIbBannerWidget'
import { statusInfoObj } from '../utils/statuses'
import { ProgressBlock } from './ProgressBlock'
import * as S from './styled'

export const IBDashboardWidget = () => {
  const { data, isLoading, error, isRefetching } = useGetPartnerPage()

  const [isOpenModal, setIsOpenModal] = useState(false)

  if (!data || isLoading) return null

  const statusObj = statusInfoObj[data?.widgetInfo?.partnerStatus]

  // @ts-ignore
  if (
    data?.widgetInfo.partnerStatus === 'PARTNER_STATUS_NOT_PARTNER' &&
    !isLoading
  )
    return <HaventIbBannerWidget />

  return (
    <S.Container>
      <S.Wrapper>
        <div className="flex">
          <Title level={3}>IB Area</Title>
          <Icon2 name="user" className="ms-[8px] text-sunray.500" />
        </div>

        <div className="flex mt-[1.2rem] items-start flex-col lg:flex-row lg:items-baseline">
          <div className="flex flex-col">
            <div className="flex items-center">
              <Text className="!text-gray.600 !inline">Current status</Text>
            </div>

            <div className="flex mt-[1.2rem] items-end">
              <Icon2
                size="custom"
                name={statusObj.iconName}
                className="w-[32px] h-[32px] me-[1.2rem]"
              />
              <Title level={2}>{statusObj.label}</Title>

              <div className="ms-[12px]">
                <Text className="!inline !text-gray.600">Commission</Text>
                <Text
                  className="!font-medium !inline ms-2"
                  withTranslate={false}
                >
                  {statusObj.comission}%
                </Text>
              </div>
            </div>
          </div>

          <div className="lg:ms-[40px] mt-[20px] lg:mt-[0px]">
            <Text className="!text-gray.600 !inline">IB Wallet</Text>
            <div className="mt-[12px] flex items-center">
              <Title level={2} withTranslate={false}>
                ${data.widgetInfo.withdrawalAmount}
              </Title>
              {Boolean(+data.widgetInfo.withdrawalAmount) && (
                <Icon2
                  name="arrowDownOnSquare"
                  className="ms-[10px] text-bronze.500 cursor-pointer"
                  onClick={() => setIsOpenModal(true)}
                />
              )}
            </div>
          </div>
        </div>

        {data.widgetInfo.partnerLink && (
          <div className="mt-[2.4rem]">
            <Input
              name="link"
              value={data.widgetInfo.partnerLink}
              autoFocus={false}
              autoComplete="off"
              suffixType="button"
              inputClasses='!pe-[48px]'
              suffix={
                <CopyIcon
                  tooltipText="Copied"
                  copiedText={data.widgetInfo.partnerLink}
                  copyIconClass="text-sunray.500"
                />
              }
            />
          </div>
        )}

        {
          <ProgressBlock
            currentStatus={data.widgetInfo.partnerStatus}
            upgrading={data.widgetInfo?.upgrading || {}}
            confirmStatus={data.widgetInfo.confirmStatus}
          />
        }
      </S.Wrapper>

      <WithdrawalModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        maxAmount={+data.widgetInfo.withdrawalAmount}
      />
    </S.Container>
  )
}
