import React from 'react'
import { Toast } from 'ui/molecules/toasts/ToastItem'
import { useToasts } from './useToasts'

export const ToastsContainer = () => {

  const { toast, onCloseToast } = useToasts();

  return (
    <div className='fixed bottom-[20px] w-full flex justify-center z-40 px-[8px] md:px-0'>
      {
        toast && (
          <Toast
            onCloseToast={onCloseToast}
            { ...toast }
          />
        )
      }
    </div>
  )
}
