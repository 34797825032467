import { useWindowSize } from '@uidotdev/usehooks'
import { useTranslation } from 'react-i18next'
import { Hint } from 'ui/atoms'
import { toCurrency } from 'ui/atoms/money'

import * as Styled from './styled'
import backgroundMobileRtl from './week-mobile-rtl.webp'
import backgroundMobile from './week-mobile.webp'
import backgroundRtl from './week-rtl.webp'
import background from './week.webp'

export const WeekPrizes = ({
  bestTrader,
  lucky,
  title,
}: {
  title: string
  bestTrader: number
  lucky: number
}) => {
  const [t] = useTranslation()

  const { width } = useWindowSize()
  const isMobile = width && width < 600

  return (
    <Styled.Wrapper
      backgroundColor="var(--color-eucalyptus-100)"
      backgroundImage={isMobile ? backgroundMobile : background}
      rtlBackgroundImage={isMobile ? backgroundMobileRtl : backgroundRtl}
    >
      <Styled.Title level={3}>{title}</Styled.Title>
      <Styled.Grid>
        <div>
          <Hint
            textContent={t(
              'This is defined by the maximum number of lots traded during the 24 hours.',
            )}
          >
            {t('Trader of the day')}
          </Hint>
          <Styled.Value>{toCurrency(bestTrader, 'USD', 0)}</Styled.Value>
        </div>
        <div>
          <Hint
            textContent={t(
              'This is defined randomly. The choice is based on the number of lucky tickets you received for every 0.1 lot traded during the 24 hours.',
            )}
          >
            {t('Lucky winner')}
          </Hint>
          <Styled.Value>{toCurrency(lucky, 'USD', 0)}</Styled.Value>
        </div>
      </Styled.Grid>
    </Styled.Wrapper>
  )
}
