import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { BannerHeadKit } from 'services/banners/get/useGetHeadBanner';
import { useHideHeadBanner } from 'services/banners/mutations/useHideHeadBanner';
import { path } from 'libs/path';
import { Button, Icon2, Text } from 'ui/atoms';

import coins from './coins.png';
import clouds from './clouds.png';
import bonus from './75Bonus.png';
import { useIsMobile } from 'libs/hooks/useIsMobile';


interface Props {
  banner: BannerHeadKit;
}

export const RegBonus: React.FC<Props> = ({ banner }) => {
  const { mutateAsync } = useHideHeadBanner();

  const isMobile = useIsMobile();
  const [t] = useTranslation();

  const navigate = useNavigate();
  const onGoToDeposit = () => {
    navigate(path.money.depositTab());
  }

  const onHideBanner = async () => {
    try {
      await mutateAsync(banner.name);
    } catch (error) {
      
    }
  }

  return (
    <BannerBgStyled 
      className={`
        h-[56px] w-full z-40
        relative
      `}
    >
      {!isMobile && <LazyLoadImage src={clouds} className='h-[56px] absolute left-0 top-0'/>}
      <Text level={isMobile ? 2 : 1} color='main' weight='medium'>
        {isMobile 
          ? `Get +${banner.params.extraDepositPercents}% to your first deposit!`
          : t(`Get to your first deposit! Be quick – the offer is limited`, { value: banner.params.extraDepositPercents })
        }
      </Text>
      {!isMobile && 
        <Button type='button' design='secondary' className='!h-[42px] min-w-[130px] ml-[16px]' onClick={onGoToDeposit}>
          Let’s deposit
        </Button>
      }
      {!isMobile && <LazyLoadImage src={bonus} className='!h-[56px] ml-[63px]'/>}
      <div className='h-full cursor-pointer absolute right-0 top-0 flex items-center  md:mr-[18px] mr-[12px]' onClick={onHideBanner}>
        <Icon2 name='xMarkIcon' className='' size={isMobile ? 'small' : 'medium' } />
      </div>
    </BannerBgStyled>
  )
}

const BannerBgStyled = styled.div`
  background-color: #A1BAFF;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
`