import { useIsMobile } from 'libs/hooks/useIsMobile';
import React, { useEffect, useMemo } from 'react';

import { Icon2, Text } from 'ui/atoms';


export type ToastTypes = 'error' | 'success';
export interface ToastProps {
  id?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  type: ToastTypes;
  hideCloseIcon?: boolean;
  duration?: number;
  onCloseToast?: () => void;
}

export const TOAST_60_SEC_TIME = 60000;

export const Toast: React.FC<ToastProps> = ({ type, title, description, onCloseToast, hideCloseIcon, duration }) => {
  const isMobile = useIsMobile();

  const getIconName = useMemo(() => {
    if (type === 'error') return <Icon2 name='xcircleIcon' className='!text-opal.500' />
    if (type === 'success') return <Icon2 name='checkCircle' className='!text-eucalyptus.500' />

    return <Icon2 name='checkCircle' className='!text-eucalyptus.500' />;
  }, [type]);


  useEffect(() => {
    if (!duration) return;

    const timer = setTimeout(() => {
      onCloseToast?.();
    }, duration);

    return () => clearTimeout(timer);
  }, []);
  

  return (
    <div className='w-[560px] min-h-[84px] bg-gray-800 py-[16px] px-[12px] flex gap-[8px]'>
      <div>
        {getIconName}
      </div>

      <div className='flex-1'>
        {title && typeof title === 'string' 
          ? <Text className='!text-white' level={1} weight='medium'>{title}</Text>
          : title
        }
        {description && typeof description === 'string' 
          ? <Text className='!text-white' level={isMobile ? 2 : 1}>{description}</Text>
          : description
        }
      </div>

      {!hideCloseIcon && 
        <div>
          <Icon2
            name='xMarkIcon'
            className='!text-white cursor-pointer'
            onClick={onCloseToast}
          />;
        </div>
      }
      
    </div>
  )
}
