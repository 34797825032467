import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TransactionItem } from 'services/money/get';
import { Money, Tag } from 'ui/atoms';

import * as Styled from './styled';

interface Props {
  data: TransactionItem;
}

export const TransactionMobile: React.FC<Props> = ({ data }) => {
  const [t] = useTranslation();

  const dstAmount = (
    <>
      {data.dstAmount.isNegative ? '-' : ''}
      <Money currency={data.dstAmount?.currency} value={+data.dstAmount?.value} />
    </>
  )

  const srcAmount = data.srcAmount && (
    <>
      {data.srcAmount.isNegative ? '-' : ''}
      <Money currency={data.srcAmount.currency} value={+data.srcAmount.value} />
    </>
  )

  const getTagDesign = useMemo(() => {
    if (!data.label) return 'gray';

    if (data.label.background === 'BACKGROUND_GREEN') return 'eucalyptus';
    if (data.label.background === 'BACKGROUND_YELLOW') return 'sunray';

    return 'gray'
  }, [data.label]);

  return (
    <Styled.InfoGrid>
      <Styled.TypeWrapper>
        {t(data.name)} &nbsp;
        {data?.label && <Tag design={getTagDesign}>{t(data.label.name)}</Tag>}
      </Styled.TypeWrapper>
      <Styled.Status status={data.status.level} small>
        {t(data.status.name)}
      </Styled.Status>
      <Styled.InfoRow>
        <div>
          <Styled.LightText>{t('From')}</Styled.LightText>
          {data.srcName}
        </div>
        
        <Styled.RightAlign>{srcAmount}</Styled.RightAlign>
    
        <div>
          <Styled.LightText>{t('To')}</Styled.LightText>
          {data.dstName}
        </div>

        <Styled.RightAlign>
          {dstAmount}
        </Styled.RightAlign>
        
      </Styled.InfoRow>
    </Styled.InfoGrid>
  )
}
