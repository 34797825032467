import { useIsMobile } from 'libs/hooks/useIsMobile';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon2, Link, Text, Title } from 'ui/atoms';

import desktopBackground from '../../img/partnershipBannerDesktop.jpg'
import mobileBackground from '../../img/partnershipBannerMobile.png'
import * as S from './styled';

interface Props {
  onClose: () => void;
}

export const ThreeLevelBanner: React.FC<Props> = ({ onClose }) => {
  const isMobile = useIsMobile();
  const [t] = useTranslation();

  const onCloseBanner = () => {
    localStorage.setItem('IB_BANNER', 'true');
    onClose();
  }

  return (
    <S.BannerBlock
      url={isMobile ? mobileBackground : desktopBackground}
      className="prevent-rtl"
    >
      <S.Icon
        name="xMarkIcon"
        className="text-gray.400 !bg-inherit"
        onClick={onCloseBanner}
      />

      <div className="lg:w-[47rem] w-full flex flex-col h-full justify-center text-left">
        <Title level={isMobile ? 3 : 1} className="text-sunray.500 z-10">
          Three-level Partnership
        </Title>
        <div className="mt-[1.2rem] md:w-full w-[212px]">
          <Text
            className="!text-white xl:!inline z-10 text-left"
            level={isMobile ? 4 : 1}
            withTranslate={false}
          >
            {t(
              'We offer unique Partnership levels! When you attract new Partners, you will get rewarded for that as well.',
            )}
            <Link
              to="http://headway.work/partnership/commissions/"
              isOpenInNewTab
              className="!leading-[10px]"
            >
              <Text
                level={isMobile ? 4 : 1}
                weight="medium"
                className="!inline !text-bronze.500 ms-2 cursor-pointer"
              >
                Learn more
              </Text>
            </Link>
          </Text>
        </div>
      </div>
    </S.BannerBlock>
  )
}
