import * as React from 'react'
import styled from 'styled-components/macro'

import { toPath } from '../../../../../features/router'
import { path } from '../../../../../libs'
import { Button, Text, Title } from '../../../../../ui/atoms'
import progress from './images/progress.svg'

export const InProgressPage = () => {
  return (
    <Container>
      <InProgressLogo />
      <Content>
        <Title level={3}>Verification is in progress</Title>
        <Text level={2}>
          Your application is being processed. The estimated response time is
          1—2 hours. Thank you for understanding!
        </Text>
        {/* <Text level={2}>
          If you need to change your request, please click Edit Application.
        </Text> */}
      </Content>
      <Footer>
        {/* <Back to={path.dashboard.verification.entry()} /> */}
        <Button
          name="navigate-to-dashboard"
          onClick={() => toPath(path.dashboard.entry())}
        >
          Dashboard
        </Button>
      </Footer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 500px;
`

const InProgressLogo = styled.div`
  width: 200px;
  height: 200px;
  background-image: url(${progress});
  background-size: contain;
  background-repeat: no-repeat;
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-top: 32px;

  button {
    margin: 0;
    width: 220px;
  }
`
