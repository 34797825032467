import { GeneratePassword } from 'pages/accounts/generatePassword'
import { Account } from 'pages/accounts/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, Title } from 'ui/atoms'
import { getMetaTraderLink } from 'ui/molecules/banners/metaTraders'
import mt4 from 'ui/molecules/banners/metaTraders/img/mt4.png'
import mt4Web from 'ui/molecules/banners/metaTraders/img/mt4.webp'
import mt5 from 'ui/molecules/banners/metaTraders/img/mt5.png'
import mt5Web from 'ui/molecules/banners/metaTraders/img/mt5.webp'
import { useWindowSize } from 'usehooks-ts'

import * as Styled from './styled'
import { CopyIcon } from '../CopyButton/CopyIcon'

type TProps = {
  details: Account
}

export const TradeModal: React.FC<TProps> = ({ details }) => {
  const [t] = useTranslation()

  const { width } = useWindowSize()
  const { mtServerInfo, login, password, accountID, platform, type } = details

  return (
    <Styled.MetaTradersListWrapper>
      <Styled.MetaTradersList>
        {platform.includes('4') && getMetaTraderLink('mt4') ? (
          <Styled.MetaTradersItem url={mt4Web} defaultUrl={mt4}>
            <Title level={4}>Download MetaTrader 4</Title>
            <div>
              <a href={getMetaTraderLink('mt4')}>
                <Icon name="arrowDownTray" />
              </a>
            </div>
          </Styled.MetaTradersItem>
        ) : null}
        {platform.includes('5') && getMetaTraderLink('mt5') ? (
          <Styled.MetaTradersItem url={mt5Web} defaultUrl={mt5}>
            <Title level={4}>Download MetaTrader 5</Title>
            <a href={getMetaTraderLink('mt5')}>
              <Icon name="arrowDownTray" />
            </a>
          </Styled.MetaTradersItem>
        ) : null}
        {width >= 1024 && platform.includes('4') && (
          <Styled.MetaTradersItem url={mt4Web} defaultUrl={mt4}>
            <Title level={4}>Open MetaTrader 4 web</Title>
            <a
              href={getMetaTraderLink('mt4-web')}
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="arrowTopRightOnSquare" />
            </a>
          </Styled.MetaTradersItem>
        )}
        {platform.includes('5') ? (
          type === 'PAAT_REAL' ? (
            <Styled.MetaTradersItem url={mt5Web} defaultUrl={mt5}>
              <Title level={4}>Open MetaTrader 5 web real</Title>
              <a
                href={getMetaTraderLink('mt5-webReal')}
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="arrowTopRightOnSquare" />
              </a>
            </Styled.MetaTradersItem>
          ) : (
            <Styled.MetaTradersItem url={mt5Web} defaultUrl={mt5}>
              <Title level={4}>Open MetaTrader 5 web demo</Title>
              <a
                href={getMetaTraderLink('mt5-webDemo')}
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="arrowTopRightOnSquare" />
              </a>
            </Styled.MetaTradersItem>
          )
        ) : null}
      </Styled.MetaTradersList>
      <div>
        <Title level={4}>MetaTrader settings</Title>
        <Styled.MetaTradersSettingsList>
          <Styled.MetaTradersSettingsItem>
            <div>{t('Server')}</div>
            <Styled.MetaTradersSettingsItemValue>
              {mtServerInfo.name}
              <CopyIcon
                tooltipText='Copied'
                copiedText={ mtServerInfo.name }
                iconSize='small'
                onCopied={(e) => e.stopPropagation()}
              />
            </Styled.MetaTradersSettingsItemValue>
          </Styled.MetaTradersSettingsItem>
          <Styled.MetaTradersSettingsItem>
            <div>{t('Login')}</div>
            <Styled.MetaTradersSettingsItemValue>
              <div>{login}</div>
              <CopyIcon
                tooltipText='Copied'
                copiedText={ login }
                iconSize='small'
                onCopied={(e) => e.stopPropagation()}
              />
            </Styled.MetaTradersSettingsItemValue>
          </Styled.MetaTradersSettingsItem>
          <Styled.MetaTradersSettingsItem>
            <div>{t('Password')}</div>
            <Styled.MetaTradersSettingsItemValue>
              <GeneratePassword password={password} accountID={accountID} />
            </Styled.MetaTradersSettingsItemValue>
          </Styled.MetaTradersSettingsItem>
        </Styled.MetaTradersSettingsList>
      </div>
    </Styled.MetaTradersListWrapper>
  )
}
