import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

export const GET_BANNERS_KIT_KEY = 'GET_BANNERS_KIT_KEY';

export type SectionBannerName = 'SECTION_ALL' | 'SECTION_IB' | 'SECTION_FINANCE' | 'SECTION_DASHBOARD';

export interface BannersKitNew {
  items: BannersKitItem[]
}

export interface BannersKitItem {
  canBeClosed: boolean;
  name: string;
  params: Record<string, any>
}

const getKitBanners = async (section: SectionBannerName, state?: string) => {
  const res = await axios.get<BannersKitNew>('/contentkit/v1/banners', { 
    params: { 
      section,
      state,
    }
  })

  return res.data
}

interface Options {
  section: SectionBannerName;
  state?: string;
  enabled?: boolean;
}

export const useGetKitBanners = ({section, enabled = true, state}: Options) => {
  return useQuery({
    queryKey: [GET_BANNERS_KIT_KEY, section],
    queryFn: () => getKitBanners(section, state),
    enabled,
  })
}
