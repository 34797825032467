import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { GET_HEAD_BANNERS_KIT_KEY } from 'services/banners/get/useGetHeadBanner';
import { retryFn } from 'services/helpers/retryFn';

const deleteDepositPromocode = async () => {
  const response = await axios.delete<{}>('/deposit-bonus/v1/promo-code')

  return response
}

export const useDelDepositPromocode = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteDepositPromocode,
    onSuccess() {
      queryClient.invalidateQueries([GET_HEAD_BANNERS_KIT_KEY]);
    },
    retry: retryFn,
  })
}
