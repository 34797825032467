import React from 'react';
import { useFacebookLogin } from 'facebook-oauth-react';
import { isAxiosError } from 'axios';

import { usePostLinkSocial } from 'services/user/mutations/usePostLinkSocial';
import { setPageAlert } from 'ui/templates/generic/model';
import { Text } from 'ui/atoms';
import { Spinner2 } from 'ui/atoms';

export const FacebookLogin = () => {
  const { mutateAsync: linkSocialMutate, isLoading } = usePostLinkSocial();

  const facebookLogin = useFacebookLogin({
    onSuccess: async (response) => {
      try {
        await linkSocialMutate({
          accessToken: response.accessToken,
          socialType: 'facebook',
        })
      } catch (error) {
        if (isAxiosError(error)) {
          setPageAlert({
            type: 'error',
            text: error.response?.data?.details?.msg
          })
        }
      }
    },
    onError: () => console.log('Facebook oauth error'),
  })

  if (isLoading) return <Spinner2 />

  return (
    <Text
      className='cursor-pointer !text-sunray.500'
      onClick={ () => facebookLogin() }
    >
      Link the account
    </Text>
  )
}
