import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from 'services/axios';

import { GET_RAMADAN_CONTEST_INFO } from '../get/useGetContestInfo';

const ramadanRegister = async () => {
  const resp  = await axios.post<{isMadeDeposit: boolean}>('/ramadan2025/v1/register');
  return resp.data;
}

export const useRamadanRegister = () => {
  const queryClient = useQueryClient();
  return (
    useMutation({
      mutationFn: ramadanRegister,
      onSuccess() {
        queryClient.invalidateQueries([GET_RAMADAN_CONTEST_INFO]);
      },
    })
  );
}
