import { path } from 'libs/path'
import { accountChoosed } from 'pages/money/depositChoice/model'
import {
  Filters,
  OuterWrapper,
  TransactionList,
  useResetStore,
} from 'pages/money/transactionsNew'
import { resetMoneyHistoryEvent } from 'pages/money/transactionsNew/model'
import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetTransactionFilter } from 'services/money/get'
import { ExchangerWallets } from 'services/wallet/get/types'
import { Spinner2, Title } from 'ui/atoms'

export const MoneyHistory = ({
  data,
  isLoading,
}: {
  data?: ExchangerWallets
  isLoading: boolean
}) => {
  useResetStore()

  const nav = useNavigate();

  const { data: filter, isLoading: filterLoading } = useGetTransactionFilter();

  const exchangerWallets = useMemo(() => {
    if (!filter || !filter.exchangerWallets?.length) return {};
    const exchangerWalletIDs = filter.exchangerWallets.map(item => item.walletID);

    return {
      exchangerWalletIDs,
    }
  }, [data]);

  const notFoundClickFn = () => {
    if (!exchangerWallets?.exchangerWalletIDs?.length) return;

    accountChoosed(exchangerWallets?.exchangerWalletIDs[0]);
  }

  useEffect(() => {
    return () => {
      resetMoneyHistoryEvent();
    }
  }, [])

  useEffect(() => {
    if (isLoading || !data) return

    if (data.items?.length === 0) {
      nav(path.dashboard.entry())
    }
  }, [data, isLoading])

  if (isLoading || !data?.items?.length) {
    return <Spinner2 size="large" />
  }

  if (filterLoading || !filter ) return <Spinner2 size='large' />

  return (
    <OuterWrapper>
      <Title level={2} className="mb-[8px]">
        Transaction History
      </Title>
      <Filters data={filter} type="exchanger" />
      <TransactionList defaultFilter={exchangerWallets} notFoundClickFn={ notFoundClickFn } />
    </OuterWrapper>
  )
}
