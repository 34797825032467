import { useWindowSize } from '@uidotdev/usehooks'
import { MouseEvent, useEffect, useState } from 'react'
import { Icon2 } from 'ui/atoms'

import * as Styled from './styled'
import { ModalProps } from './types'

export const Modal = ({
  open,
  closable = true,
  children,
  onClose,
  onOk,
  onCancel,
  width,
  footer = true,
  title,
  titleClasses = '',
  okLabel = 'OK',
  cancelLabel = 'Cancel',
  overlayClasses = '',
  footerClasses = '',
  icon,
  okButtonProps,
  cancelButtonProps,
  isScrollContent
}: ModalProps) => {
  const { width: screenWidth } = useWindowSize()
  const isMobileMode = screenWidth && screenWidth < 600

  // для красивой анимации
  const [shouldRender, setShouldRender] = useState(open)

  // для закрытия по нажатию на esc
  const handleEsc = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose()
    }
  }

  const handleOk = () => {
    if (onOk) {
      onOk()
    } else {
      onClose()
    }
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    } else {
      onClose()
    }
  }

  const handleOverlayClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    if (closable) {
      onClose()
    }
  }

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'

      setShouldRender(true)

      window.addEventListener('keydown', handleEsc)

      return () => {
        document.body.style.overflow = 'visible'

        window.removeEventListener('keydown', handleEsc)
      }
    } else {
      document.body.style.overflow = 'visible'

      // оставляем модальное окно в dom до завершения анимации
      const timer = setTimeout(() => setShouldRender(false), 250)
      return () => clearTimeout(timer)
    }
  }, [open])

  if (!shouldRender) {
    return null
  }

  return (
    <Styled.Overlay
      isOpen={open}
      onClick={handleOverlayClick}
      isScrollContent={isScrollContent}
      className={overlayClasses}
    >
      <Styled.Content
        onClick={(event) => event.stopPropagation()}
        width={width}
      >
        {closable && <Styled.CloseButton onClick={onClose} type="button">
          <Icon2 name="xMarkIcon" />
        </Styled.CloseButton>}
        {!!title && (
          <Styled.Title
            level={isMobileMode ? 3 : 2}
            icon={icon}
            className={`
              max-w-[${width || '304px'}] mobile:max-w-[${width || '252px'}]
              ${titleClasses}
            `}
          >
            {title}
          </Styled.Title>
        )}
        <div
          className={
            width
              ? ''
              : `mx-${icon ? 'auto' : '0'} max-w-[304px] mobile:max-w-[252px]`
          }
        >
          {children}
        </div>
        {footer ? (
          <Styled.Footer className={footerClasses}>
            {/* если логика ok и cancel отличается, то показываем кнопку cancel */}
            {!!onOk ? (
              <Styled.Button
                size={isMobileMode ? 'small' : 'medium'}
                design="secondary"
                onClick={handleCancel}
                {...cancelButtonProps}
              >
                {cancelLabel}
              </Styled.Button>
            ) : null}
            <Styled.Button
              size={isMobileMode ? 'small' : 'medium'}
              onClick={handleOk}
              {...okButtonProps}
            >
              {okLabel}
            </Styled.Button>
          </Styled.Footer>
        ) : null}
      </Styled.Content>
    </Styled.Overlay>
  )
}
