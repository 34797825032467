import styled from 'styled-components/macro'

export const Conditions = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--color-gray-200);
  height: 102px;

  @media (min-width: 599px) {
    width: calc(50% - 8px);
  }
`;

export const DailyPrizesCard = styled.div`
  padding: 24px 20px;
  border-radius: 8px;
`;

export const LeaderBoardBanner = styled.div<{url: string}>`
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  border-radius: 8px;
  width: 100%;
  background-color: var(--color-gray-200);
  padding: 24px 20px 50px 20px;
  min-height: 280px;

  @media (min-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`