import React from 'react';
import { useStoreMap } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { CurrenciesItem } from 'services/payments/get/DepositFormTypes';
import { SourceItem } from 'services/payments/get/types';

import { AmountInput } from 'ui/molecules';
import { Icon2 } from 'ui/atoms';
import { useCurrency } from './useCurrency';
import { useAmountField } from './useAmount';
import { $amountField, onChangeAmountEvent } from '../model';
import { CalculatedAmount } from './CalculatedAmount';


type Options = {
  defaultCurrency?: CurrenciesItem;
  destenation?: SourceItem;
  gatewayId: string;
  isDisabled: boolean | undefined;
  amountPrecisions: Record<string, number>;
}

export const AmountField = ({ defaultCurrency, isDisabled, amountPrecisions = {} }: Options) => {
  const amount = useStoreMap($amountField, (state) => state.amount);

  const [t] = useTranslation();

  const { isFlipCurrency, activeCurrency, onFlipCurrency, limits, isLoadingLimits } = useCurrency(defaultCurrency!);

  const { errorType, isLoadingCalc } = useAmountField(amount, {
    activeCurrency,
    limits,
    defaultCurrency: defaultCurrency!.currencyCode
  });

  const errorMessage = () => {
    if (!errorType) return undefined;

    if (errorType === 'LIMIT_ERROR') {
      return `Limit ${ activeCurrency } ${limits.min}-${limits.max}`;
    }

    if (errorType === 'EMPTY') {
      return t('money.customForm.enterAmount');
    }

    if (errorType === 'MAX') {
      return `Insufficient funds`;
    }

    return errorType;
  }
  
  return (
    <>
      <AmountInput
        className='mt-[16px] md:!w-[440px]'
        value={amount}
        disabled={isLoadingLimits || isDisabled }
        autoComplete='off'
        placeholder={t('money.customForm.minimumAmount', { activeCurrency, min: limits.min }) as string}
        errorMessage={errorMessage()}
        floatCount={amountPrecisions[activeCurrency]}
        inputClasses='!pe-[60px]'
        onChange={(val) => {
          // Максимальное число в js это число с разрядом в 16 символов
          // не пускаем больше максимального числа
          if ((val as string).length >= 15) return;
          onChangeAmountEvent(val as string)
        }}
        suffixType='button'
        suffix={
          <div className='flex lg:gap-[12px] gap-[4px]'>
            { activeCurrency }
            { isFlipCurrency && 
              <Icon2 
                name='arrowsUpDown'
                onClick={ isDisabled ? undefined : onFlipCurrency }
                disabled={ isLoadingLimits || isDisabled }
                className={ 'pointer' }
              />
            }
          </div>
        }
      />

      { isFlipCurrency &&
        <CalculatedAmount
          defaultGatewayCurrency={ defaultCurrency?.currencyCode }
          isLoadingCalc={ isLoadingCalc }
        />
      }
    </>
  )
}
