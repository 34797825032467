import { createEvent, createStore } from 'effector';
import { useUnit } from 'effector-react';
import React, { useCallback, useEffect, useState } from 'react';
import { ToastProps } from './ToastItem';

const dropLocalToastEvent = createEvent();
export const setToastEvent = createEvent<ToastProps | null>();
export const $toastStore = createStore<ToastProps | null>(null).on(dropLocalToastEvent, () => null);

$toastStore.on(setToastEvent, (_, payload) => payload);

export const useToasts = () => {
  const toast = useUnit($toastStore);

  const onCloseToast = useCallback(
    () => {
      dropLocalToastEvent();
    },
    [],
  );

  useEffect(() => {
    return () => {
      dropLocalToastEvent();
    }
  }, []);

  return {
    toast,
    onCloseToast
  };
}
