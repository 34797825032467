import { useMutation } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { retryFn } from 'services/helpers/retryFn'
import { delEmptyNumFloat } from 'ui/molecules/hooksForm/FormInput';

export type CreateDepositResponse = {
  URL: string;
  headers?: Record<string, any>;
  payload?: Record<string, any>;
  requestType: 'RT_REDIRECT' | 'RT_FRAME' | 'RT_CRYPTO' | 'RT_GET';
  transactionID: string;
}

export type CreateDepositReqBody = {
  gatewayId: string
  destinationID: string
  destinationType: 'wallet' | 'account' | string;
  amount?: string;
  currency?: string;
  withDepositBonus?: 'true' | 'false';
  formData?: Record<string, any>;
}

const createDeposit = async ({
  gatewayId,
  destinationID,
  destinationType, withDepositBonus,
  amount, currency, formData = {},
}: CreateDepositReqBody) => {
  const amountParse = delEmptyNumFloat(amount!);

  const response = await axios.post<CreateDepositResponse>(
    '/payments/v2/create-deposit',
    {
      gatewayId,
      destinationID,
      destinationType,
      amount: amountParse,
      currency,
      formData: {
        ...formData,
        withDepositBonus,
        amount: amountParse,
      },
    },
  )

  return response.data;
}

export const useCreateDeposit = () => {
  return useMutation({
    mutationFn: createDeposit,
    retry: retryFn,
  })
}
