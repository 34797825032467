import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Title, Text, Button } from 'ui/atoms';
import { useIsMobile } from 'libs/hooks/useIsMobile';
import { PromoYour2024Type } from '../api/useGetYour2024';
import { FinishedPng } from './img';
import { path } from 'libs/path';



interface Props {
  data: PromoYour2024Type;
}

export const FinishedPromo: React.FC<Props> = ({ data }) => {
  const [t] = useTranslation();
  const nav = useNavigate();

  const isMobile = useIsMobile();

  const toGiftShop = () => {
    nav(path.giftshop.entry());
  }

  return (
    <div 
      className='
        w-full md:mt-[48px] mt-[16px] flex flex-col justify-center md:max-w-[700px] items-center 
        sm:mb-[40px]
        md:mb-[40px]
      '
    >
      <div className='m-auto'>
        <img src={FinishedPng} alt="" />
      </div>

      <Title level={isMobile ? 2 : 1} className='text-center w-full mt-[24px]'>
        Your 2024 with Headway
      </Title>

      <Text level={isMobile ? 2 : 1} className='md:mt-[24px] mt-[16px] text-center' withTranslate={false}>
          <Trans
            i18nKey={'your2024.page.finished.description'}
            t={t}
            default={'Share the joy of trading with your friends – invite them to sign up on Headway by January 20. Use the link below, and for every friend who joins, you’ll get +1 Crown. Track your progress in the Giftshop Activity History.'}
            components={{
              1: (<br></br>),
              2: (<span className='font-medium text-body.regular'>&nbsp;until January 20th</span>),
              3: (<br></br>)
            }}
          />
      </Text>

      <Button
        className='w-full md:max-w-[240px] !h-[40px] mt-[48px] md:mt-[32px]'
        onClick={toGiftShop}
      >
        Go to Giftshop
      </Button>

      <a
        className='font-medium text-body.regular text-bronze.500 mt-[26px]'
        href='/year-results-2024-terms/'
        target="_blank"
        rel='noreferrer'
      >
        {t('Terms and conditions')}
      </a>
    </div>
  )
}
