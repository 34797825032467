import { path } from 'libs/path';
import { useNavigate } from 'react-router-dom';
import { useRamadanRegister } from 'services/ramadan/mutation/useRamadanRegister';

import { showJoinedRamadanModalEvent } from '../molecules/modals/JoinedModal';

interface Options {
  isMadeDeposit: boolean;
  isJoined: boolean;
}

export const useActionButton = ({ isJoined, isMadeDeposit }: Options) => {
    const nav = useNavigate();
  
    const { mutateAsync, isLoading } = useRamadanRegister();
  
    const onClickButton = async () => {
      try {
        if (isLoading) return;
  
        if (!isJoined) {
          const resp = await mutateAsync();

          showJoinedRamadanModalEvent(resp.isMadeDeposit)
          
          return;
        }
  
        if (isJoined && !isMadeDeposit) {
          nav(path.money.depositTab());
          return;
        }
      } catch (error) {
        
      }
    };

  return {
    onClickButton,
    buttonLabel: isJoined ? 'Deposit to join' : 'Join now',
    isLoading,
  }
}

