import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

export const GET_HEAD_POPUP_KIT_KEY = 'GET_HEAD_POPUP_KIT_KEY';


export interface BannerHeadKit {
  name: string;
  params: Record<string, any>;
}

const getPopup = async (state?: string) => {
  const res = await axios.get<BannerHeadKit>('/contentkit/v1/popup', { 
    params: { 
      state,
    }
  })

  return res.data
}

interface Options {
  state?: 'STATE_DEPOSIT_BONUS_POPUP' // отдается ответ для deposit-bonus popup
  | 'STATE_EMPTY_POPUP'; // пустой popup (deposit-bonus popup был закрыт ранее)
  enabled?: boolean;
}

export const useGetPopup = ({enabled = true, state}: Options) => {
  return useQuery({
    queryKey: [GET_HEAD_POPUP_KIT_KEY],
    queryFn: () => getPopup(state),
    enabled,
  })
}
