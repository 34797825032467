import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  border: 1px solid var(--color-gray-400);
  padding: 24px 20px;
  height: 300px;
  border-radius: 8px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
`