import { useTranslation } from 'react-i18next'
import { Button, Title } from 'ui/atoms'

import * as Styled from './styled'
import { useState } from 'react'
import { Modal } from 'ui/molecules/modal_draft'
import { TicketsModal } from '../hallOfFame/TicketsModal'

export const DailyResults = ({
  place,
  lotsCount,
  ticketsCount,
  dayID
}: {
  place: number
  lotsCount: string;
  dayID: number;
  ticketsCount: number
}) => {
  const [t] = useTranslation();
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  return (
    <Styled.Wrapper>
      <Title level={3} className="mb-[24px]">
        Your daily results
      </Title>
      <Styled.Grid>
        <div>
          <div className="mb-[4px]">{t('Your place')}</div>
          <Title level={2}>{place}</Title>
        </div>
        <div>
          <div className="mb-[4px]">{t('Lots traded')}</div>
          <Title level={2}>{lotsCount}</Title>
        </div>
        <div>
          <div className="mb-[4px]">{t('Your raffle tickets')}</div>
          <Title level={2}>{ticketsCount}</Title>
        </div>
      </Styled.Grid>
      <Button size="small" design="secondary" className="mt-[24px] w-full" onClick={() => setIsModalOpen(true)}>
        View my ticket numbers
      </Button>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="My ticket numbers"
        width={600}
        footer={false}
        isScrollContent
        titleClasses="text-center"
      >
        <TicketsModal dayID={dayID} isOpen={isModalOpen} />
      </Modal>
    </Styled.Wrapper>
  )
}
