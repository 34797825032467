import styled from 'styled-components/macro'

export const CharityWrapper = styled.div`
  width: 100%;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--color-gray-400);
  border-radius: 8px;

  @media (min-width: 1280px) {
    width: 880px;
    padding: 24px 0px;
  }

`;