import { useWindowSize } from '@uidotdev/usehooks'
import { Trans } from 'react-i18next'
import { Title } from 'ui/atoms'

import * as Styled from './styled'

export const DisqualifiedBlock = () => {
  const { width } = useWindowSize()
  const isMobile = width && width < 600

  return (
    <Styled.Wrapper>
      <Title level={isMobile ? 2 : 1} className="mb-[16px]">
        You are disqualified
      </Title>
      <div>
        <Trans
          i18nKey="It seems that you violated the rules of the competition, so we had to remove you from the race. If you have any questions or you think the disqualification is unfair, please contact us at help@hw.site"
          values={{ email: 'help@hw.site' }}
          components={[
            <span />,
            <a
              href="mailto:help@hw.site"
              className="font-medium text-sunray.700"
            />,
          ]}
        />
      </div>
    </Styled.Wrapper>
  )
}
