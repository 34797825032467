import RcUpload from 'rc-upload';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { RcFile } from 'rc-upload/lib/interface';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetcher } from './fetcher';
import { Icon2, Spinner2, Text } from 'ui/atoms';
import * as S from './styled';
import { LocalFile, ServerFile } from './types'
import { useIsMobile } from 'libs/hooks/useIsMobile';
import { useLoadingPosition } from './helpers/useLoadingPosition';

export type AcceptTypes = 'gif' | 'jpg' | 'jpeg' | 'png' | 'pdf';

type Props = {
  name: string;
  multiple?: boolean;
  type?: 'drag';
  accept?: AcceptTypes[];
  label?: React.ReactNode;
  limit?: number;
  error?: string;
  disableLimitMessage?: boolean;
  disabled?: boolean;
  uploadUrl: string;
  onChangeFiles: (files: ServerFile, placeId?: string) => void;
  onDeleteFile: (index: number, id: string) => void;
  files?: ServerFile[];
  withBorder?: boolean;
  defaultUploadPlace?: DefaultUploadPlace[];
  isHideAddButton?: boolean;
}

export type DefaultUploadPlace = {
  title: JSX.Element | string;
  titleIcon?: 'plus' | 'cloudArrowUp';
  img?: {
    main: string;
    webp?: string;
  };
  icon?: 'plus' | 'cloudArrowUp';
  id: string;
}

export const Uploader = ({
  type = 'drag',
  accept = [],
  multiple = false,
  limit = 5,
  uploadUrl,
  onChangeFiles,
  onDeleteFile,
  defaultUploadPlace = [],
  isHideAddButton,
  files = [],
  name,
  disableLimitMessage,
  // error,
  disabled,
}: Props) => {
  const [t] = useTranslation();
  const isMobile = useIsMobile();
  
  const [uploadingError, setUploadingError] = useState<string>();

  const { 
    addLoadedPlaces, removeLoadedPlaces, 
    addLoading, removeLoading, loadingArray, 
    defaultUploadArray 
  } = useLoadingPosition({ defaultUploadPlace });

  const onStartUpload = async (file: RcFile, loadedId?: string) => {
    const fileId = loadedId || file.uid;
    try {
      addLoading(fileId);
      const newFile = await fetcher(uploadUrl, file);

      if (newFile) {
        setUploadingError(undefined);
        onChangeFiles({...newFile, placeId: loadedId});
        if (!!loadedId) {
          addLoadedPlaces(loadedId);
        }
    
      }

    } catch (error) {
      setUploadingError('File upload error. Try Again');
      if (loadedId) {
        removeLoadedPlaces(loadedId);
      }
    }
    finally {
      removeLoading(fileId);
    }
  }

  const onDeleteFileFn = ({id, index, placeId}: {id: string, index: number, placeId?: string}) => {
    const fileId = placeId || id;
    onDeleteFile(index, id);
    setUploadingError(undefined);
    removeLoading(fileId);
    if (placeId) {
      removeLoadedPlaces(placeId);
    }
  };

  const hasMoreFiles = useMemo(() => {
    if (isHideAddButton) return false;
    // Когда загружено больше, чем лимит или равно
    if (files?.length >= limit) return false;
    
    // Когда загруженных файлов + ждуших загрузку файлов больше или равно лимиту
    if (Number(files?.length + loadingArray?.length) >= limit) return false;

    return true;
  }, [limit, files, loadingArray, isMobile]);

  const loadingSpiners = useMemo(() => {
    if (defaultUploadArray?.length === 0) return loadingArray;
    
    if (loadingArray?.length > defaultUploadArray?.length) {
      const newLoadingArray = [...loadingArray];
      newLoadingArray.splice(1, defaultUploadArray?.length);
      return newLoadingArray;
    }

    return [];
  }, [loadingArray, defaultUploadArray]);

  const defaultUploadId = useCallback(() => {
    const id = defaultUploadArray.find((item) => !loadingArray.includes(item.id))?.id;
    return id;
  }, [defaultUploadArray, loadingArray]);
  
  return (
    <S.Container data-disabled={disabled}>
      <S.Files>
        {
          files.map((file: LocalFile, index) => {
            const { uid, src } = file
            const isLoading = loadingArray.includes(uid)

            return (
              <S.LocalFile
                key={file.id}
                image={src}
                data-loading={isLoading}
                data-error={Boolean(uploadingError)}
                data-disabled={disabled}
              >
                <embed src={src} width={100} height={100}></embed>
                <S.Delete onClick={() => onDeleteFileFn({id: uid, index, placeId: file.placeId})}>✕</S.Delete>
              </S.LocalFile>
            )
          })
        }
        {
          defaultUploadArray?.map((item, index) => {
            const isLoading = loadingArray.includes(item.id);
            return (
              <S.Add data-disabled={disabled} key={`default_upload_${item.id}`} isBorder isLoading={isLoading}>
              { isLoading && 
                <Spinner2 size='large' className='absolute z-[3] right-0 left-0 top-0 bottom-0 !m-auto' />
              }
              <RcUpload
                name={`${name}${type && `-${type}`}`}
                onStart={(files) => onStartUpload(files, item.id)}
                customRequest={() => {}}
                disabled={isLoading}
                type={type}
                multiple={multiple}
                accept={accept?.map((a) => `.${a}`).join(',')}
              />

              <S.Label>
                { item.icon && 
                  <S.LabelIcon>
                    <S.UploadIcon name={item.icon || 'plus'} />
                  </S.LabelIcon>
                }
                <S.LabelImg>
                  {!!item.img && 
                    <LazyLoadImage
                      alt="upload card"
                      src={item.img?.main} srcSet={item.img?.webp} 
                      className={`${isLoading ? 'opacity-30' : ''}`}
                    />
                  }
                </S.LabelImg>
                <S.LabelText>
                  {item.titleIcon && (
                    <S.UploadIcon className='!text-gray-600 mr-[6px]' name={item.titleIcon} size='base' />)
                  }
                  {item.title || t('Add More')}
                </S.LabelText>
              </S.Label>
            </S.Add>
            )
          })
        }

        { loadingSpiners?.map((item) => (
          <S.Add data-disabled={disabled} key={`default_upload_loading_${item}`} isBorder>
            {
              <Spinner2 size='large' className='absolute z-[3] right-0 left-0 top-0 bottom-0 !m-auto' />
            }
          </S.Add>
          ))
        }

        {hasMoreFiles && !isMobile && (
          <S.Add data-disabled={disabled}>
            <RcUpload
              name={`${name}${type && `-${type}`}`}
              onStart={(newFiles) => onStartUpload(newFiles, defaultUploadId())}
              customRequest={() => {}}
              type={type}
              multiple={multiple}
              accept={accept?.map((a) => `.${a}`).join(',')}
            />

            <S.Label>
              <S.LabelIcon>
                <S.UploadIcon name="plus" />
              </S.LabelIcon>
              <S.LabelText>
                {t('Add More')}
              </S.LabelText>
            </S.Label>
          </S.Add>
        )}
      </S.Files>

      {hasMoreFiles && isMobile && (
        <S.MAdd data-disabled={disabled}>
          <RcUpload
            name={`${name}${type && `-${type}`}`}
            onStart={(newFiles) => onStartUpload(newFiles, defaultUploadId())}
            customRequest={() => {}}
            type={type}
            multiple={multiple}
            accept={accept?.map((a) => `.${a}`).join(',')}
          />

          { <div className='mt-[16px] flex'>
              <Icon2 size='base' name='plus' className='!text-bronze.500' />
              <Text
                className='!text-bronze.500 ms-[8px]'
                withTranslate={false}
                level={2}
              >
                {t('Add More')}
              </Text>
            </div>
          }
        </S.MAdd>
      )}

      {uploadingError && (
        <S.FilesError>
          {uploadingError}
        </S.FilesError>)
      }

      { files.length >= limit && !disableLimitMessage && (!uploadingError) &&
        <S.FilesError>
          {files.length >= limit && (
            <>
              <S.FilesErrorIcon name="exclamationCircle" size="small" /> You can
              upload maximum {limit} files
            </>
          )}
        </S.FilesError>
      }

    </S.Container>
  )
}