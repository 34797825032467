import { useUnit } from 'effector-react';
import { useTranslation, Trans } from 'react-i18next';
import React, { useEffect, useMemo } from 'react';
import { useDebounce } from '@uidotdev/usehooks';

import { useGetDepositBonus } from 'services/money/get';
import { Icon2, Text, TipyTooltip } from 'ui/atoms';

import { $destenation } from '../model';
import { BonusToggle } from './BonusToggle';
import { BonusSum } from './BonusSum';
import { BonusInfo } from './BonusInfo';
import { PromoCode } from './PromoCode';
import { $bonusDeposit, setAmountForBigestPercent, setBonusDepositData, setBonusPromocodeEvent } from '../store/depositBonusStore';
import { useGetReceiveAmount } from '../utils/useGetReciveAmount';
import { Alerts } from 'ui/molecules';


export const DepositBonus: React.FC = () => {
  const destenation = useUnit($destenation);

  const [t] = useTranslation();
  
  const { receiveAmount, receiveCurrency } = useGetReceiveAmount();

  const debouncedAmount = useDebounce(receiveAmount, 300);
  const isActiveCheck = useUnit($bonusDeposit);

  const { data, isLoading, error, isInitialLoading, refetch } = useGetDepositBonus({ 
    enabled: !!destenation?.id && isActiveCheck,
    // state: 'STATE_INCREASE_REG_BONUS',
    receiveAmount: debouncedAmount,
    accountID: destenation?.id!,
  });

  const code = useMemo(() => data?.promoCode?.code, [data]);

  useEffect(() => {
    if (!data) {
      refetch();
    }
    return () => {
      setBonusPromocodeEvent(null);
      setAmountForBigestPercent(null);
    }
  }, []);

  useEffect(() => {
    setBonusDepositData(data);

    if (!data) return;
    
    if (!data.bonusConditions || Number(data.receiveAmount) > Number(data.bonusConditions?.sumDeposit)) {
      setAmountForBigestPercent(null);
      return;
    }

    const amount = Number(data.bonusConditions.sumDeposit || 50) - Number(data.receiveAmount || 0);

    if (amount <= 0) {
      setAmountForBigestPercent(null);
      return;
    }

    setAmountForBigestPercent({
      bigestPercent: data.bonusConditions.bonusPercentAfter,
      currency: data.currency,
      amountNeeded: amount,
    });

  }, [data]);
  
  if (error) return null;

  const bgColor = data?.bgColor === 'COLOR_YELLOW' ? 'bg-azure.100' : 'border border-gray.400';

  return (
    <div 
      className={`
        w-full rounded-[8px]
        lg:w-[440px] mt-[24px] 
        ${bgColor}
      `}
    >
      <div className='py-[16px] mx-[20px]'>
        <div className='flex justify-between h-[40px] items-center'>
          <Text withTranslate={false} weight='medium' color='main' className='flex items-center'>
            {data?.isIncreasedPercentage && <Icon2 name='fire' className='!text-orange-500 mr-[4px]' />}
            <Trans
              t={t}
              i18nKey="Get bonus"
              values={{
                value: data?.isIncreasedPercentage ? `+${data?.bonusPercent}%` : '',
              }}
            />
            <TipyTooltip
              interactive
              disabled={!data?.bonusPercent}
              content={
                <>
                  <Text level={3} className='max-w-[256px]' weight='medium' withTranslate={false}>
                    {t(
                      'The bonus depends on your deposit amount. Get up to added to your trading credit to maximize your opportunities in the market.', 
                      {value: data?.bonusConditions?.bonusPercentAfter || data?.bonusPercent})
                    }
                    {/* The bonus depends on your deposit amount. Get up to +{data?.bonusPercent}% added to your trading credit to maximize your opportunities in the market. */}
                  </Text>
                  <Text
                    level={3}
                    className='!text-bronze.500 cursor-pointer mt-[4px]'
                    weight='medium'
                    withTranslate={false}
                  >
                    <a href='/deposit-bonus-terms/' target='_blank' rel="noreferrer">
                      {t('See all details')}
                    </a>
                  </Text>
                </>
              }
            >
              <div>
                <Icon2 name='questionMarkCircle' size='small' className='text-gray.500 ml-[4px]'/>
              </div>
            </TipyTooltip>
          </Text>
          <BonusToggle defaultState={data?.isActiveByDefault} />
        </div>

        { isActiveCheck &&
          <>
            <BonusSum
              receiveAmount={receiveAmount}
              bonusDep={data?.bonusAmount}
              isBonusLoading={isLoading}
              receiveCurrency={receiveCurrency}
            />
            {(data?.limitExceeding?.sumLimit) && 
              <div className='md:mt-[24px] w-full mt-[12px]'>
                <Alerts type='warning'>
                  <Text level={2} withTranslate={false}>
                    {t('You reach the maximum bonus of available on this trading account.', { value: `$${data.limitExceeding.sumLimit || 0}`})}
                  </Text>
                </Alerts>
              </div>
            }
          </>
        }

        {  data?.promoCode && 
          <PromoCode codeFromBe={code} promoRefetch={refetch} />
        }
      </div>

      { !!data?.bonusConditions && 
        <div className='py-[16px] px-[20px] w-full border-solid border-gray.400 border-t-[1px]'>
          <BonusInfo bonus={data.bonusConditions}/>
        </div>
      }
    </div>
  )
}
