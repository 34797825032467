import React, { useEffect, useState, useRef } from 'react';

import * as S from './styled';

// первая попытка 0 сек
// вторая попытка 15 сек
// третья попытка 30 сек
// 4 и последующие 60 сек
const RESEND_TRYES = {
  1: 0,
  2: 15,
  3: 30,
  4: 60,
};

interface Props {
  resendCode: () => Promise<void>;
  isError?: boolean;
}

export const ResendTimer: React.FC<Props> = ({ resendCode, isError }) => {
  const [timer, setTimer] = useState(RESEND_TRYES[1]);
  const timerId = useRef<NodeJS.Timer>();
  const timerCount = useRef<number>(1);
  const isRunning = timer > 0;

  const resendCodeAndNewTimer = async () => {
    await resendCode();
    if (timerCount.current < 4) {
       timerCount.current = timerCount.current + 1;
    }
    
    // @ts-ignore
    setTimer(RESEND_TRYES[timerCount.current] ?? RESEND_TRYES[4]);

  }

  useEffect(() => {
    if (timerId.current || timer === 0) return;

    timerId.current = setInterval(() => {
      
      setTimer(prev => {
        if (prev === 1) {
          clearInterval(timerId.current);
          timerId.current = undefined;
          return prev - 1;
        }

        return prev - 1;
      });
    }, 1000);
  }, [timer]);
  

  return (
    <div className='flex h-full items-center'>
      {isRunning && <S.ConfirmTimerCount isError={ isError }>{timer}</S.ConfirmTimerCount>}
      {!isRunning && <S.ResendText isError={ isError }>Resend</S.ResendText>} 
      <S.ConfirmTimerIcon
        isError={ isError }
        name={isRunning ? 'clock' : 'arrowPath'}
        data-running={isRunning}
        onClick={
          isRunning
            ? undefined
            : resendCodeAndNewTimer
        }
      />
    </div>
  )
}