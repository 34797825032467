
export const CARD_CATEGORY: Record<number, string> = {
  1: 'who opened the most orders',
  2: 'who traded the most lots',
  3: 'who traded the most days',
  4: 'who received the most Crowns in Headway Giftshop',
  5: 'who made the most orders in Headway Giftshop',
  6: 'who had the most real accounts',
  7: 'who had the most BUY positions',
  8: 'who had the most SELL positions',
  9: 'who traded gold the most',
  10: 'who traded EURUSD the most',
  11: 'who traded crypto the most',
  16: 'with the most Pro accounts',
  17: 'with the most Standard account',
  18: 'with the most Cent account',
  19: 'with the longest open positions',
  20: 'with the shortest open positions',
  21: 'who traded on the weekends',
  22: 'who have the most demo account',
  23: 'who have spent the most days with Headway',
  24: 'who trade on their phones the most',
  25: 'who trade on their computer the most',
  28: 'who got the biggest profit with a Bonus $111',
  29: 'who logged into Personal Area the most days',
}