import { useQuery } from '@tanstack/react-query';
import { Account, AccountsList } from 'services/accounts/types';

import { axios } from 'services/axios';
import { Wallet, WalletList } from 'services/wallet/get/types';

export const TRANSACTION_FILTER_KEY = 'TRANSACTION_FILTER_KEY';

export interface TransactionFilter {
  accounts: Account[];
  wallets: Wallet[];
  exchangerWallets: Wallet[];
  statuses: {name: string, value: string}[];
  transactionTypes: {name: string, value: string}[];
}

const getTransactionFilter = async () => {
  const resp = await axios.get<TransactionFilter>(`/money-history/v1/transactions-filters`);

  return resp.data;
}

export const useGetTransactionFilter = () => {
  return useQuery({
    queryKey: [TRANSACTION_FILTER_KEY],
    queryFn: () => getTransactionFilter(),
    staleTime: 200,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}
