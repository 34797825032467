import { useIsMobile } from 'libs/hooks/useIsMobile'
import React, { useDeferredValue, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { WinnersItem } from 'services/ramadan/types'
import { Text, Title } from 'ui/atoms'
import { ScrollWithBlur } from 'ui/atoms/scrollWithBlur'

import { DayCard } from './DayCard'
import * as S from './styled'

interface Props {
  winners: WinnersItem[]
  daysCount?: number
}

export const HallOfFame: React.FC<Props> = ({ winners, daysCount }) => {
  const [filter, setFilter] = useState('all')
  const isMobile = useIsMobile()
  const [t] = useTranslation()

  const placeHolderDays = useMemo(() => {
    if (isMobile) return []
    if (!daysCount || (filter && filter !== 'all')) return []

    const count = daysCount - winners.length
    return new Array(count).fill(null)
  }, [daysCount, winners, filter, isMobile])

  const deferredFilter = useDeferredValue(filter)

  const filtersOptions = useMemo(() => {
    const defaultOpt = new Set()

    winners.forEach((item) => {
      defaultOpt.add(item.day.weekID)
    })

    const resultObj = [{ label: 'All', value: 'all' }]

    defaultOpt.forEach((item) => {
      resultObj.push({
        // @ts-expect-error
        label: <Trans i18nKey="Week 1" values={{ number: item }} />,
        value: item as string,
      })
    })

    return resultObj
  }, [winners])

  const filteredWinners = useMemo(() => {
    if (deferredFilter === 'all') return winners
    return winners?.filter((item) => item.day.weekID === deferredFilter) || []
  }, [winners, deferredFilter])

  return (
    <div className="xl:w-[880px] w-full">
      <Title level={2} className="text-center">
        Hall of Fame
      </Title>

      {filtersOptions?.length > 2 && (
        <ScrollWithBlur className="mt-[16px] w-full flex md:justify-center items-center">
          {filtersOptions.map((item, index) => (
            <div
              className={`md:px-[16px] px-[12px] py-[4px] cursor-pointer whitespace-nowrap`}
              onClick={() => setFilter(item.value)}
              key={item.value}
            >
              <Title
                level={isMobile ? 3 : 2}
                className={`${
                  filter === item.value ? '!text-bronze.500' : '!text-gray-600'
                }`}
                withTranslate={index === 0}
              >
                {item.label}
              </Title>
            </div>
          ))}
        </ScrollWithBlur>
      )}

      <S.DayCardWrapper>
        {filteredWinners.map((item) =>
          !item.bestTrader && !item.lucky ? (
            <S.DayCardPlaceholder key={`placeholder_day_${item.day.dayID}`}>
              <Text
                weight="medium"
                withTranslate={false}
                className="text-center"
              >
                <Trans i18nKey="Day 1" values={{ value: item.day.dayID }} />
              </Text>

              <Text
                weight="medium"
                level={1}
                color="subtext"
                className="flex-1 flex justify-center items-center"
              >
                Your name may be here
              </Text>
            </S.DayCardPlaceholder>
          ) : (
            <DayCard
              key={`${item.day.weekID}_${item.day.dayID}`}
              winner={item}
            />
          ),
        )}
        {placeHolderDays?.map((_, index) => (
          <S.DayCardPlaceholder key={`placeholder_day_${index}`}>
            <Text weight="medium" withTranslate={false} className="text-center">
              <Trans
                i18nKey="Day 1"
                values={{ value: 1 + index + filteredWinners.length }}
              />
            </Text>

            <Text
              weight="medium"
              level={1}
              color="subtext"
              className="flex-1 flex justify-center items-center"
            >
              Your name may be here
            </Text>
          </S.DayCardPlaceholder>
        ))}
      </S.DayCardWrapper>
    </div>
  )
}
