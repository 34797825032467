import { createEvent, restore, createStore, sample } from 'effector';
import { SourceItem } from 'services/payments/get/types';
import { CalculatedV1Resp } from 'services/payments/mutations/types';


interface AmountFieldStore {
  amount: string;
  activeCurrency: string;
  errorType?: AmountErrorType;
}

type AmountErrorType = 'LIMIT_ERROR' | 'EMPTY';

export const onChangeAmountEvent = createEvent<string>();
export const onSetAmountCurrencyEvent = createEvent<string>();
export const setAmountErrorEvent = createEvent<AmountErrorType | undefined>();
export const onResetAmountEvent = createEvent();
export const updateAmountFieldEvent = createEvent<Partial<AmountFieldStore>>();
export const $amountField = createStore<AmountFieldStore>({
  amount: '',
  activeCurrency: '',
  errorType: undefined,
})
.reset(onResetAmountEvent)
.on(updateAmountFieldEvent, (state, payload) => ({...state, ...payload}))
.on(onChangeAmountEvent, (state, payload) => ({...state, amount: payload}))
.on(onSetAmountCurrencyEvent, (state, payload) => ({...state, activeCurrency: payload}))
.on(setAmountErrorEvent, (state, payload) => ({ ...state, errorType: payload }));

export const onChangeCalcAmountEvent = createEvent<CalculatedV1Resp>();
export const onResetCalcAmountEvent = createEvent();
export const $calcAmount = restore(onChangeCalcAmountEvent, {
  currency: '',
  convertedAmount: '',
}).reset(onResetCalcAmountEvent);

sample({
  source: $calcAmount,
  clock: onChangeAmountEvent,
  fn(src, clk) {
    if (!clk) {
      return {
        convertedAmount: '',
        currency: src.currency,
      };
    }
  return src;
  },
  target: $calcAmount,
});


export const setDestenationEvent = createEvent<SourceItem | null>();
export const $destenation = restore<SourceItem | null>(setDestenationEvent, null);
